export const TEAMS_LOADED = 'workaware/teams/TEAMS_LOADED';
export const MEMBER_ADDED_TO_TEAM = 'workaware/teams/MEMBER_ADDED_TO_TEAM';
export const MEMBER_REMOVED_FROM_TEAM = 'workaware/teams/MEMBER_REMOVED_FROM_TEAM';
export const MOVE_TEAM = 'workaware/teams/MOVE_TEAM';
export const CREATE_TEAM = 'workaware/teams/CREATE_TEAM';
export const UPDATE_TEAM = 'workaware/teams/UPDATE_TEAM';
export const REMOVE_TEAM = 'workaware/teams/REMOVE_TEAM';

export const teamsLoaded = teams => ({ type: TEAMS_LOADED, payload: teams });
export const memberAddedToTeam = (member, team) => ({ type: MEMBER_ADDED_TO_TEAM, payload: { member, team }});
export const memberRemovedFromTeam = (member, team) => ({ type: MEMBER_REMOVED_FROM_TEAM, payload: { member, team }});
export const teamCreated = (team) => ({ type: CREATE_TEAM, payload: team });
export const teamUpdated = (team) => ({ type: UPDATE_TEAM, payload: team });
export const teamRemoved = (team) => ({ type: REMOVE_TEAM, payload: team });
export const moveTeam = (team, target) => ({ type: MOVE_TEAM, payload: { team, target }});

const removeTeam = (tree, team) => {
  team.parent.children.forEach((element, index) => {
    if (element.Id === team.Id) {
      team.parent.children.splice(index, 1);
    }
  });
  return tree;
};

const createTeam = (tree, team) => {
  tree.findOne(n => n.Id === team.ParentOrganizationTeamHierarchyId, target => {
    return insertTeam(tree, Object.assign(team, {
      children: [],
      members: [],
      parent: target
    }), target);
  });
  return tree;
};

const insertTeam = (tree, team, target) => {
  tree.findOne(n => n.Id === target.Id, target => {
    target.children.push(Object.assign(team, { 
      ParentOrganizationTeamHierarchyId: target.Id,
      parent: target 
    }));
  });
  return tree;
};

const updateTeam = (tree, team) => {
  tree.findOne(n => n.Id === team.Id, n => {
    n = Object.assign(n, team);
  });
  return tree;
};

const removeMember = (tree, team, member) => {
  team.members.forEach((element, index) => {
    if (element.Id === member.Id) {
      team.members.splice(index, 1);
    }
  });
  return tree;
};

const addMember = (tree, team, member) => {
  tree.findOne(n => n.Id === team.Id, n => {
    n.members.push(member);
  })
  return tree;
};

const teamsReducer = (state = null, action) => {
  switch (action.type) {
    case MOVE_TEAM:
      return Object.assign({}, insertTeam(removeTeam(state, action.payload.team), action.payload.team, action.payload.target));
    case CREATE_TEAM:
      return Object.assign({}, createTeam(state, action.payload));
    case UPDATE_TEAM:
      return Object.assign({}, updateTeam(state, action.payload));
    case REMOVE_TEAM:
      return Object.assign({}, removeTeam(state, action.payload));
    case MEMBER_ADDED_TO_TEAM:
        return Object.assign({}, addMember(state, action.payload.team, action.payload.member));
    case MEMBER_REMOVED_FROM_TEAM:
      return Object.assign({}, removeMember(state, action.payload.team, action.payload.member));
    case TEAMS_LOADED:
        return Object.assign({}, action.payload);
    default:
      return state;
  }
};

export default teamsReducer;
