import { WorkawareBaseClass } from './WorkawareBaseClass'
import { TeamModel } from '.'

export class FileModel extends WorkawareBaseClass {
  _className = 'FileModel'
  public Id: number | undefined = undefined
  public Filename: string | undefined = undefined
  public Folder: string | undefined = undefined
  public Filesize: number | undefined = undefined
  public CreatedOn: Date | undefined = undefined
  public Culture: string | undefined = undefined
  public CultureGroup?: string = undefined
  public CultureGroupName?: string = undefined
  public Teams: TeamModel[] = []
}
