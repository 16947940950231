import axios, { AxiosRequestConfig } from 'axios'
import CryptoJS from 'crypto-js'
import { createNotification } from 'factories/notificationFactory'
import { endRequest, startRequest } from 'reducers/stateReducer'
import { Dictionary } from 'types'
import store, { getAccessToken, getLocale, getTeamFilter } from 'utils/configureStore'

interface RequestConfig extends AxiosRequestConfig {
  queryParameters?: object
  mode?: string
  returnResponse?: boolean
}

interface requestBuildingSettings {
  base: string
  cullIdsInPostRequests?: boolean
}

export const requestBuilder = (settings: requestBuildingSettings) => async <T = any>(url: string, options: RequestConfig = {}, customHeaders: Dictionary<string> = {}): Promise<T> => {
  const defaultOptions = (): RequestConfig => ({
    method: 'GET',
    responseType: 'json',
    queryParameters: {},
    mode: 'cors',
    returnResponse: false,
    validateStatus: (s) => s >= 200 && s < 300,
    headers: {
      Accept: 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAccessToken()}`,
      'OData-Version': '4.0',
      'OData-MaxVersion': '4.0',
      'ZUMO-API-VERSION': '2.0.0',
      Prefer: 'return=representation',
    },
  })

  return new Promise(async (resolve, reject) => {
    const params: RequestConfig = Object.assign({}, defaultOptions(), options)

    if (params.data instanceof FormData) {
      for (let value of params.data.values()) {
        if (value instanceof File) {
          params.headers['FileMD5Hash'] = await getChecksum(value)
        }
      }
    }

    if (['post', 'put', 'patch'].includes(params.method?.toLowerCase() ?? '') && typeof params.data === 'object' && false === params.data instanceof FormData) {
      if (settings.cullIdsInPostRequests && params.method?.toLowerCase() === 'post') {
        //some odata apis will fail if inserting objects with an Id field
        delete params.data.Id
      }
      // remove the class name folder
      if (undefined !== params.data._className) {
        delete params.data._className
      }
      // remove any other properties we want to
      if (undefined !== params.data._stripProperties) {
        for (var i in params.data._stripProperties) {
          var paramName = params.data._stripProperties[i]
          delete params.data[paramName]
        }
        delete params.data._stripProperties
      }
    }

    const teamFilterId = getTeamFilter()

    if (teamFilterId !== '-1') {
      params.headers['X-WORKAWARE-TEAMFILTER'] = teamFilterId
    }

    const locale = getLocale()

    if (locale) {
      params.headers['X-WORKAWARE-LOCALE'] = locale
    }

    if (customHeaders) {
      for (var prop in customHeaders) {
        var v = customHeaders[prop]
        if (v === undefined && params.headers[prop]) {
          delete params.headers[prop]
        } else {
          params.headers[prop] = v
        }
      }
    }

    store.dispatch(startRequest())

    if (!url.startsWith('http')) {
      url = settings.base + url
    }

    axios(url, params)
      .then((result) => {
        store.dispatch(endRequest())
        if (params.returnResponse) {
          resolve(result as any)
        } else {
          resolve(result.data)
        }
      })
      .catch((error: { message: string; response?: any }) => {
        store.dispatch(endRequest())
        const message = error?.response?.data?.error?.message || error.message
        createNotification.error(message)
        reject(error)
      })
  })
}

const getChecksum = (file: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (e) => {
      if (e !== null && e.target !== null) {
        const data = CryptoJS.enc.Latin1.parse(e.target.result)
        const hash = CryptoJS.MD5(data).toString()
        resolve(hash)
      }
    }
    reader.readAsBinaryString(file)
  })
}
