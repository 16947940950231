import AppBar from '@material-ui/core/AppBar'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import LinearProgress from '@material-ui/core/LinearProgress'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import MenuIcon from '@material-ui/icons/Menu'
import clsx from 'clsx'
import LanguagePanel from 'components/LanguagePanel'
import ProfilePanel from 'components/ProfilePanel'
import { uiSettings } from 'etc/settings'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toggleMobileMenu, toggleSideDrawer } from 'reducers/stateReducer'
import { IRootReduxState } from 'types/reducers'
import Branding from './branding'
import { useLocation } from 'react-router-dom'
import { UserModel } from 'data/models'
import ErrorBoundary from 'components/ErrorBoundary'

const useStyles = makeStyles((theme) => ({
  signin: {
    color: uiSettings.header.foreground,
    borderColor: uiSettings.header.foreground,
  },
  appBar: (props: any) => ({
    padding: '0px 2px',
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: theme.palette.type === 'light' ? '0px 2px 5px 0px #dadada' : '0px 2px 5px 0px #1b1b1b',
    backgroundColor: theme.palette.background.paper,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  }),
  appBarShift: (props: any) => ({
    marginLeft: uiSettings.drawerWidth,
    width: props.bigScreen ? `calc(100% - ${uiSettings.drawerWidth}px)` : '100%',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ml8: {
    marginLeft: 8,
  },
  hide: {
    display: 'none',
  },
  toolbar: (props: any) => ({
    height: '100%',
    paddingLeft: '0px',
    paddingRight: '0px',
    '@media (max-width: 601px)': {
      minHeight: '56px',
    },
    '@media (max-width: 600px) and (orientation: landscape)': {
      minHeight: '56px',
    },
  }),
}))

interface Props {
  window?: () => Node | Window | undefined
}
const BorderLinearProgress = withStyles({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    height: 3,
    zIndex: 1203, // above the appbar
  },
  bar: {},
})(LinearProgress)

interface Props {
  componentsLeft?: JSX.Element
  componentsRight?: JSX.Element
  /** @deprecated */
  title?: string
  /** @deprecated */
  loading?: number
  /** @deprecated */
  user?: UserModel
  /** @deprecated */
  expanded?: boolean
  /** @deprecated */
  mobileMenuToggle?: () => void
  /** @deprecated */
  toggleExpanded?: () => void
}
const TopNavigation: React.FC<Props> = ({ componentsLeft, componentsRight }) => {
  const loading = useSelector((state: IRootReduxState) => state.app.state.loading)
  const expanded = useSelector((state: IRootReduxState) => state.app.state.sideMenuExpanded)
  const bigScreen = useMediaQuery('(min-width:960px)')
  const smallScreen = useMediaQuery('(max-width:600px)')
  const dispatch = useDispatch()
  const classes = useStyles({ bigScreen })
  const location = useLocation()

  return (
    <ErrorBoundary>
      <BorderLinearProgress hidden={loading === 0} />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: expanded,
        })}
      >
        <Toolbar className={classes.toolbar}>
          {smallScreen ? (
            <Grid container alignItems="center" justify="space-between" direction="row" wrap="nowrap">
              {location.pathname.length <= 1 && (
                <Grid item>
                  <div style={{ marginLeft: '3px' }}>
                    <Branding width={200}></Branding>
                  </div>
                </Grid>
              )}

              {componentsLeft && (
                <Grid item container justify="flex-start" alignItems="center">
                  {componentsLeft}
                </Grid>
              )}

              {componentsRight && (
                <Grid item container justify="center" alignItems="center">
                  {componentsRight}
                </Grid>
              )}

              <Grid item container justify="flex-end" alignItems="center" xs={2}>
                <Grid item>
                  <IconButton aria-label="Open drawer" onClick={() => dispatch(toggleMobileMenu())}>
                    <MenuIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid container alignItems="center" justify="space-between" wrap="nowrap" spacing={3}>
              {/* <Grid item container direction="row" wrap="nowrap" xs={3} justify="flex-start" alignItems="center" spacing={3}> */}

              <Grid item>
                <Hidden smDown implementation="css">
                  <IconButton className={clsx({ [classes.ml8]: expanded })} aria-label="open drawer" onClick={() => dispatch(toggleSideDrawer())}>
                    {expanded ? <ChevronLeftIcon /> : <MenuIcon />}
                  </IconButton>
                </Hidden>
                <Hidden mdUp implementation="css">
                  <IconButton aria-label="Open drawer" onClick={() => dispatch(toggleMobileMenu())}>
                    <MenuIcon />
                  </IconButton>
                </Hidden>
              </Grid>

              {componentsLeft && <Grid item>{componentsLeft}</Grid>}

              {/* </Grid> */}
              <Grid item style={{ flexGrow: 1 }}>
                <div />
              </Grid>

              {/* <Grid item container direction="row" xs={9} justify="flex-end" alignItems="center" wrap="nowrap"> */}
              {componentsRight && <Grid item>{componentsRight}</Grid>}

              <Grid item>
                <LanguagePanel />
              </Grid>

              <Grid item>
                <ProfilePanel mobileScreen={false} />
              </Grid>
            </Grid>
            // </Grid>
          )}
        </Toolbar>
      </AppBar>
    </ErrorBoundary>
  )
}

export default TopNavigation
