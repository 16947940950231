import { IStateReducer } from 'types/reducers'

export const START_REQUEST = 'workaware/app/state/START_REQUEST'
export const END_REQUEST = 'workaware/app/state/END_REQUEST'
export const SET_PAGE_TITLE = 'workaware/app/state/SET_PAGE_TITLE'
export const TOGGLE_SIDEBAR = 'workaware/app/state/TOGGLE_SIDE_BAR'
export const TOGGLE_MOBILE_MENU = 'workaware/app/state/TOGGLE_MOBILE_MENU'

/** @deprecated */
export const OPEN_NEW_ITEM_DRAWER = 'workaware/app/state/OPEN_NEW_ITEM_DRAWER'
/** @deprecated */
export const CLOSE_NEW_ITEM_DRAWER = 'workaware/app/state/CLOSE_NEW_ITEM_DRAWER'
/** @deprecated */
export const SET_FILTER_TEXT = 'workaware/app/state/SET_FILTER_TEXT'

export const startRequest = () => ({ type: START_REQUEST })
export const endRequest = () => ({ type: END_REQUEST })
export const setPageTitle = (title) => ({ type: SET_PAGE_TITLE, payload: title })
export const toggleSideDrawer = () => ({ type: TOGGLE_SIDEBAR })
export const toggleMobileMenu = () => ({ type: TOGGLE_MOBILE_MENU })

const initialState: IStateReducer = {
  loading: 0,
  pageTitle: '',
  sideMenuExpanded: true,
  mobileMenuOpen: false,
  drawerExpanded: false
}

const stateReducer = (state = initialState, action): IStateReducer => {
  switch (action.type) {
    case SET_PAGE_TITLE:
      return Object.assign({}, state, { pageTitle: action.payload })
    case START_REQUEST:
      return Object.assign({}, state, { loading: state.loading + 1 })
    case END_REQUEST:
      return Object.assign({}, state, { loading: Math.max(state.loading - 1, 0) })
    case TOGGLE_SIDEBAR:
      return { ...state, sideMenuExpanded: !state.sideMenuExpanded }
    case TOGGLE_MOBILE_MENU:
      return { ...state, mobileMenuOpen: !state.mobileMenuOpen }
    default:
      return state
  }
}

export default stateReducer
