import React from 'react'
import { WaoEmptyListPlaceholder } from 'components/Forms'
import CustomList from 'components/lists/CustomList'
import RasterlistListItem from 'components/list-items/RasterlistListItem'
import { RasterModel } from 'data/models/RasterModel'

interface Props {
  raster: RasterModel[]
  filterText?: string
  selectedRaster?: RasterModel[]
  handleSelect?: (raster: RasterModel) => void
  multiSelect?: boolean
}
const RasterList: React.FC<Props> = ({ filterText = '', raster, selectedRaster = [], handleSelect, multiSelect = false }) => {
  return (
    <React.Fragment>
      {raster.length > 0 && (
        <CustomList ariaLabel="team items">
          {raster
            .filter((u) => selectedRaster.includes(u) || u.Name?.toLowerCase().includes(filterText))
            .map((item) => (
              <RasterlistListItem multiSelect={multiSelect} selected={selectedRaster.includes(item)} key={item.Id} handleSelect={handleSelect} raster={item}  />
            ))}
        </CustomList>
      )}

      {(!raster || raster.length === 0) && <WaoEmptyListPlaceholder />}
    </React.Fragment>
  )
}

export default RasterList
