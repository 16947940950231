import { WorkawareApi } from 'apis/WorkawareApi';
import { TeamFileLinkDto } from 'data/dtos';
import { FileModel, TeamModel } from 'data/models';

export class teamFileLinkService {

  private _api: WorkawareApi;

  constructor(api: WorkawareApi) {
    this._api = api;
  }

  link = (file: FileModel, team: TeamModel): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (team.Id === undefined || file.Id === undefined) {
        return reject('team and file must have an id assigned');
      }
      this._api.teamFileLink.link(new TeamFileLinkDto(team.Id, file.Id))
        .then(() => resolve())
        .catch((error: any) => reject(error));
    });
  }

  unlink = (file: FileModel, team: TeamModel): Promise<void> => {

    return new Promise((resolve, reject) => {
      if (team.Id === undefined || file.Id === undefined) {
        return reject('team and file must have an id assigned');
      }
      this._api.teamFileLink.unlink(new TeamFileLinkDto(team.Id, file.Id))
        .then(() => resolve())
        .catch((error: any) => reject(error));
    });
  }
}