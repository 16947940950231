import React from 'react'
import clsx from 'clsx'
import { uiSettings } from 'etc/settings'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import ProfilePanel from 'components/ProfilePanel'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Branding from 'components/navigation/branding'
import { useDispatch, useSelector } from 'react-redux'
import { IRootReduxState } from 'types/reducers'
import { toggleMobileMenu } from 'reducers/stateReducer'

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: uiSettings.drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    '& .MuiListItemIcon-root': {
      minWidth: 50
    }
  },
  drawerOpen: {
    width: uiSettings.drawerWidth,
    zIndex: 1202,
    transition: theme.transitions.create(['width', 'zIndex'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create(['width', 'zIndex'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    zIndex: 1200,
    overflowX: 'hidden',
    width: uiSettings.drawerWidthClosed,
    [theme.breakpoints.up('sm')]: {
      width: uiSettings.drawerWidthClosed,
    },
  },
  toolbar: (props: any) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0, 1),
    height: props.bigScreen ? `${uiSettings.header.appBarHeight}px` : `${uiSettings.header.appBarHeightMobile}px`,
    // necessary for content to be below app bar
  }),
  logo: {
    width: 170,
    height: 30,
    objectFit: 'contain',
    transform: 'rotate(0deg)',
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  sideMenuWrapper: {
    overflowX: 'hidden',
    overflowY: 'auto',
    maxHeight: 'calc(100% - 65px)',
    '&::-webkit-scrollbar': {
      width: '4px',
    },
    '&::-webkit-scrollbar-track': {
      background: theme.palette.type === 'light' ? '#f1f1f1' : theme.palette.grey[800],
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.type === 'light' ? '#dadada' : '#b1b1b1',
      '&:hover': {
        background: theme.palette.type === 'light' ? '#b1b1b1' : '#dadada',
      },
    },
  },
  sideMenuContainer: {
    margin: '0px auto',
    width: '100%',
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  sideMenuContainerOpen: {
    width: '90%',
  },
}))

interface Props {
  /** @deprecated */
  expanded?: boolean
  /** @deprecated */
  mobileMenuOpen?: boolean
  /** @deprecated */
  mobileMenuToggle?: () => void
  /** @deprecated */
  toggleExpanded?: () => void
}
const SideNavigation: React.FC<Props> = ({ children }) => {
  const bigScreen = useMediaQuery('(min-width:960px)')
  const classes = useStyles({ bigScreen })
  const expanded = useSelector((state: IRootReduxState) => state.app.state.sideMenuExpanded)
  const mobileMenuOpen = useSelector((state: IRootReduxState) => state.app.state.mobileMenuOpen)
  const dispatch = useDispatch()

  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          classes={{
            paper: classes.drawer,
          }}
          anchor={'left'}
          open={mobileMenuOpen}
          onClose={() => dispatch(toggleMobileMenu())}
          ModalProps={{ keepMounted: true }}
        >
          <div className={classes.toolbar}>
            <Branding width={215}></Branding>
          </div>
          <div className={classes.sideMenuWrapper}>
            <div className={clsx(classes.sideMenuContainer, { [classes.sideMenuContainerOpen]: expanded })}>
              {children}
              <Hidden implementation="css" smUp>
                <ProfilePanel mobileScreen={true} />
              </Hidden>
            </div>
          </div>
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: expanded,
            [classes.drawerClose]: !expanded,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: expanded,
              [classes.drawerClose]: !expanded,
            }),
          }}
        >
          <div className={classes.toolbar}>
            <Branding width={215}></Branding>
          </div>
          <div className={classes.sideMenuWrapper}>
            <div className={clsx(classes.sideMenuContainer, { [classes.sideMenuContainerOpen]: expanded })}>{children}</div>
          </div>
        </Drawer>
      </Hidden>
    </div>
  )
}

export default SideNavigation
