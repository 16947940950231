import { is, humanFileSize } from 'utils/utilities'
import moment from 'moment'
import { FileModel, TeamModel, CultureGroupModel } from 'data/models'

interface Dictionary<T> {
  [Key: string]: T
}

export const createFileTree = (files: FileModel[], separator = '/', rootName = 'root') => {
  if (!is.Array(files)) throw Error('files must be an array')
  if (!is.String(separator)) throw Error('separator must be a string')
  const tree = new FileTree(rootName)
  files.forEach((file) => tree.addFile(new FileNode(file)))
  return tree
}

export class FileNode {
  public id: number = 0
  public text: string = ''
  public folder: string = ''
  public rawSize: number = 0
  public size: string = ''
  public created: string = ''
  public createdOn?: Date = undefined
  public selected: boolean = false
  public culture: string | undefined = undefined
  public cultures: Dictionary<number> = {}
  public cultureGroup?: string = undefined
  public cultureGroupName?: string = undefined
  public teams: TeamModel[] = []

  constructor(obj: FileModel) {
    this.id = obj.Id || 0
    this.text = obj.Filename || ''
    this.folder = obj.Folder || ''
    this.rawSize = obj.Filesize || 0
    this.size = humanFileSize(obj.Filesize || 0)
    this.createdOn = obj.CreatedOn
    this.created = moment(obj.CreatedOn).fromNow()
    this.selected = false
    this.cultureGroup = obj.CultureGroup
    this.cultureGroupName = obj.CultureGroupName
    this.culture = obj.Culture
    this.teams = obj.Teams

    if (this.culture) {
      this.cultures[this.culture] = this.id
    }
  }

  toFileModel(): FileModel {
    let model = new FileModel()
    model.Id = this.id
    model.Filename = this.text
    model.Folder = this.folder
    model.Filesize = this.rawSize
    model.CreatedOn = this.createdOn
    model.CultureGroup = this.cultureGroup
    model.Culture = this.culture
    model.Teams = this.teams
    return model
  }
}

export class Node {
  public text: string = ''
  public items: Node[] = []
  public files: FileNode[] = []
  public parent: Node | undefined = undefined
  public isRootNode: boolean = false

  constructor(name: string) {
    this.text = name
  }

  _path = (): string => {
    let path = ''
    if (this.parent !== undefined) {
      path += this.parent.getPath()
    }
    path += '/' + this.text
    return path
  }

  getContainingFolder = (removeLeadingSlash: boolean = false): string => {
    let path = this.getPath(removeLeadingSlash)
    path = path.substring(0, path.lastIndexOf('/'))
    return path
  }

  getPath = (removeLeadingSlash: boolean = false): string => {
    let path = this._path()
    if (path[0] === '/' && removeLeadingSlash) {
      path = path.substring(1)
    }
    return path
  }

  childExists = (search: string): boolean => {
    if (!is.String(search)) return false
    return this.getChild(search) !== undefined ? true : false
  }

  createChild = (name: string): Node | undefined => {
    if (!this.childExists(name)) {
      const node = new Node(name)
      node.parent = this
      this.items.push(node)
    }
    return this.getChild(name)
  }

  deleteChild = (node: Node): Node[] => {
    this.items = this.items.filter((i) => i !== node)
    return this.items
  }

  getChild = (search: string): Node | undefined => {
    return this.items.find((f) => f.text === search)
  }
}

export class FileTree {
  public root: Node
  public rootName: string

  constructor(name: string = 'root') {
    this.rootName = name
    this.root = new Node(this.rootName)
    this.root.isRootNode = true
  }

  renameFolder = (oldName: string, newName: string): FileTree => {
    const node = this.findNodeInPath(oldName)
    if (node !== undefined) {
      node.text = newName.substring(newName.lastIndexOf('/') + 1)
    }
    return this
  }

  removeFolder = (name: string): FileTree => {
    const node = this.findNodeInPath(name)
    if (node !== undefined) {
      node.parent?.deleteChild(node)
    }
    return this
  }

  removeFileById = (id: number, node: Node = this.root): FileTree => {
    node.items.forEach((node) => this.removeFileById(id, node))
    node.files = node.files.filter((v) => v.id !== id)
    return this
  }

  moveFileById = (id: number, path: string, separator: string = '/'): FileTree => {
    const file = this.detachFileById(id)
    if (file) {
      const node = this.findNodeInPath(path, this.root, separator)
      if (node) {
        file.folder = path
        node.files.push(file)
      }
    }
    return this
  }

  detachFileById = (id: number, node: Node = this.root): FileNode | null => {
    for (let i = 0; i < node.files.length; i++) {
      if (node.files[i].id === id) {
        let files = node.files.splice(i, 1)
        return files[0]
      }
    }
    for (let i = 0; i < node.items.length; i++) {
      let result = this.detachFileById(id, node.items[i])
      if (result) return result
    }
    return null
  }

  joinFileCultureGroup = (file: FileNode, group: CultureGroupModel, separator: string = '/') => {
    const node = this.findNodeInPath(file.folder, this.root, separator)
    if (node !== undefined) {
      node.files.map((f: FileNode) => (f.id === file.id ? { ...file, cultureGroup: group } : f))
    }
    return this
  }

  leaveFileCultureGroup = (file: FileNode, separator: string = '/') => {
    const node = this.findNodeInPath(file.folder, this.root, separator)
    if (node !== undefined) {
      node.files.map((f) => (f.id === file.id ? { ...file, cultureGroup: undefined } : f))
    }
    return this
  }

  addFile = (file: FileNode, separator: string = '/') => {
    const node = this.findNodeInPath(file.folder, this.root, separator)
    if (node !== undefined) {
      // check for culture groupings
      if (file.cultureGroup && file.culture) {
        const cgFile = node.files.find((f) => f.cultureGroup === file.cultureGroup)
        if (cgFile) {
          cgFile.cultures[file.culture] = file.id
          return
        }
      }
      if (file.text) {
        node.files.push(file)
      }
    }
    return this
  }

  createChildrenFromPath = (path: string, node: Node = this.root, separator: string = '/'): Node | undefined => {
    const result = this._getPathPiece(path, separator)
    const child = node.childExists(result.piece) ? node.getChild(result.piece) : node.createChild(result.piece)
    path = result.path
    if (child === undefined || path.length === 0) {
      return child
    }
    return this.createChildrenFromPath(path, child, separator)
  }

  findNodeInPath = (path: string, node: Node = this.root, separator: string = '/'): Node | undefined => {
    const result = this._getPathPiece(path, separator)
    path = result.path
    // handle root node
    if (result.piece === this.rootName && node.isRootNode) {
      return path.length === 0 ? node : this.findNodeInPath(path, node, separator)
    }
    // handle child nodes
    const child = node.childExists(result.piece) ? node.getChild(result.piece) : node.createChild(result.piece)
    if (child === undefined || path.length === 0) {
      return child
    }
    return this.findNodeInPath(path, child, separator)
  }

  _getPathPiece = (path: string, separator: string = '/'): { path: string; piece: string } => {
    if (path[0] === separator) path = path.substring(1)
    const parts = path.split(separator)
    const piece = parts.splice(0, 1)
    return {
      path: parts.join(separator),
      piece: piece[0],
    }
  }
}
