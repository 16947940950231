import { PasswordGenerationRequest, PasswordUpdateRequest, TeamFileLinkDto } from 'data/dtos'
import { CompanyModuleAccessDto } from 'data/dtos/CompanyModuleAccessDto'
import { UsernameExistsDto } from 'data/dtos/UsernameExistsDto'
import { ValueTranslationRequestDto, ValueTranslationResponseDto } from 'data/dtos/ValueTranslationDto'
import { AnonymousFormModel, CompanyFormModel, CultureGroupModel, TeamModel, UserModel } from 'data/models'
import { EmailTemplateModel } from 'data/models/EmailTemplateModel'
import { JourneyModel } from 'data/models/JourneyModel'
import { RasterModel } from 'data/models/RasterModel'
import UploadTypes from 'enums/UploadTypes'
import { apiSettings } from 'etc/settings'
import { Dictionary } from 'types'
import { FormTemplateFilterArguments, IWorkawareApi, PagedArguments, PagedResult } from 'types/apis'
import { getUser } from 'utils/configureStore'
import { requestBuilder } from 'utils/request'

const request = requestBuilder(apiSettings.workaware)

export class WorkawareApi implements IWorkawareApi {
  private _getCompanyId = (): number => getUser()?.profile.company_id
  private _getCurrentUserId = (): number => getUser()?.profile.user_id

  public reporting = {
    utilities: {
      translate: (data: ValueTranslationRequestDto) => request<ValueTranslationResponseDto>(`/api/v2/reporting/translate`, { method: 'post', data }),
    },
  }

  public calendar = {
    feed: {
      list: () => request('/api/v2/calendars/external'),
      external: (guid: string) => request(`/api/v2/calendar/external/${guid}`, { responseType: 'text' }),
      allAssignments: () => request('/api/v2/calendar/allAssignments', { responseType: 'text' }),
      allIncompleteAssignments: () => request('/api/v2/calendar/allIncomplete', { responseType: 'text' }),
      myAssignments: () => request('/api/v2/calendar/assignments', { responseType: 'text' }),
      myIncompleteAssignments: () => request('/api/v2/calendar/incompleteAssignments', { responseType: 'text' }),
    },
  }

  public assets = {
    overdue: () => request('/odata/CMMSAssetOverdueDTOs'),
    getList: () => request('/odata/CMMSAssets?$select=Id,Name,Description'),
    getBarcodeTypes: () => request('/api/v2/Assets/BarcodeTypes'),
    getOdometerUnitTypes: () => request('/api/v2/Assets/OdometerUnitTypes'),
    get: (id: any) => request(`/api/v2/assets/${id}`),
    post: (data: any): Promise<any> => request(`/api/v2/assets`, { method: 'post', data }, { 'content-type': 'application/json' }),
    put: (data: any): Promise<any> => request(`/api/v2/assets`, { method: 'put', data }, { 'content-type': 'application/json' }),
    delete: (id: any): Promise<any> => request(`/api/v2/assets/${id}`, { method: 'delete' }),
    sites: {
      getList: () => request('/odata/CMMSSites?$select=Id,Name'),
      getTree: () => request('/api/v2/sitestree'),
      getTypes: () => request('/api/v2/sitetypes'),
      get: (id: any) => request(`/api/v2/sites/${id}`),
      post: (data: any): Promise<any> => request(`/api/v2/sites`, { method: 'post', data }, { 'content-type': 'application/json' }),
      put: (data: any): Promise<any> => request(`/api/v2/sites`, { method: 'put', data }, { 'content-type': 'application/json' }),
      delete: (id: any): Promise<any> => request(`/api/v2/sites/${id}`, { method: 'delete' }),
    },
    photos: {
      upload: (id: any, data: FormData): Promise<any> => request(`/api/v2/assets/${id}/photos`, { method: 'post', data }, { 'Content-Type': 'false' }),
      download: (id: any): Promise<Blob> => request(`/api/v2/assets/photos/${id}`, { responseType: 'blob' }),
    },
    documents: {
      upload: (id: any, data: FormData): Promise<any> => request(`/api/v2/assets/${id}/documents`, { method: 'post', data }, { 'Content-Type': 'false' }),
      download: (id: any): Promise<Blob> => request(`/api/v2/assets/documents/${id}`, { responseType: 'blob' }),
    }
  }

  public forms = {
    dynamic: {
      template: (id: number) => request(`/api/v2/companyForms/dynamic/${id}`),
      get: (id: number): Promise<any> => request(`/api/v2/formCompletion/${id}`),
      post: (data: any): Promise<any> => request(`/api/v2/formCompletion`, { method: 'post', data }, { 'content-type': 'application/json' }),
      put: (data: any): Promise<any> => request(`/api/v2/formCompletion`, { method: 'put', data }, { 'content-type': 'application/json' }),
      attachments: {
        get: (id: number): Promise<any> => request(`/api/v2/formCompletion/${id}/attachments`),
        post: (id: number, data: FormData): Promise<any> =>
          request(
            `/api/v2/formCompletion/${id}/attachment`,
            { method: 'post', data },
            {
              'Content-Type': 'false',
            }
          ),
        delete: (id: number, attachmentId: string): Promise<any> => request(`/api/v2/formCompletion/${id}/attachment/${attachmentId}`, { method: 'delete' }),
        download: (formId: number, attachmentId: string): Promise<Blob> => request(`/api/v2/formCompletion/${formId}/attachment/${attachmentId}`, { responseType: 'blob' }),
      },
    },
    detail: {
      get: (id: number): Promise<Blob> => request(`/odata/FormCompletions(${id})/Detail`, { responseType: 'blob' }, { Accept: 'application/pdf' }),
      post: (id: number, data: any): Promise<any> => request(`/odata/FormCompletions(${id})/Detail`, { method: 'post', data }, { 'content-type': 'application/pdf' }),
    },
    anonymous: {
      all: (): Promise<any> => request(`/api/v2/companyForms/anonymous?count=true`),
      create: (data: AnonymousFormModel): Promise<any> => request(`/api/v2/companyForms/anonymous`, { method: 'post', data }),
      delete: (id: string): Promise<any> => request(`/api/v2/companyForms/anonymous/${id.toLocaleUpperCase()}`, { method: 'delete' }),
      update: (id: string, data: AnonymousFormModel): Promise<any> => request(`/api/v2/companyForms/anonymous/${id}`, { method: 'put', data }),
    },
    templates: {
      all: (): Promise<any> => request(`/api/v2/companyForms`),
      get: (id: number): Promise<CompanyFormModel> => request(`/api/v2/companyForms/${id}`),
      update: (template: CompanyFormModel): Promise<CompanyFormModel> => request(`/api/v2/companyForm`, { method: 'put', data: template }),
      insert: (template: CompanyFormModel): Promise<CompanyFormModel> => request(`/api/v2/companyForm`, { method: 'post', data: template }),
      delete: (id: number): Promise<any> => request(`/api/v2/companyForms/${id}`, { method: 'delete' }),
      paged: (args: PagedArguments & { dynamic: boolean; showAll: boolean }): Promise<any> => {
        const { offset, take, showAll, dynamic } = args
        return request(`/api/v2/companyForms/?offset=${offset}&take=${take}&showAll=${showAll ? 1 : 0}&dynamic=${dynamic ? 1 : 0}`)
      },
      pagedFilteredTagged: (args: PagedArguments & FormTemplateFilterArguments): Promise<any> => {
        const { offset, take, ...filterArgs } = args
        return request(`/api/v2/companyForms/filtered?offset=${args.offset}&take=${args.take}`, { method: 'post', data: filterArgs })
      },
      search: {
        tags: (search: string = '', count: number = 5): Promise<string[]> => {
          return request(`/api/v2/companyForms/search/tags?search=${search}&count=${count}`)
        },
        notificationLevels: (search: string = '', count: number = 5): Promise<Dictionary<string>> => {
          return request(`/api/v2/companyforms/search/notificationlevels?search=${search}&count=${count}`)
        },
      },
      active: (): Promise<any> => request(`/odata/CompanyForms?$filter=IsActive&$orderby=Name`),
      grouped: (): Promise<any> => request(`/odata/CompanyForms/Workaware.Server.Grouped()/?$expand=Forms,Groups($expand=Forms)`),
      //get: (id: number): Promise<any> => request(`/odata/CompanyForms(${id})`),
      createFormCompletionRecord: (id: number): Promise<any> => request(`/odata/CompanyForms(${id})/CreateFormCompletion`),
      getBlank: (id: number): Promise<Blob> => request(`/odata/CompanyForms(${id})/Template`, { responseType: 'blob' }),
      getPopulated: (id: number): Promise<Blob> => request(`/odata/CompanyForms(${id})/Workaware.Server.PopulatedTemplate`, { responseType: 'blob' }),
      post: (data: any): Promise<any> => request(`/api/v2/companyForms/dynamic`, { method: 'post', data }, { 'content-type': 'application/json' }),
      put: (id: number | undefined, data: any): Promise<any> => request(`/api/v2/companyForms/dynamic/${id}`, { method: 'put', data }, { 'content-type': 'application/json' }),
    },
    attachments: {
      get: (id: number): Promise<any> => request(`/odata/FormCompletions(${id})/Attachments`),
      post: (id: number, data: any): Promise<any> => request(`/odata/FormCompletions(${id})/Attachments`, { method: 'post', data }),
      delete: (id: number, data: any): Promise<any> => request(`/odata/FormCompletions(${id})/Attachments`, { method: 'delete' }),
    },
    completed: {
      //query can include all odata clauses
      //queriedWithCount: (query: string): Promise<any> => request(`/api/v2/formCompletions?$count=true&${query}`),
      //queriedWithCount: (query: string): Promise<any> => request(`/odata/FormCompletions?$count=true&${query}`),
      aggregated: (data: any): Promise<any> => request(`/api/v2/formCompletions/aggregated`, { method: 'post', data }),
      all: (query?: string): Promise<any> => request(`/api/v2/formCompletions?${query || ''}`),
      paged: (args: PagedArguments, query?: string): Promise<any> => request(`/api/v2/formCompletions/?offset=${args.offset}&take=${args.take}&${query || ''}`),
      total: (): Promise<any> => request(`/odata/FormCompletions/$count`),
      //filter should be $filter clause only; including $skip or $top will cause a server error
      totalFiltered: (filter: string): Promise<any> => request(`/odata/FormCompletions?$count=true&$top=0&${filter}`),
      get: (id: number): Promise<any> => request(`/api/v2/formCompletion/${id}`),
      delete: (id: number): Promise<any> => request(`/odata/FormCompletions(${id})`, { method: 'delete' }),
      changeOwner: (formId: number, newOwnerId: number): Promise<any> => request(`/odata/FormCompletions(${formId})/ChangeOwner`, { method: 'post', data: { NewOwner: newOwnerId } }),
    },
    stateFlowTemplates: {
      getAll: (): Promise<any> => request(`/api/v2/stateFlowTemplates`),
    },
  }

  public teamFileLink = {
    link: (dto: TeamFileLinkDto): Promise<any> => request(`/api/v2/teamFileLink/`, { method: 'post', data: JSON.stringify(dto) }),
    unlink: (dto: TeamFileLinkDto): Promise<any> => request(`/api/v2/teamFileLink/`, { method: 'delete', data: JSON.stringify(dto) }),
  }

  public download = {
    file: (id: number): Promise<Blob> => request(`/api/DownloadAPI/GetFile/${this._getCompanyId()}/${this._getCurrentUserId()}/${id}`, { responseType: 'blob' }),
    userFile: (file: string): Promise<Blob> => request(`/api/DownloadAPI/GetUserFile/${this._getCompanyId()}/${this._getCurrentUserId()}/10/${file}/`, { responseType: 'blob' }),
    fileInCultureGroup: (id: string): Promise<any> => request(`/api/DownloadAPI/GetFileInCultureGroup/${this._getCompanyId()}/${this._getCurrentUserId()}/${id}`, { responseType: 'blob' }),
  }

  public folders = {
    getAll: (): Promise<any> => request(`/api/FileAPI/GetFolders/${this._getCompanyId()}`),
    create: (name: string): Promise<any> => request(`/api/FileAPI/CreateFolder`, { method: 'post', data: { name } }),
    delete: (folder: string): Promise<any> => request(`/api/FileAPI/DeleteFolder`, { method: 'post', data: JSON.stringify(folder) }),
    rename: (oldName: string, newName: string): Promise<void> =>
      request(`/api/FileAPI/RenameFolder`, {
        method: 'post',
        data: {
          NewFolderName: newName,
          OldFolderName: oldName,
        },
      }),
    changeFolder: (id: number, newName: string): Promise<void> =>
      request(`/api/v2/file/changeFileFolder`, {
        method: 'post',
        data: JSON.stringify({
          Id: id,
          NewFolderName: newName,
        }),
      }),
  }

  public files = {
    cultureGroups: {
      getAll: (): Promise<CultureGroupModel[]> => request(`/api/v2/cultureGroups`),
      get: (id: string): Promise<CultureGroupModel | undefined> => request(`/api/v2/cultureGroup/${id}`),
      create: (data: CultureGroupModel): Promise<CultureGroupModel | undefined> => request(`/api/v2/cultureGroup`, { method: 'post', data }),
      update: (data: CultureGroupModel): Promise<CultureGroupModel | undefined> => request(`/api/v2/cultureGroup`, { method: 'put', data }),
      delete: (id: string): Promise<any> => request(`/api/v2/cultureGroup/${id}`, { method: 'delete' }),
      link: (fileId: number, groupId: string): Promise<CultureGroupModel> => request(`/api/v2/file/cultureGroup/${fileId}/${groupId}`, { method: 'post' }),
      unlink: (fileId: number): Promise<void> => request(`/api/v2/file/cultureGroup/${fileId}`, { method: 'delete' }),
    },
    getAll: (): Promise<any> => request(`/api/v2/files?includeBlankFilenames`),
    get: (id: number): Promise<any> => request(`/api/v2/file/${id}`),
    create: (data: any): Promise<any> => request(`/odata/Files`, { method: 'post', data }),
    put: (id: number, data: any): Promise<any> => request(`/odata/Files(${id})`, { method: 'put', data }),
    update: (id: number, data: any): Promise<any> => request(`/odata/Files(${id})`, { method: 'patch', data }),
    delete: (id: number): Promise<any> => request(`/odata/Files(${id})`, { method: 'delete' }),
    upload: (data: any, filename: string): Promise<any> =>
      request(
        `/api/UploadAPI`,
        { method: 'post', data },
        {
          'Content-Type': 'false',
          UploadType: UploadTypes.CompanyFiles.value.toString(),
          FileName: filename,
        }
      ),
  }

  public company = {
    get: (id: number): Promise<any> => request(`/api/v2/company/${id}`),
    getLogo: (fileName: string): Promise<Blob> => request(`/api/DownloadAPI/GetServerFile/${this._getCompanyId()}/${this._getCurrentUserId()}/15/${fileName}/?ZUMO-API-VERSION=2.0.0`, { responseType: 'blob' }),
    update: (id: number, data: any): Promise<any> => request(`/odata/Companies(${id})`, { method: 'patch', data }),
  }

  public picklistItems = {
    getType: (id: number): Promise<any> => request(`/api/v2/picklist/${id}`),
    get: (id: number): Promise<any> => request(`/odata/PicklistItems(${id})`),
    create: (data: any): Promise<any> => request(`/odata/PicklistItems`, { method: 'post', data }),
    put: (id: number, data: any): Promise<any> => request(`/odata/PicklistItems(${id})`, { method: 'put', data }),
    update: (id: number, data: any): Promise<any> => request(`/odata/PicklistItems(${id})`, { method: 'patch', data }),
    delete: (id: number): Promise<any> => request(`/odata/PicklistItems(${id})`, { method: 'delete' }),
    all: (): Promise<any> => request(`/odata/PicklistItems`),
  }
  public emailTemplate = {
    getAll: (): Promise<{ value: string; name: string }[]> => request(`/api/v2/GetEmailTemplateTypes`),
    create: (data: any): Promise<any> => request(`/api/v2/EmailTemplatePost`, { method: 'post', data }),
    getTemplate: (emailType: number): Promise<any> => request(`/api/v2/GetDefaultEmailTemplate/${emailType}`),
    getByTypeId: (typeId: number): Promise<EmailTemplateModel[]> => request(`/api/v2/GetByTypeId/${typeId}`),
    delete: (id: number): Promise<any> => request(`/odata/EmailTemplates(${id})`, { method: 'delete' }),
    put: (data: any): Promise<any> => request(`/api/v2/EmailTemplate`, { method: 'put', data }),
  }
  public picklistTypes = {
    getAll: (): Promise<{ Key: number; Value: string }[]> => request(`/api/v2/picklists`),
    getAssetTypes: (): Promise<{ name: string; value: string }[]> => request(`/api/v2/AssetPicklistTypes`),
    get: (id: number): Promise<any> => request(`/odata/CompanyPicklistTypes(${id})`),
    create: (data: any): Promise<any> => request(`/odata/CompanyPicklistTypes`, { method: 'post', data }),
    put: (id: number, data: any): Promise<any> => request(`/odata/CompanyPicklistTypes(${id})`, { method: 'put', data }),
    update: (id: number, data: any): Promise<any> => request(`/odata/CompanyPicklistTypes(${id})`, { method: 'patch', data }),
    delete: (id: number): Promise<any> => request(`/odata/CompanyPicklistTypes(${id})`, { method: 'delete' }),
  }

  public user = {
    all: (): Promise<any> => request(`/odata/Users`),
    paged: (args: PagedArguments): Promise<PagedResult<UserModel>> => {
      let url = `/api/v2/users?offset=${args.offset}&take=${args.take}`
      if (args.filter?.name) {
        url += `&nameFilter=${args.filter.name}`
      }
      return request(url)
    },
    active: (): Promise<any> => request(`/odata/Users?$filter=IsActive&$orderby=Lastname asc,Firstname asc,PreferredName asc`),
    get: (id: number): Promise<any> => request(`/odata/Users(${id})`),
    create: (data: UserModel): Promise<any> => request(`/odata/Users`, { method: 'post', data }),
    put: (id: number, data: any): Promise<any> => request(`/odata/Users(${id})`, { method: 'put', data }),
    update: (id: number, data: Dictionary<any>): Promise<any> => request(`/odata/Users(${id})`, { method: 'patch', data }),
    delete: (id: number): Promise<any> => request(`/odata/Users(${id})`, { method: 'delete' }),
    uploadPhoto: (data: any, filename: string): Promise<any> =>
      request(
        `/api/UploadAPI`,
        { method: 'post', data },
        {
          'Content-Type': 'false',
          UploadType: UploadTypes.UserFilePhotos.value.toString(),
          FileName: filename,
        }
      ),
    photo: (companyId: number, userId: number, url: string): Promise<any> => request(`/api/DownloadAPI/GetUserFile/${companyId}/${userId}/10/${url}/?ZUMO-API-VERSION=2.0.0`),
    signature: (companyId: number, userId: number, url: string): Promise<any> => request(`/api/DownloadAPI/GetUserFile/${companyId}/${userId}/14/${url}/?ZUMO-API-VERSION=2.0.0`),
    generatePassword: (data: PasswordGenerationRequest): Promise<any> => request(`/api/userApi/generateAndSetPassword`, { method: 'patch', data }),
    updatePassword: (data: PasswordUpdateRequest): Promise<any> => request(`/api/userApi/updatePassword`, { method: 'post', data }),
    checkUsername: (Username: string): Promise<UsernameExistsDto> => request(`/api/v2/users/checkUsername`, { method: 'post', data: JSON.stringify(Username) }),
  }

  public teams = {
    //getByIds: (ids: number[]): Promise<any> => request(`/odata/OrganizationTeamHierarchies?${this._buildIdMatchingFilter(ids)}`),
    getByIds: (ids: number[]): Promise<any> => request(`/api/v2/teams/?${ids.map((id) => 'ids=' + id).join('&')}`),
    all: (): Promise<any> => request(`/api/v2/teams`),
    mine: (): Promise<TeamModel[]> => request(`/api/v2/teams/mine`),
    get: (id: number): Promise<any> => request(`/api/v2/team/${id}`),
    patch: (id: number, data: any): Promise<any> => request(`/odata/OrganizationTeamHierarchies(${id})`, { method: 'patch', data }),
    post: (data: any): Promise<any> => request(`/odata/OrganizationTeamHierarchies`, { method: 'post', data }),
    delete: (id: number): Promise<any> => request(`/odata/OrganizationTeamHierarchies(${id})`, { method: 'delete' }),
    members: {
      all: (): Promise<any> => request(`/odata/OrganizationTeamMemberHierarchies`),
      get: (id: number): Promise<any> => request(`/odata/OrganizationTeamMemberHierarchies?$filter=OrganizationTeamHierarchyId eq ${id}`),
      delete: (id: number): Promise<any> => request(`/odata/OrganizationTeamMemberHierarchies(${id})`, { method: 'delete' }),
      post: (data: any): Promise<any> => request(`/odata/OrganizationTeamMemberHierarchies`, { method: 'post', data }),
    },
    attributes: {
      all: (id: number): Promise<any> => request(`/odata/OrganizationTeamHierarchyAttributes?$filter=OrganizationTeamHierarchyId%20eq%20${id}`),
      getCalculated: (id: number): Promise<any> => request(`/odata/OrganizationTeamHierarchies(${id})/Workaware.Server.GetEffectiveAttributes()/`),
      create: (data: any): Promise<any> => request(`/odata/OrganizationTeamHierarchyAttributes`, { method: 'post', data }),
      update: (id: number, data: any): Promise<any> => request(`/odata/OrganizationTeamHierarchyAttributes(${id})`, { method: 'patch', data }),
      remove: (id: number): Promise<any> => request(`/odata/OrganizationTeamHierarchyAttributes(${id})`, { method: 'delete' }),
    },
  }

  public messaging = {
    serviceConnectionString: () => request(`/api/Messaging/ConnectionString`),
  }
  private urlSafeBase64Encode = (string: string) => {
    //remove trailing =
    var base64 = btoa(string)
    while (base64[base64.length - 1] === '=') {
      base64 = base64.slice(0, base64.length - 1)
    }
    return base64
      .replace('+', '.')
      .replace('/', '_')
      .replace('=', '-')
  }
  public assignment = {
    assignedToLoggedInUser: {
      all: (): Promise<{ value: any }> => request(`/odata/Assignments/Workaware.Server.AllAssignedToDirectly()/`),
      incomplete: (): Promise<{ value: any }> => request(`/odata/Assignments/Workaware.Server.AllAssignedToDirectly()/?$filter=CompletedOn eq null or CompletedByUserId eq null&$orderby=AssignedOn`),
    },
    assignedByLoggedInUser: {
      incompleteTopLevel: (): Promise<{ value: any }> => request(`/odata/Assignments/Workaware.Server.AllAssignedBy()/?$filter=(CompletedOn eq null or CompletedByUserId eq null) and ParentAssignmentId eq null&$orderby=AssignedOn desc`),
      incomplete: (): Promise<{ value: any }> => request(`/odata/Assignments/Workaware.Server.AllAssignedBy()/?$filter=CompletedOn eq null or CompletedByUserId 0eq null&$orderby=AssignedOn desc`),
      all: (): Promise<{ value: any }> => request(`/odata/Assignments/Workaware.Server.AllAssignedBy()/`),
    },
    v2: {
      get: (id: number) => request(`/api/v2/assignmentDetails?$filter=Id eq ${id}`),
      update: (id: number, data: any) => request(`/api/v2/updateAssignment/${id}`, { method: 'post', data }),
      assignedToLoggedInUser: {
        all: () => request(`/api/v2/AllAssignedToDirectly?$orderby=AssignedOn`),
        incomplete: () => request(`/api/v2/AllAssignedToDirectly?$filter=CompletedOn eq null or CompletedByUserId eq null&$orderby=AssignedOn desc`),
      },
      assignedByLoggedInUser: {
        all: () => request(`/api/v2/AllAssignedBy?$orderby=AssignedOn`),
        incompleteTopLevel: () => request(`/api/v2/AllAssignedBy?$filter=(CompletedOn eq null or CompletedByUserId eq null) and ParentAssignmentId eq null&$orderby=AssignedOn desc`),
        get: (id: number) => request(`/api/v2/AllAssignedBy?$filter=Id eq ${id}`),
      },
      /**
       * Sets the assignment as completed by the currently authenticated user
       * @param id
       */
      complete: (id: number) => request(`/api/v2/completeAssignment/${id}`),
    },
    all: () => request(`/odata/Assignments`),
    incompleteCount: () => request(`/odata/Assignments?$filter=ParentAssignmentId eq null and (CompletedOn eq null or CompletedByUserId eq null)&$top=0&$count=true`),
    get: (id: number) => request(`/odata/Assignments(${id})`),
    allTopLevel: (): Promise<any> => request(`/odata/Assignments?$filter=ParentAssignmentId eq null`),
    incompleteTopLevel: (limit: number = 10): Promise<any> => request(`/odata/Assignments?$filter=ParentAssignmentId eq null and (CompletedOn eq null or CompletedByUserId eq null)&$orderby=AssignedOn&$top=${limit}`),
    item: (id: number): Promise<any> => request(`/odata/Assignments(${id})/Workaware.Server.GetAssignedItem()/?$expand=FormCompletion,Template`),
    update: (id: number, data: any): Promise<any> => request(`/odata/Assignments(${id})`, { method: 'patch', data }),
    delete: (id: number): Promise<any> => request(`/odata/Assignments(${id})`, { method: 'delete' }),
    sendNotification: (id: number): Promise<any> => request(`/odata/Assignments(${id})/Workaware.Server.SendNotificationForAssignment()/`),
    children: (id: string): Promise<{ value: any }> => request(`/odata/Assignments?$filter=ParentAssignmentId eq ${id}`),
    incompleteChildren: (id: string): Promise<{ value: any }> => request(`/odata/Assignments?$filter=ParentAssignmentId eq ${id} and (CompletedOn eq null or CompletedByUserId eq null)`),
    create: {
      fromTemplate: (id: number): Promise<any> => request(`/odata/CompanyForms(${id})/Workaware.Server.CreateAssignment()/`),
      fromForm: (id: number): Promise<any> => request(`/odata/FormCompletions(${id})/Workaware.Server.CreateAssignment()/`),
      fromModel: (data: any) => request(`/api/v2/createAssignment`, { method: 'post', data }),
    },
    forEvent: (id: string) => request(`/api/v2/assignmentsForEvent/${this.urlSafeBase64Encode(id)}`),
  }
  public companyModuleSecurity = {
    all: (): Promise<any> => request(`/odata/CompanyModuleSecurity`),
    update: (id: number, data: any): Promise<any> => request(`/odata/CompanyModuleSecurity(${id})`, { method: 'patch', data }),
  }
  public journeyManagement = {
    getAllJourneys: (): Promise<any> => request('/odata/Journeys'),
    create: (data: JourneyModel): Promise<any> => request('/odata/Journeys', { method: 'post', data }),
    update: (id: number, data: JourneyModel): Promise<any> => request(`/odata/Journeys(${id})`, { method: 'patch', data }),
  }
  public raster = {
    all: (): Promise<any> => request(`/odata/Rasters`),
    get: (id: number) => request(`/odata/Rasters(${id})`),
    create: (data: RasterModel): Promise<any> => request(`/odata/Rasters`, { method: 'post', data }),
    put: (id: number, data: any): Promise<any> => request(`/odata/Rasters(${id})`, { method: 'put', data }),
    update: (id: number, data: RasterModel): Promise<any> => request(`/odata/Rasters(${id})`, { method: 'patch', data }),
  }
  public maintenanceSchedules = {
    getList: (): Promise<any> => request(`/api/v2/MaintenanceSchedules`),
    get: (id: number) => request(`/api/v2/MaintenanceSchedules/${id}`),
    post: (data: any): Promise<any> => request(`/api/v2/MaintenanceSchedules`, { method: 'post', data }, { 'content-type': 'application/json' }),
    put: (data: any): Promise<any> => request(`/api/v2/MaintenanceSchedules`, { method: 'put', data }, { 'content-type': 'application/json' }),
    delete: (id: any): Promise<any> => request(`/api/v2/MaintenanceSchedules/${id}`, { method: 'delete' }),
  }
  public shapeFiles = {
    all: (): Promise<any> => request('/odata/Shapefiles'),
  }
  public trackingCurrentPosition = {
    getTrackingUser: (): Promise<any> => request('/api/TrackingAPI/GetCurrentPositions'),
    getHistoricPositions: (data): Promise<any> => request(`/api/TrackingAPI/GetHistoricPositions`, { method: 'post', data }),
  }
  public userShapes = {
    getUserShapes: (): Promise<any> => request('/api/UserShapes/Shapes'),
  }
  public userGeneratedShapes = {
    paged: (): Promise<any> => request(`/api/UserShapes/Shapes/`),
    delete: (id: string): Promise<any> => request(`/api/UserShapes/Shapes/${id}`, { method: 'delete' }),
    update: (id: string, data: RasterModel): Promise<any> => request(`/api/UserShapes/Shapes/${id}`, { method: 'put', data }),
  }
  public journeyCheckPoints ={
    getAll: (): Promise<any> => request('/odata/JourneyCheckpoints'),
    post:(data : any):Promise<any> =>request(`/odata/JourneyCheckpoints`, { method: 'post', data })
  }
  public modulesAccess = {
    get: (): Promise<CompanyModuleAccessDto[]> => request(`/api/v2/moduleAccess/user`)
  }
  public avatar = {
    get: (relativeUrl: string): Promise<Blob> => request(relativeUrl, { responseType: 'blob' })
  }
}
