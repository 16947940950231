import { transform, TransformMethods } from 'data/transform';
import { PicklistItemModel } from 'data/models';
import { WorkawareApi } from 'apis/WorkawareApi';

export interface PickListTypeContainer {
  name: string,
  value?: number,
  key?: string,
  guid?: string
}

export class picklistsService {
  private _api: WorkawareApi;
  private _itemTransform: TransformMethods<PicklistItemModel>;

  constructor(api: WorkawareApi) {
    this._api = api;
    this._itemTransform = transform<PicklistItemModel>(PicklistItemModel);
  }

  getAllTypes = () => new Promise<PickListTypeContainer[]>((resolve, reject) => {
    this._api.picklistTypes.getAll()
      .then(result => {
        const types =
          result
            .map((e) => {
              return ({
                name: e.Value || '-no name set-',
                value: e.Key
              });
            })
            .sort((lhs, rhs) => {
              if (lhs.name === undefined && rhs.name === undefined) return 0;
              if (lhs.name === undefined) return -1;
              if (rhs.name === undefined) return 1;
              return lhs.name.localeCompare(rhs.name)
            });

        resolve(types);
      })
      .catch(error => reject(error));
  });

  getAssetTypes = () => new Promise<{name: string, value: string}[]>((resolve, reject) => {
    this._api.picklistTypes.getAssetTypes()
    .then(result => resolve(result))
    .catch(error => reject(error));
  });

  getPicklistItemsForType = (id: number) => new Promise<PicklistItemModel[]>((resolve, reject) => {
    this._api.picklistItems.getType(id)
      .then(result => resolve(this._itemTransform.arrayFrom(result)))
      .catch(error => reject(error));
  });

  getPicklistItemsForTypeForSelect = (id: number) => new Promise<{ name: string, value?: number}[]>((resolve, reject) => {
    this._api.picklistItems.getType(id)
      .then(result => resolve(result.map((i) => ({
        name: i.Value || `no name set (id: ${i.Id})`,
        value: i.Id
      }))
      ))
      .catch(error => reject(error));
  });

  getAllItems = () => new Promise<PicklistItemModel[]>((resolve, reject) => {
    this._api.picklistItems.all()
      .then(result => resolve(this._itemTransform.arrayFrom(result.value)))
      .catch(error => reject(error));
  });

  getItem = (id: number) => new Promise<PicklistItemModel>((resolve, reject) => {
    this._api.picklistItems.get(id)
      .then(result => resolve(this._itemTransform.from(result)))
      .catch(error => reject(error));
  });

  createItem = (item: PicklistItemModel) => new Promise<PicklistItemModel>((resolve, reject) => {
    //item = objectMod(item, {}, ['Id', 'DateModified']);
    this._api.picklistItems.create(item)
      .then(result => resolve(this._itemTransform.from(result)))
      .catch(error => reject(error));
  });

  getPicklistItemDescription = (item?: PicklistItemModel) => {
    if (item === undefined) {
      return "(empty)";
    }
    if (item.Description !== undefined) {
      return item.Description;
    }
    if (item.Value !== undefined) {
      return item.Value;
    }
    return item.Id;
  };
}