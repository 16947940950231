import { WorkawareApi } from '../../apis/WorkawareApi'
import { EmailTemplateModel } from '../../data/models/EmailTemplateModel'

export class emailTemplatesService {
  private _api: WorkawareApi

  constructor(api: WorkawareApi) {
    this._api = api
  }

  getAllTemplates = () =>
    new Promise<{ value: string; name: string }[]>((resolve, reject) => {
      this._api.emailTemplate
        .getAll()
        .then((result) => resolve(result))
        .catch((error) => reject(error))
    })
  getDefaultTemplate = (emailType: number) =>
    new Promise<EmailTemplateModel[]>((resolve, reject) => {
      this._api.emailTemplate
        .getTemplate(emailType)
        .then((result) => resolve(result))
        .catch((error) => reject(error))
    })
  getTemplateByTypeId = (typeId: number) =>
    new Promise<EmailTemplateModel[]>((resolve, reject) => {
      this._api.emailTemplate
        .getByTypeId(typeId)
        .then((result) => resolve(result))
        .catch((error) => reject(error))
    })
  create = (item: EmailTemplateModel) =>
    new Promise<EmailTemplateModel>((resolve, reject) => {
      this._api.emailTemplate
        .create(item)
        .then((result) => resolve(result))
        .catch((error) => reject(error))
    })
  update = (item: EmailTemplateModel) =>
    new Promise<EmailTemplateModel>((resolve, reject) => {
      this._api.emailTemplate
        .put(item)
        .then((result) => resolve(result))
        .catch((error) => reject(error))
    })
  delete = (id: number) =>
    new Promise<any>((resolve, reject) => {
      this._api.emailTemplate
        .delete(id)
        .then((result) => resolve(result))
        .catch((error) => reject(error))
    })
}
