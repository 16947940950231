import { Grid } from '@material-ui/core'
import Button from 'components/Buttons'
import { WaoCheckbox, WaoFormContainer, WaoSelect } from 'components/Forms'
import { Dictionary } from 'data/transform'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { SecurityModel } from 'data/models/SecurityModel'
import { ModuleNoAccessVisibilityType } from 'enums/ModuleNoAccessVisibilityType'
import { SecurityModules } from 'enums/SecurityModules'

interface Props {
  item: SecurityModel
  handleCreate?: (user: SecurityModel) => void
  handleUpdate?: (id: number, changes: Dictionary<any>, something?: boolean) => void
  handleCancel?: () => void
}
const SecurityModuleForm = ({ item, handleCreate, handleUpdate }: Props) => {
  const [module, setModule] = React.useState<SecurityModel>(Object.assign(new SecurityModel(), item))
  const [modified, setModified] = React.useState<Dictionary<any>>({})
  const editing = Boolean(module.Id)

  const keys = React.useMemo(() => { 
    return Object.keys(SecurityModules)
      .filter((x) => SecurityModules[x] === module.ModuleId)[0]
      ?.replace(/([A-Z])/g, ' $1')
      .trim()
  }, [module])

  const dirty = () => 0 < Object.keys(modified).length

  const handleChange = (name: string) => (value: any) => mutateState(name, value)

  const mutateState = (name: string, value: any) => {
    setModified({ ...modified, [name]: value })
    setModule({ ...module, [name]: value })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    editing ? handleUpdate && module.Id && handleUpdate(module.Id, modified) : handleCreate && handleCreate(module)
  }

  return (
    <WaoFormContainer name="user-edit-form" submit={(e) => handleSubmit(e)}>
      <h4>{keys}</h4>
      <Grid item xs={12} md={10}>
        <WaoSelect
          fullWidth
          name="ModuleNoAccessVisibilityType"
          label="Module Access Visibility"
          value={module.ModuleNoAccessVisibilityType}
          items={Object.keys(ModuleNoAccessVisibilityType).map((key) => ({ value: ModuleNoAccessVisibilityType[key], name: key }))}
          handleChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} md={10}>
        <WaoCheckbox name="CanUserAccess" value={module.CanUserAccess} handleChange={handleChange} label="Can User Access" />
      </Grid>
      <Grid item xs={12} md={10}>
        <WaoCheckbox name="CanManagerAccess" value={module.CanManagerAccess} handleChange={handleChange} label="Can Manager Access" />
      </Grid>
      <Grid item xs={12} md={10}>
        <WaoCheckbox name="CanSeniorManagerAccess" value={module.CanSeniorManagerAccess} handleChange={handleChange} label="Can Senior Manager Access" />
      </Grid>
      <Grid item xs={12} md={10}>
        <WaoCheckbox name="CanExternalAuditorAccess" value={module.CanExternalAuditorAccess} handleChange={handleChange} label="Can External Auditor Access" />
      </Grid>
      <Button.SubmitButton disabled={!dirty()} label={editing ? <FormattedMessage id="update" defaultMessage="Update" /> : <FormattedMessage id="create" defaultMessage="Create" />} />
    </WaoFormContainer>
  )
}

export default SecurityModuleForm
