import React from 'react'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles((theme) => ({
  btn: (props: any) => ({
    borderRadius: '50%',
    padding: '0px',
    minWidth: 'auto',
    margin: '0px 5px',
    marginLeft: `${props.mobileScreen ? 10 : 5}px`,
    '&:hover': {
      background: 'none',
    },
  }),
}))

interface Props {
  content: React.ReactNode
}
const MenuToggleButton: React.FC<Props> = ({ content, children }) => {
  const [anchor, setAnchor] = React.useState<HTMLButtonElement | undefined>(undefined)
  const mobileScreen = useMediaQuery('(max-width:600px)')
  const classes = useStyles({ mobileScreen })

  return (
    <div>
      <Button size="small" className={classes.btn} onClick={(event) => setAnchor(event.currentTarget)}>
        {content}
      </Button>
      <Menu elevation={2} anchorEl={anchor} keepMounted open={Boolean(anchor)} onClose={() => setAnchor(undefined)}>
        {children}
      </Menu>
    </div>
  )
}

export default MenuToggleButton
