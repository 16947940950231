import React from 'react';
import ListItemBase from 'components/list-items/ListItemBase';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { RasterModel } from 'data/models/RasterModel';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';

export interface RasterListItemProps {
  raster: RasterModel
  selected?: boolean
  multiSelect?: boolean
  handleSelect?: (Raster: RasterModel) => void,
  showDetail?:boolean,

}
const RasterlistListItem = ({ raster, selected, handleSelect, multiSelect = false }:RasterListItemProps) => (
  <ListItemBase multiSelect={multiSelect} handleClick={() => handleSelect && handleSelect(raster)} selected={selected}>

  <ListItem onClick={e => { e.preventDefault(); handleSelect&& handleSelect(raster); }}>

    <ListItemAvatar>
          <DynamicFeedIcon color={raster.IsActive ? 'secondary' : 'disabled'} />
        </ListItemAvatar>
      <ListItemText primary={raster.Name} secondary={raster && raster.Description}/>
    </ListItem>
  </ListItemBase>
);

export default RasterlistListItem;
