import { WorkawareBaseClass } from './WorkawareBaseClass'

export class UserModel extends WorkawareBaseClass {
  _className = 'UserModel'

  public Address: string | undefined = undefined
  public CanSeeEveryonesData: boolean = false
  public CanUserSeeAllForms: boolean = false
  public City: string | undefined = undefined
  public ClientId: number | undefined = undefined
  public Comment: string | undefined = undefined
  public CompanyId: number | undefined = undefined
  public CompanyMessageLastRead: Date | undefined = undefined
  public CompanyName: string | undefined = undefined
  public Country: string | undefined = undefined
  public CreateDate: Date | undefined = new Date()
  public CustomerUnion: string | undefined = undefined
  public DOB: Date | undefined = undefined
  public DefaultLanguage: string | undefined = undefined
  public DefaultLanguageCode: string | undefined = undefined
  public DepartmentId: number | undefined = undefined
  public DivisionId: number | undefined = undefined
  public DriverLicenseExpiryDate: Date | undefined = undefined
  public DriverLicenseIssueCountry: string | undefined = undefined
  public DriverLicenseIssueProvinceState: string | undefined = undefined
  public DriverLicenseLicenseClasses: number | undefined = undefined
  public DriverLicenseNotes: string | undefined = undefined
  public DriverLicenseNumber: string | undefined = undefined
  public DriverLicenseScanURL: string | undefined = undefined
  public DriverLicenseType: string | undefined = undefined
  public EmergencyContactCellPhone: string | undefined = undefined
  public EmergencyContactEmail: string | undefined = undefined
  public EmergencyContactHomePhone: string | undefined = undefined
  public EmergencyContactName: string | undefined = undefined
  public EmergencyContactRelationship: string | undefined = undefined
  public EmergencyContactWorkPhone: string | undefined = undefined
  public EmployeeId: number | undefined = undefined
  public EmploymentInfoDayRateField: number | undefined = undefined
  public EmploymentInfoDayRateOffice: number | undefined = undefined
  public EmploymentInfoHoursPerWeek: number | undefined = undefined
  public EmploymentInfoPayment: number | undefined = undefined
  public EmploymentInfoSalary: number | undefined = undefined
  public EmploymentInfoShift: string | undefined = undefined
  public EmploymentInfoSpecific: string | undefined = undefined
  public EmploymentInfoType: string | undefined = undefined
  public EmploymentStatus: string | undefined = undefined
  public FailedPasswordAnswerAttemptCount: number = 0
  public FailedPasswordAnswerAttemptWindowsStart: string | undefined = undefined
  public FailedPasswordAttemptCount: number = 0
  public FailedPasswordAttemptWindowStart: string | undefined = undefined
  public FieldForEmailAddressId: number = 1 //username
  public FieldForSMSId: number = 0
  public Firstname: string | undefined = undefined
  public FormAccessId: number = 0
  public Gender: string | undefined = undefined
  public Id: number | undefined = undefined
  public IncludeInCertificateVerification: boolean = false
  public InitialHireDate: Date | undefined = undefined
  public IsActive: boolean = true
  public IsApproved: boolean = false
  public IsLockedOut: boolean = false
  public IsSafetyAdmin: boolean = false
  public IsTracked: boolean = false
  public JobClassificationId: number | undefined = undefined
  public Language: string | undefined = undefined
  public LastLockoutDate: Date | undefined = undefined
  public LastLoginDate: Date | undefined = undefined
  public LastPasswordChangedDate: Date | undefined = undefined
  public Lastname: string | undefined = undefined
  public LicenseNumber: string | undefined = undefined
  public LicenseScanURL: string | undefined = undefined
  public LicenseType: string | undefined = undefined
  public LocationId: number | undefined = undefined
  public Middlename: string | undefined = undefined
  public PHNNumber: string | undefined = undefined
  public PHNProvState: string | undefined = undefined
  public PHNProvStateOther: string | undefined = undefined
  public PasswordAnswer: string | undefined = undefined
  public PasswordQuestion: string | undefined = undefined
  public PersonalCellPhone: string | undefined = undefined
  public PersonalEmail: string | undefined = undefined
  public PersonalHomePhone: string | undefined = undefined
  public PhotoURL: string | undefined = undefined
  public PostalCode: string | undefined = undefined
  public PreferredName: string | undefined = undefined
  public Province: string | undefined = undefined
  public RegistrationKey: string | undefined = undefined
  public SIN: string | undefined = undefined
  public Salutation: string | undefined = undefined
  public SecurityLevel: number = 0
  public SendCertificateWarnings: boolean = false
  public SignatureURL: string | undefined = undefined
  public SignoffOnlyAccount: string | undefined = undefined
  public SubContractorId: number | undefined = undefined
  public Title: string | undefined = undefined
  public TrackerId: string | undefined = undefined
  public Username: string | undefined = undefined
  public WorkCellPhone: string | undefined = undefined
  public WorkEmail: string | undefined = undefined
  public WorkPhone: string | undefined = undefined

  getBestName = () => {
    if (this.Lastname && this.Firstname && this.PreferredName) {
      return `${this.Firstname} (${this.PreferredName}) ${this.Lastname}`
    }
    if (this.Firstname && this.Lastname) {
      return `${this.Firstname} ${this.Lastname}`
    }
    if (this.PreferredName && this.Lastname) {
      return `${this.PreferredName} ${this.Lastname}`
    }
    if (this.Firstname && this.PreferredName) {
      return `${this.Firstname} (${this.PreferredName})`
    }
    if (this.Firstname) {
      return this.Firstname
    }
    if (this.PreferredName) {
      return this.PreferredName
    }
    return this.Username
  }
}
