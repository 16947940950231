import { CachedListContainer } from "data/cache";
import { Dictionary } from 'types';

export const SET_CACHED_LIST = 'workaware/cachedLists/SET_CACHED_LIST';
export const REMOVE_CACHED_LIST = 'workaware/cachedLists/REMOVE_CACHED_LIST';

export const setCachedList = (name: string, list: CachedListContainer) => ({ type: SET_CACHED_LIST, payload: { name, list } });
export const removeCachedList = (name: string) => ({ type: REMOVE_CACHED_LIST, payload: name });


interface Action {
  type: string,
  payload?: any
}

const initialState: Dictionary<CachedListContainer> = {};

const cachedListReducer = (state: Dictionary<CachedListContainer> = initialState, action: Action) => {
  switch (action.type) {
    case REMOVE_CACHED_LIST:
      const { [action.payload as string]: value, ...newState } = state;
      return Object.assign({}, newState);
    case SET_CACHED_LIST:
      const payload: { name: string, list: CachedListContainer } = action.payload;
      return Object.assign({}, state, { [payload.name]: payload.list });
    default:
      return state;
  }
};

export default cachedListReducer;
