import uuid from 'uuid/v1'
import { WorkawareBaseClass } from './WorkawareBaseClass'
import { ExternalEventModel } from './ExternalEventModel'

/**
 * Details about an assignment that can be changed
 */
export class AssignmentCreateModel extends WorkawareBaseClass {
  _className = 'AssignmentCreateModel'

  /**
   * Unique Id to store with this assignment
   */
  public UniqueID: string = uuid()
  /**
   * User id to assign
   */
  public AssignedToUserId: number | undefined = undefined
  /**
   * Team to assign
   */
  public AssignedToTeamId: number | undefined = undefined
  /**
   * If assigned to a team indicate how the assignment applies to the team members
   */
  public AssignedToTeamType: string | undefined = undefined
  /**
   * Assignment description
   */
  public Description: string = ''
  /**
   * Due date of the assignment
   */
  public DueDate: Date | undefined = undefined
  /**
   * Actual completion of the assignment
   */
  public CompletedOn: Date | undefined = undefined
  /**
   * Id of user that completed the assignment
   */
  public CompletedByUserId: number | undefined = undefined
  /**
   * Id of the completed form to associate with this assignment
   */
  public FormID: number | undefined = undefined
  /**
   * Id of the blank template to associate with this assignment
   */
  public CompanyFormId: number | undefined = undefined
  /**
   * Details about the external event to associate with this assignment
   */
  public EventDetail: ExternalEventModel | undefined = undefined
}
export class AssignmentUpdateModel extends AssignmentCreateModel {
  /**
   * Server-generated Id of the assignment
   */
  public Id: number = 0
}
/**
 * Additional details about an an assignment that are populated at the server and
 * will be ignored if submitted
 */
export class AssignmentDetailModel extends AssignmentUpdateModel {
  /**
   * Server-generated summary of the assignment (describing the assigned item(s))
   */
  public Summary: string = ''
  /**
   * Human readable name of user responsible for the assignment (if any)
   */
  public AssignedToUser: string | undefined = undefined
  /**
   * Human readable name of team responsible for the assignment (if any)
   */
  public AssignedToTeam: string = ''
  /**
   * Human readable name of user who created the assignment
   */
  public AssignedByUser: string = ''
  /**
   * Entity id of the user who created the assignment
   */
  public AssignedByUserId: number = 0
  /**
   * Human readable name of user who completed the assignment (if any)
   */
  public CompletedByUser: string | undefined = undefined
  /**
   * Date the assignment was generated
   */
  public AssignedOn: Date = new Date()
  /**
   * Last time a reminder was sent about this assignment
   */
  public EmailedOn: Date | undefined = undefined
  /**
   * Any sub-assignments related to this one
   */
  public Children: AssignmentDetailModel[] = []
}
