import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: '10px'
  },
}))

const ContentWrapper: React.FC = ({ children }) => {
  const classes = useStyles()

  return <div className={classes.wrapper}>{children}</div>
}

export default ContentWrapper
