import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Menu, { MenuProps } from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ReactCountryFlag from 'react-country-flag'
import { locales } from 'etc/i18nConfig'
import { useDispatch, useSelector } from 'react-redux'
import { IRootReduxState, IUserState } from 'types/reducers'
import { setLocale } from 'reducers/userReducer'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    textTransform: 'none',
    padding: '6px',
    marginRight: '2px',
  },
  startIcon: {
    marginLeft: '0px',
  },
  endIcon: {
    marginLeft: '0px',
  },
}))

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
))

const CustomizedMenus:  React.FC = () => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const userInfo = useSelector<IRootReduxState, IUserState>((state) => state.app.user)
  const dispatch = useDispatch()
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <Button
        classes={{
          root: classes.root,
          startIcon: classes.startIcon,
          endIcon: classes.endIcon,
        }}
        aria-controls="locale-menu"
        aria-haspopup="true"
        onClick={handleClick}
        startIcon={<ReactCountryFlag svg countryCode={locales.find((i) => i.code === userInfo.localeCode)?.flagCode} />}
        endIcon={<ArrowDropDownIcon />}
      >
        {locales.find((i) => i.code === userInfo.localeCode)?.name}
      </Button>
      <StyledMenu id="locale-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {locales.map((l) => (
          <MenuItem key={l.code} value={l.code} onClick={(e) => dispatch(setLocale(l.code))}>
            <ReactCountryFlag svg countryCode={l.flagCode} style={{ marginRight: 10 }} />
            {l.name}
          </MenuItem>
        ))}
      </StyledMenu>
    </div>
  )
}

export default CustomizedMenus