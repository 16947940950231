import { WorkawareBaseClass } from './WorkawareBaseClass'

export class PicklistItemModel extends WorkawareBaseClass {
  _className = 'PicklistItemModel'

  public Id: number | undefined = undefined
  public Color: string | undefined = undefined
  public CompanyId: number | undefined = undefined
  public DateModified: Date | undefined = undefined
  public Description: string | undefined = ''
  public IconURL: string | undefined = undefined
  public MaximumZoomVisible: number | undefined = undefined
  public MinimumZoomVisible: number | undefined = undefined
  public SortOrder: number | undefined = undefined
  public Type: number | undefined = undefined
  public Value: string | undefined = ''
  public IsActive:boolean | undefined=undefined

  public getValue = () => {
    if (this.Value) {
      return this.Value
    }
    if (this.Description) {
      return this.Description
    }
    return `${this.Id}`
  }

  public getDescription = () => {
    if (this.Description) {
      return this.Description
    }
    if (this.Value) {
      return this.Value
    }
    return `${this.Id}`
  }
}

export const convertFromEnumModel = (enumType: { value: number; name: string }) => {
  let pl = new PicklistItemModel()
  pl.Id = enumType.value
  pl.Value = enumType.name
  return pl
}
