import React from 'react'
import Buttons from './Buttons'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import AppsSharpIcon from '@material-ui/icons/AppsSharp'
import { Grid, Popover } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  btn: {
    textTransform: 'none',
  },
}))

const ActionsToggleButton: React.FC = ({ children }) => {
  const smallScreen = useMediaQuery('(max-width:1000px)')
  const [anchor, setAnchor] = React.useState<Element | undefined>(undefined)
  const [over, setOver] = React.useState(false)
  const classes = useStyles()

  return smallScreen ? (
    <div>
      <Buttons.Button disableRipple={true} minimal={smallScreen} label="Actions" size="medium" variant="contained" icon={<AppsSharpIcon />} className={classes.btn} click={(event) => setAnchor(event.currentTarget)}></Buttons.Button>
      <Popover
        anchorEl={anchor}
        PaperProps={{
          onClick: () => setAnchor(undefined),
          onMouseOver: () => setOver(true),
          onMouseLeave: () => setOver(false),
          style: { padding: '1em', minWidth: '200px', minHeight: '220px' },
        }}
        BackdropProps={{ onClick: () => setAnchor(undefined), style: { opacity: 0.1 } }}
        keepMounted
        elevation={over ? 3 : 2}
        open={!!anchor}
      >
        <Grid container direction="column" spacing={3}>
          {children instanceof Array
            ? (children as Array<Element>).map((child, key) => {
                return (
                  <Grid key={key} item>
                    {child}
                  </Grid>
                )
              })
            : children}
        </Grid>
      </Popover>
    </div>
  ) : (
    <Grid container direction="row" spacing={3}>
      {children instanceof Array
        ? (children as Array<Element>).map((child, key) => {
            return (
              <Grid key={key} item>
                {child}
              </Grid>
            )
          })
        : children}
    </Grid>
  )
}

export default ActionsToggleButton
