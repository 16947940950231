import { Grid, TablePagination } from '@material-ui/core'
import Button from 'components/Buttons'
import ContentWrapper from 'components/ContentWrapper'
import { WaoCheckbox, WaoMenu, WaoText } from 'components/Forms'
import UserGeneratedShapesList from 'components/lists/UserGeneratedShapesList'
import TopNavigation from 'components/navigation/TopNavigation'
import PaginationActions from 'components/tables/paginationActions'
import GenericDrawer from 'containers/drawers/GenericDrawer'
import { UserGeneratedShapeModel } from 'data/models/UserGeneratedShapeModel'
import { useUserGeneratedShapesService } from 'data/services'
import { uiSettings } from 'etc/settings'
import { createNotification } from 'factories/notificationFactory'
import React from 'react'
import { Helmet } from 'react-helmet'
import { FormattedMessage } from 'react-intl'
import { Dictionary } from 'types'

const UserGeneratedShapesPage: React.FC = () => {
  const [userGeneratedShapes, setUserGeneratedShapes] = React.useState<UserGeneratedShapeModel[]>([])
  const [userGeneratedShape, setUserGeneratedShape] = React.useState<UserGeneratedShapeModel | undefined>(undefined)
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(uiSettings.pagination.defaultRows)
  const [page, setPage] = React.useState<number>(0)
  const [pageOfUserGeneratedShapes, setPageOfUserGeneratedShapes] = React.useState<UserGeneratedShapeModel[]>([])

  const userGeneratedShapesService = useUserGeneratedShapesService()

  const handleUpdate = async (id: string, data: Dictionary<any>) => {
    if (id && data) {
      await userGeneratedShapesService.updateShape(id, data as UserGeneratedShapeModel)
      createNotification.success('Shape Updated Successfully')
      setUserGeneratedShape(undefined)
      getData()
    }
  }

  const handleDelete = async (id: string) => {
    if (id) {
      await userGeneratedShapesService.deleteShape(id)
      createNotification.success('shape item deleted')
      setUserGeneratedShape(undefined)
      getData()
    }
  }

  const setPagedData = () => {
    const start = page * rowsPerPage
    const end = Math.min(start + (rowsPerPage - 1), userGeneratedShapes.length)
    setPageOfUserGeneratedShapes(userGeneratedShapes.slice(start, end))
  }

  const getData = async () => {
    userGeneratedShapesService.getShapes().then((results) => {
      setUserGeneratedShapes(results)
    })
  }

  React.useEffect(() => {
    setPagedData()
    // eslint-disable-next-line
  }, [page, rowsPerPage, userGeneratedShapes])

  React.useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Helmet>
        <title>Settings / User Generated Shapes</title>
        <meta name="user shapes" content="user generated shapes" />
      </Helmet>

      <TopNavigation />

      <ContentWrapper>
        <UserGeneratedShapesList shapes={pageOfUserGeneratedShapes} handleSelect={(shape) => setUserGeneratedShape({ ...shape })} />
        <TablePagination
          rowsPerPageOptions={uiSettings.pagination.rowsOptions}
          count={userGeneratedShapes?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          component="div"
          onChangePage={(e, page) => setPage(page)}
          onChangeRowsPerPage={(e) => {
            setRowsPerPage(+e.target.value)
            setPage(0)
          }}
          ActionsComponent={PaginationActions}
        />
      </ContentWrapper>

      {userGeneratedShape && (
        <GenericDrawer open={userGeneratedShape !== undefined} title="Shape Properties" handleClose={() => setUserGeneratedShape(undefined)}>
          <>
            <WaoMenu
              items={[
                {
                  name: 'delete',
                  control: <Button.DeleteButton fullWidth label={<FormattedMessage id="delete" defaultMessage="Delete" />} click={() => handleDelete && handleDelete(userGeneratedShape.id)} />,
                },
              ]}
            />
            <Grid container direction="column" spacing={3}>
              <Grid item>
                <WaoText name="name" value={userGeneratedShape.Name} handleChange={(n) => (v) => setUserGeneratedShape({ ...userGeneratedShape, Name: v })} label="Name" />
              </Grid>
              <Grid item>
                <WaoCheckbox name="isDraft" value={userGeneratedShape.IsDraft} handleChange={(n) => (v) => setUserGeneratedShape({ ...userGeneratedShape, IsDraft: v })} label="This is a draft" />
              </Grid>
              <Grid item>Name: {userGeneratedShape.Name}</Grid>
              <Grid item>
                Area: {userGeneratedShape.AreaSquareMeters ?? 0} m<sup>2</sup>
              </Grid>
              <Grid item>Length: {userGeneratedShape.LengthMeters ?? 0} m</Grid>
              <Grid item>Type: {userGeneratedShape.Type}</Grid>
              <Grid item>Created By: {userGeneratedShape.CreatedByUserId}</Grid>
              <Grid item>
                <Button.SaveButton click={() => handleUpdate(userGeneratedShape.id, userGeneratedShape)} label={<FormattedMessage id="update" defaultMessage="Update" />} />
              </Grid>
            </Grid>
          </>
        </GenericDrawer>
      )}
    </>
  )
}

export default UserGeneratedShapesPage
