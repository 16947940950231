export interface Dictionary<T> {
  [Key: string]: T;
}

export interface TransformMethods<T> {
  from: (data: object) => T,
  arrayFrom: (data: object[]) => T[],
  mapFrom: (data: object[], mapOnProperty: string) => Dictionary<T>
}

export const transform = <T>(modelCreator: { new(...args: any[]): T }): TransformMethods<T> => {

  const _findEntryInObject = (obj: object, search: string): any => {
    let location = obj;
    const parts = search.split('.');
    for (let i in parts) {
      const result = _search(location, parts[i]);
      if (!result) return null;
      location = result;
    }
    return location;
  };

  const _search = (obj: object, name: string): any => {
    name = name.toLowerCase();
    const keys = Object.keys(obj);
    for (let j: number = 0; j < keys.length; j++) {
      const key = keys[j].toLowerCase();
      if (key === name) {
        return obj[keys[j]];
      }
    }
    return null;
  };

  const _transform = (model: T, data: object): T => {
    //console.log(model);
    if (!data) return model;
    Object.getOwnPropertyNames(model).forEach(key => {
      //console.log(key);
      model[key] = _findEntryInObject(data, key) || model[key];
    });
    return model;
  };

  const _fromModel = (data: object): T => {
    return _transform(new modelCreator(), data);
  };

  return ({
    from: _fromModel,
    arrayFrom: (data: object[]): T[] => {
      const arr: T[] = [];
      data.forEach(value => {
        arr.push(_fromModel(value));
      });
      return arr;
    },
    mapFrom: (data: object[], mapProperty: string = 'Id'): Dictionary<T> => {
      const object = {};
      data.forEach(value => object[value[mapProperty]] = _fromModel(value));
      return object;
    },
  });
};