import { WorkawareApi } from 'apis/WorkawareApi';
import { TeamAttributeModel, TeamMemberModel, TeamModel, UserModel } from 'data/models';
import { transform, TransformMethods } from 'data/transform';
import { listToTree, Tree } from 'utils/Tree';

export class teamsService {
  private _api: WorkawareApi;
  private _userTransform: TransformMethods<UserModel>;
  private _teamTransform: TransformMethods<TeamModel>;
  private _memberTransform: TransformMethods<TeamMemberModel>;
  private _attributeTransform: TransformMethods<TeamAttributeModel>;

  constructor(api: WorkawareApi) {
    this._api = api;
    this._userTransform = transform<UserModel>(UserModel);
    this._teamTransform = transform<TeamModel>(TeamModel);
    this._memberTransform = transform<TeamMemberModel>(TeamMemberModel);
    this._attributeTransform = transform<TeamAttributeModel>(TeamAttributeModel);
  }

  getAllTeams = () => new Promise<TeamModel[]>((resolve, reject) => {
    this._api.teams.all()
      .then(result => resolve(this._teamTransform.arrayFrom(result)))
      .catch(error => reject(error));
  });

  myTeams = () => new Promise<TeamModel[]>((resolve, reject) => {
    this._api.teams.mine()
    .then(result => resolve(result))
    .catch(error => reject(error));
  });

  createTeam = (team?: TeamModel) => new Promise<TeamModel>((resolve, reject) => {
    if (team === undefined) {
      return reject('team is undefined');
    }
    delete team.children;
    
    this._api.teams.post(team)
      .then(result => resolve(this._teamTransform.from(result)))
      .catch(error => reject(error));
  });

  updateTeam = (id?: number, modifications?: any) => new Promise<TeamModel>((resolve, reject) => {
    if (id === undefined) {
      return reject('id is undefined');
    }
    if (modifications === undefined) {
      return reject('modifications is undefined');
    }
    this._api.teams.patch(id, modifications)
      .then(result => resolve(this._teamTransform.from(result)))
      .catch(error => reject(error));
  });

  deleteTeam = (id?: number) => new Promise<void>((resolve, reject) => {
    if (id === undefined) {
      return reject('id is undefined');
    }
    this._api.teams.delete(id)
      .then(() => resolve())
      .catch(error => reject(error));
  });

  addMember = (user?: UserModel, team?: TeamModel) => new Promise<TeamMemberModel>((resolve, reject) => {
    if (user === undefined) {
      return reject('user is undefined');
    }
    if (team === undefined) {
      return reject('team is undefined');
    }
    const member = new TeamMemberModel();
    member.OrganizationTeamHierarchyId = team.Id;
    member.CompanyId = team.CompanyId;
    member.UserId = user.Id;
    this._api.teams.members.post(member)
      .then(result => resolve(this._memberTransform.from(result)))
      .catch(error => reject(error));
  });

  deleteMember = (id?: number) => new Promise<void>((resolve, reject) => {
    if (id === undefined) {
      return reject('id is undefined');
    }
    this._api.teams.members.delete(id)
      .then(() => resolve())
      .catch(error => reject(error));
  });

  getAttributes = (id?: number) => new Promise<TeamAttributeModel[]>((resolve, reject) => {
    if (id === undefined) {
      return reject('id is undefined');
    }
    this._api.teams.attributes.all(id)
      .then(result => resolve(this._attributeTransform.arrayFrom(result.value)))
      .catch(error => reject(error));
  });

  getInheritedAttributes = (id?: number) => new Promise<TeamAttributeModel[]>((resolve, reject) => {
    if (id === undefined) {
      return reject('id is undefined');
    }
    Promise.all([
      this._api.teams.attributes.all(id),
      this._api.teams.attributes.getCalculated(id)
    ])
      .then(result => {
        const attributes = this._attributeTransform.arrayFrom(result[0].value);
        const calculated = this._attributeTransform.arrayFrom(result[1].value);
        const attributeIds = attributes.map(i => i.PicklistItemId);
        const inherited = calculated.filter(i => !attributeIds.includes(i.Id));
        resolve(inherited);
      })
      .catch(error => reject(error));
  });

  createAttribute = (attribute?: TeamAttributeModel) => new Promise<TeamAttributeModel>((resolve, reject) => {
    if (attribute === undefined) {
      return reject('attribute is undefined');
    }
    this._api.teams.attributes.create(attribute)
      .then(result => resolve(this._attributeTransform.from(result)))
      .catch(error => reject(error));
  });

  updateAttribute = (attribute?: TeamAttributeModel) => new Promise<TeamAttributeModel>((resolve, reject) => {
    if (attribute === undefined) {
      return reject('attribute is undefined');
    }
    if (attribute.Id !== undefined) {
      this._api.teams.attributes.update(attribute.Id, attribute)
        .then(result => resolve(this._attributeTransform.from(result)))
        .catch(error => reject(error));
    } else {
      reject('id is not defined on the model');
    }
  });

  removeAttribute = (id?: number) => new Promise<void>((resolve, reject) => {
    if (id === undefined) {
      return reject('id is undefined');
    }
    this._api.teams.attributes.remove(id)
      .then(() => resolve())
      .catch(error => reject(error));
  });

  buildTeamsTree = () => new Promise<Tree>((resolve, reject) => {
    Promise.all([
      this._api.teams.all(),
      this._api.user.all(),
      this._api.teams.members.all()
    ]).then(result => {
      const teams = this._teamTransform.arrayFrom(result[0]);
      const users = this._userTransform.arrayFrom(result[1].value);
      const members = this._memberTransform.arrayFrom(result[2].value);
      const tree = listToTree(teams, 'Id', 'ParentOrganizationTeamHierarchyId');
      tree.map(n => (n as TeamModel).members = []);
      members.forEach(member => {
        member.user = users.find(u => u.Id === member.UserId);
        tree.findOne(n => n.Id === member.OrganizationTeamHierarchyId, n => {
          (n as TeamModel).members?.push(member);
        })
      })
      resolve(tree);
    });
  });
}