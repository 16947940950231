import { IReportingState, IReducerAction, IParticipationDashboardState } from "types/reducers";
import { ILiveReport, ILiveFilter, ILiveReportingState } from 'components/liveReporting/types';
import { ParticipationSortType } from "components/liveReporting/enums";
import { ParticipationFilter } from "components/liveReporting/models/ParticipationFilter";

export const PARTICIPATION_DASHBOARD_FILTER_SET = 'workaware/reporting/PARTICIPATION_BOARD_FILTER_SET';
export const PARTICIPATION_DASHBOARD_SORT_SET = 'workaware/reporting/PARTICIPATION_BOARD_SORT_SET';
export const LIVE_REPORT_SET = 'workaware/reporting/LIVE_REPORT_SET';
export const LIVE_FILTERS_SET = 'workaware/reporting/LIVE_FILTERS_SET';

export const setLiveReport = (report: ILiveReport): IReducerAction => ({ type: LIVE_REPORT_SET, payload: report });
export const setLiveFilters = (filters: ILiveFilter[]): IReducerAction => ({ type: LIVE_FILTERS_SET, payload: filters });
export const setParticipationDashboardFilter = (filter: ParticipationFilter): IReducerAction => ({ type: PARTICIPATION_DASHBOARD_FILTER_SET, payload: filter });
export const setParticipationDashboardSort = (sortType: number): IReducerAction => ({ type: PARTICIPATION_DASHBOARD_SORT_SET, payload: sortType });

//const key = 'workaware-reporting';

const initialState: IReportingState = {
  live: {
    report: {
      _id: '',
      title: '',
      companyId: 0,
      content: {},
    },
    filters: []
  },
  participationDashboard: {
    filter: {},
    sortType: ParticipationSortType.total,
  }
};

const getInitialState = (): IReportingState => {
  //const storedObj = JSON.parse(localStorage.getItem(key) || '{}') as IReportingState;
  //return { ...initialState, ...storedObj };
  return { ...initialState };
};

function init(state: IReportingState) {
  return {
    updateLiveReport: function (newState: Partial<ILiveReportingState>): IReportingState {
      return this._save({
        ...state,
        live: {
          ...state.live,
          ...newState
        }
      })
    },
    updateParticipationDashboard: function (newState: Partial<IParticipationDashboardState>): IReportingState {
      return this._save({
        ...state,
        participationDashboard: {
          ...state.participationDashboard,
          ...newState
        }
      })
    },
    _save: (data: any) => {
      const obj = { ...state, ...data };
      //const { report, ...filters } = obj; // pull out report
      //localStorage.setItem(key, JSON.stringify(obj));
      return obj;
    }
  };
};


const reportingReducer = (state: IReportingState = getInitialState(), action: IReducerAction): IReportingState => {
  const storage = init(state);
  switch (action.type) {
    case LIVE_REPORT_SET:
      return storage.updateLiveReport({ report: action.payload });
    case LIVE_FILTERS_SET:
      return storage.updateLiveReport({ filters: action.payload });
    case PARTICIPATION_DASHBOARD_SORT_SET:
      return storage.updateParticipationDashboard({ sortType: action.payload });
    case PARTICIPATION_DASHBOARD_FILTER_SET:
      return storage.updateParticipationDashboard({ filter: action.payload });
    default:
      return state;
  }
};

export default reportingReducer;
