import { WorkawareBaseClass } from './WorkawareBaseClass'

export class AnonymousFormModel extends WorkawareBaseClass {
  _className = 'AnonymousFormModel'
  public Id: string | undefined = undefined
  public Name: string = ''
  public Created?: Date = undefined
  public IsActive: boolean = true
  public BackgroundColour: string = ''
  public ForegroundColour: string = ''
  public LogoUrl: string = ''
  public ShowResponseCode?: boolean
  public SendVerificationEmails?: boolean
  public EmailVerificationField: string = ''
  public CompanyFormName: string = ''
  public CompanyFormDescription: string = ''
  public CompanyFormIsActive?: boolean

  public CompanyFormId?: number = undefined // used when creating a new form
}
