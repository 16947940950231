/* eslint-disable import/first */
import React, { lazy } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import { FormattedMessage } from 'react-intl'
import { Switch, Route } from 'react-router-dom'
import { WorkawareApi } from 'apis/WorkawareApi'
import Navigation from 'components/navigation'
import { userLoaded, userUnloaded, userModuleAccessLoaded, userModuleAccessCleared } from 'reducers/userReducer'
import { setPageTitle } from 'reducers/stateReducer'

/* font-awesome library */
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFile, faFileAlt, faFilePdf, faFileInvoice, faFileCode, faBook, faFileSignature, faGlobeAmericas, faFileWord } from '@fortawesome/free-solid-svg-icons'
import { parseUrl } from 'utils/utilities'
import ErrorBoundary from 'components/ErrorBoundary'
import { IRootReduxState } from 'types/reducers'
import SecurityPage from './pages/settings/SecurityPage'
import RasterPage from './pages/settings/RasterPage'
import UserGeneratedShapesPage from './pages/settings/UserGeneratedShapesPage'
import Forbidden from './Forbidden'
import ProtectedRoute from 'components/ProtectedRoute'
import { Module } from 'enums/Module'
import { ModuleAccessProvider } from 'utils/moduleAccessProvider'

library.add(faFile, faFileAlt, faFilePdf, faFileWord, faFileInvoice, faFileSignature, faFileCode, faBook, faGlobeAmericas)

/* on-demand loading */
const LiveReporting = lazy(() => import('containers/pages/LiveReporting'))
const Home = lazy(() => import('containers/pages/Home'))
const Library = lazy(() => import('containers/pages/Library'))
const AssignmentsByYou = lazy(() => import('containers/pages/assignments/AssignmentsByYou'))
const AssignmentsToYou = lazy(() => import('containers/pages/assignments/AssignmentsToYou'))
const Organization = lazy(() => import('containers/pages/Organization'))
const Callback = lazy(() => import('containers/pages/Callback'))
const CompletedForms = lazy(() => import('containers/pages/CompletedForms'))
const CompanySettingsPage = lazy(() => import('containers/pages/settings/CompanySettingsPage'))
const UserProfilePage = lazy(() => import('containers/pages/settings/UserProfilePage'))
const UserListPage = lazy(() => import('containers/pages/settings/UserListPage'))
const EmailTemplatesPage = lazy(() => import('containers/pages/settings/EmailTemplatesPage'))
const PicklistSettingsPage = lazy(() => import('containers/pages/settings/PicklistSettingsPage'))
const FormsSettingsPage = lazy(() => import('containers/pages/FormTemplatesPage'))
const AnonymousFormsPage = lazy(() => import('containers/pages/settings/AnonymousFormsPage'))
const CultureGroups = lazy(() => import('containers/pages/CultureGroups'))
const DynamicFormViewer = lazy(() => import('containers/pages/DynamicFormViewer'))
const ParticipationDashboard = lazy(() => import(`containers/pages/ParticipationDashboard`))
const LockScreen = lazy(() => import(`containers/pages/LockScreen`))
const AssetMaintenanceDashboard = lazy(() => import('containers/pages/AssetMaintenanceDashboard'))
const Calendar = lazy(() => import(`containers/pages/Calendar`))
const JourneyManagementDashboard = lazy(() => import('containers/pages/JourneyManagement'))
const LocationTrackingDashboard = lazy(() => import('containers/pages/LocationTracking'))
const SitesLocations = lazy(() => import(`containers/pages/assets/SitesLocations`))
const Picklists = lazy(() => import(`containers/pages/assets/Picklists`))
const MaintenanceSchedules = lazy(() => import(`containers/pages/assets/MaintenanceSchedules`))
const AssetsManagement = lazy(() => import(`containers/pages/assets/AssetsManagement`))

import('../../package.json').then((packages) => {
  var params = new URLSearchParams(window.location.search)
  if (params.has('package-version')) {
    const p = params.get('package-version')
    console.log(`${p}: ${packages.dependencies[params.get('package-version') as string]}`)
  }
})

const NoMatch: React.FC = (props) => {
  const m = parseUrl(window.location.href)
  const { error } = m?.parameters as any
  console.log(error)
  return (
    <section className="hero">
      <div className="hero-body">
        <div className="container">
          <h1 className="title">Unknown Route =(</h1>
          <h2 className="subtitle">We could not find a match to the provided route</h2>
          <ErrorBoundary>{error && <pre>{JSON.stringify(JSON.parse(decodeURIComponent(error)), null, 2)}</pre>}</ErrorBoundary>
        </div>
      </div>
    </section>
  )
}

const getAnonymousRoutes = () => (
  <Switch>
    <Route exact path="/bye" render={() => <h5>Have a nice day =)</h5>} />
    <Route exact path="/callback" render={() => <Callback />} />
    <Route render={() => <LockScreen />} />
  </Switch>
)

interface Props {}
const App: React.FC<Props> = (props) => {
  const dispatch = useDispatch()
  const user = useSelector((state: IRootReduxState) => state.oidc.user)
  const profile = useSelector((state: IRootReduxState) => state.app.user.current)
  const access = useSelector((state: IRootReduxState) => state.app.user.access)
  const moduleAccess = new ModuleAccessProvider(access)

  const getLoggedInRoutes = () => (
    <Switch>
      <Route exact path="/" render={() => <Home />} />
      <Route exact path="/callback" render={() => <Callback />} />
      <ProtectedRoute
        access={moduleAccess.getAccess(Module.InteractiveCharts)}
        exact
        path="/reports/live/:id?"
        render={(props) => {
          return <LiveReporting {...props} />
        }}
      />
      <ProtectedRoute
        access={moduleAccess.getAccess(Module.ParticipationDashboard)}
        exact
        path="/reports/participation"
        render={(props) => {
          return <ParticipationDashboard {...props} />
        }}
      />
      <ProtectedRoute
        access={moduleAccess.getAccess(Module.CMMSMaintenanceDashboard)}
        exact
        path="/reports/maintenance"
        render={(props) => {
          return <AssetMaintenanceDashboard {...props} />
        }}
      />
      <ProtectedRoute
        access={moduleAccess.getAccess(Module.Calendar)}
        exact
        path="/calendar"
        render={(props) => {
          dispatch(setPageTitle(<FormattedMessage id="title.calendar" defaultMessage="Calendar" />))
          return <Calendar {...props} />
        }}
      />
      <Route
        exact
        path="/dynamic/:id?"
        render={(props) => {
          dispatch(setPageTitle(<FormattedMessage id="title.dynamic-form" defaultMessage="View Dynamic Form" />))
          return <DynamicFormViewer {...props} />
        }}
      />
      <ProtectedRoute
        access={moduleAccess.getAccess(Module.Assignments)}
        exact
        path="/assignments/assignedToYou"
        render={() => {
          dispatch(setPageTitle(<FormattedMessage id="title.assignments.to-you" defaultMessage="Assignments" />))
          return <AssignmentsToYou />
        }}
      />
      <ProtectedRoute
        access={moduleAccess.getAccess(Module.Assignments)}
        exact
        path="/assignments/assignedByYou"
        render={() => {
          dispatch(setPageTitle(<FormattedMessage id="title.assignments.by-you" defaultMessage="Assignments" />))
          return <AssignmentsByYou />
        }}
      />
      ;
      <ProtectedRoute
        access={moduleAccess.getAccess(Module.DocumentLibrary)}
        exact
        path="/library/documents"
        render={() => {
          dispatch(setPageTitle(<FormattedMessage id="title.document-library" defaultMessage="Document Library" />))
          return <Library />
        }}
      />
      <ProtectedRoute
        access={moduleAccess.getAccess(Module.DocumentLibrary)}
        exact
        path="/library/cultureGroups"
        render={() => {
          dispatch(setPageTitle(<FormattedMessage id="title.culture-groups" defaultMessage="Document Library / Culture Groups" />))
          return <CultureGroups />
        }}
      />
      <ProtectedRoute
        access={moduleAccess.getAccess(Module.OrganizationChart)}
        exact
        path="/organization"
        render={() => {
          dispatch(setPageTitle(<FormattedMessage id="title.organization" defaultMessage="Team Organization" />))
          return <Organization />
        }}
      />
      <ProtectedRoute
        access={moduleAccess.getAccess(Module.CompletedForms)}
        exact
        path="/forms/completed"
        render={() => {
          return <CompletedForms />
        }}
      />
      <ProtectedRoute
        access={moduleAccess.getAccess(Module.BlankForms)}
        exact
        path={['/form/templates/anonymous', '/form/templates/anonymous/:id', '/form/templates/anonymous/:action/:id']}
        render={() => {
          return <AnonymousFormsPage />
        }}
      />
      <ProtectedRoute
        access={moduleAccess.getAccess(Module.BlankForms)}
        exact
        path={['/form/templates', '/form/templates/:id']}
        render={() => {
          dispatch(setPageTitle(<FormattedMessage id="title.form-templates" defaultMessage="Form Templates" />))
          return <FormsSettingsPage />
        }}
      />
      <ProtectedRoute
        access={moduleAccess.getAccess(Module.CompanySettings)}
        exact
        path="/settings/company"
        render={() => {
          dispatch(setPageTitle(<FormattedMessage id="title.settings.company" defaultMessage="Settings / Company" />))
          return <CompanySettingsPage />
        }}
      />
      <Route
        exact
        path="/settings/picklist"
        render={() => {
          dispatch(setPageTitle(<FormattedMessage id="title.settings.picklists" defaultMessage="Settings / Picklists" />))
          return <PicklistSettingsPage />
        }}
      />
      <ProtectedRoute
        access={moduleAccess.getAccess(Module.People)}
        exact
        path="/settings/users"
        render={() => {
          return <UserListPage />
        }}
      />
      <Route
        exact
        path="/settings/emailTemplates"
        render={() => {
          return <EmailTemplatesPage />
        }}
      />
      <Route
        exact
        path="/settings/user/:id?"
        render={(props) => {
          return <UserProfilePage {...props} />
        }}
      />
      <Route
        exact
        path="/profile"
        render={(props) => {
          dispatch(setPageTitle(<FormattedMessage id="title.settings.user-profile-me" defaultMessage="My Profile" />))
          return <UserProfilePage user={profile} {...props} />
        }}
      />
      <Route
        path="/settings/securityModules"
        render={() => {
          return <SecurityPage />
        }}
      />
      <ProtectedRoute
        access={moduleAccess.getAccess(Module.Geospatial)}
        exact
        path="/settings/raster"
        render={() => {
          return <RasterPage />
        }}
      />
      <Route
        exact
        path="/assets/sitesLocations"
        render={(props) => {
          dispatch(setPageTitle(<FormattedMessage id="title.settings.user-profile-me" defaultMessage="My Profile" />))
          return <SitesLocations {...props} />
        }}
      />
      <Route
        exact
        path="/assets/picklists"
        render={(props) => {
          dispatch(setPageTitle(<FormattedMessage id="title.settings.user-profile-me" defaultMessage="My Profile" />))
          return <Picklists {...props} />
        }}
      />
      <Route
        exact
        path="/assets/maintenanceSchedules"
        render={(props) => {
          dispatch(setPageTitle(<FormattedMessage id="title.settings.user-profile-me" defaultMessage="My Profile" />))
          return <MaintenanceSchedules {...props} />
        }}
      />
      <Route
        exact
        path="/assets/assetsmanagement"
        render={(props) => {
          dispatch(setPageTitle(<FormattedMessage id="title.settings.user-profile-me" defaultMessage="My Profile" />))
          return <AssetsManagement {...props} />
        }}
      />
      <ProtectedRoute
        access={moduleAccess.getAccess(Module.JourneyManagement)}
        exact
        path="/journeyManagement"
        render={() => {
          return <JourneyManagementDashboard />
        }}
      />
      <Route
        exact
        path="/locationTracking"
        render={() => {
          return <LocationTrackingDashboard />
        }}
      />
      <ProtectedRoute
        access={moduleAccess.getAccess(Module.Geospatial)}
        exact
        path="/settings/UserGeneratedShapes"
        render={() => {
          return <UserGeneratedShapesPage />
        }}
      />
      <Route
        exact
        path="/Forbidden"
        render={() => {
          return <Forbidden />
        }}
      />
      <Route
        render={(props) => {
          return <NoMatch />
        }}
      />
    </Switch>
  )

  React.useEffect(() => {
    if (!profile && user) {
      const api = new WorkawareApi()
      api.user.get(user.profile.user_id).then((result) => {
        dispatch(userLoaded(result))
      })
      api.modulesAccess.get().then((result) => {
        dispatch(userModuleAccessLoaded(result))
      })
    }
    if (profile && !user) {
      dispatch(userUnloaded())
      dispatch(userModuleAccessCleared())
    }
    // eslint-disable-next-line
  }, [user])

  return (
    <>
      <Helmet titleTemplate="Workaware: %s" defaultTitle="Workaware.Online">
        <meta name="description" content="Workaware Online Client" />
      </Helmet>
      <Navigation showTopNavigation={false}>{user ? getLoggedInRoutes() : getAnonymousRoutes()}</Navigation>
    </>
  )
}

export default App
