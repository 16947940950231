import { transform, TransformMethods } from 'data/transform';
import { WorkawareApi } from 'apis/WorkawareApi';
import { AssignmentModel, AssignmentItemModel, AssignmentDetailModel, AssignmentCreateModel, AssignmentUpdateModel } from 'data/models';
import { CalendarEvent } from './calendarService';
export class assignmentsService {
  private _api: WorkawareApi;
  private _assignmentTransform: TransformMethods<AssignmentModel>;
  private _assignmentItemTransform: TransformMethods<AssignmentItemModel>;
  private _assignmentDetailTransform: TransformMethods<AssignmentDetailModel>;
  constructor(api: WorkawareApi) {
    this._api = api;
    this._assignmentTransform = transform<AssignmentModel>(AssignmentModel);
    this._assignmentItemTransform = transform<AssignmentItemModel>(AssignmentItemModel)
    this._assignmentDetailTransform = transform<AssignmentDetailModel>(AssignmentDetailModel)
  }

  incomplete = (limit: number = 10): Promise<AssignmentModel[]> => new Promise((resolve, reject) => {
    this._api.assignment.incompleteTopLevel(limit)
      .then(result => resolve(this._assignmentTransform.arrayFrom(result.value)))
      .catch(error => reject(error));
  });

  incompleteCount = (): Promise<number> => new Promise((resolve, reject) => {
    this._api.assignment.incompleteCount()
      .then(result => resolve(result['@odata.count'] as number))
      .catch(error => reject(error));
  });

  get = (id: number): Promise<AssignmentModel> => new Promise((resolve, reject) => {
    if (id === undefined) {
      return reject('id is undefined');
    }
    this._api.assignment.get(id)
      .then(result => {
        resolve(this._assignmentTransform.from(result))})
      .catch(error => reject(error));
  });
  getDetail = (id: number): Promise<AssignmentDetailModel | undefined> => new Promise((resolve, reject) => {
    this._api.assignment.v2.get(id)
      .then(result => {
        var assignmentDetail = this._assignmentDetailTransform.arrayFrom(result).shift();
        if (assignmentDetail) {
          resolve(assignmentDetail)
        } else {
          reject(Error('Assignment not found or inaccessible'))
        }
      })
      .catch(error => reject(error))
  });
  item = (id?: number): Promise<AssignmentItemModel> => new Promise((resolve, reject) => {
    if (id === undefined) {
      return reject('id is undefined');
    }
    this._api.assignment.item(id)
      .then(result => resolve(this._assignmentItemTransform.from(result)))
      .catch(error => reject(error));
  });
  /**
   * Check level of access the current user has to @param assignment.  If assigned by them (ie full access)
   * return true, otherwise false.
   * @param assignment 
   */
  isAssignedByCurrentUser = (assignment: AssignmentDetailModel): Promise<boolean> => {
    return this.isAssignedByCurrentUserFromId(assignment.Id);
  }
  isAssignedByCurrentUserFromId = async (id: number): Promise<boolean> => {
    var list = this._assignmentItemTransform.arrayFrom(await this._api.assignment.v2.assignedByLoggedInUser.get(id));
    return list.shift() !== undefined;
  }
  complete = async (assignment?: AssignmentModel | AssignmentDetailModel): Promise<void> => {
    if (assignment?.Id === undefined) {
      throw Error('assignment is undefined');
    }
    await this._api.assignment.v2.complete(assignment.Id);
  };

  update = (id?: number, changes?: any): Promise<void> => new Promise((resolve, reject) => {
    if (id === undefined) {
      return reject('id is undefined');
    }
    if (changes === undefined) {
      return reject('assignment is undefined');
    }
    this._api.assignment.update(id, changes)
      .then(() => resolve())
      .catch(error => reject(error));
  });

  delete = (id?: number): Promise<void> => new Promise((resolve, reject) => {
    if (id === undefined) {
      return reject('id is undefined');
    }
    this._api.assignment.delete(id)
      .then(() => resolve())
      .catch(error => reject(error));
  });

  children = (id?: string): Promise<Array<AssignmentModel>> => new Promise((resolve, reject) => {
    if (id === undefined) {
      return reject('id is undefined');
    }
    this._api.assignment.children(id)
      .then(result => resolve(this._assignmentTransform.arrayFrom(result.value)))
      .catch(error => reject(error));
  });

  incompleteChildren = (id?: string): Promise<Array<AssignmentModel>> => new Promise((resolve, reject) => {
    if (id === undefined) {
      return reject('id is undefined');
    }
    this._api.assignment.incompleteChildren(id)
      .then(result => resolve(this._assignmentTransform.arrayFrom(result.value)))
      .catch(error => reject(error));
  });

  getCurrentUserIncompleteAssignments = (): Promise<Array<AssignmentDetailModel>> => new Promise((resolve, reject) => {
    this._api.assignment.v2.assignedToLoggedInUser.incomplete()
      .then(result => resolve(this._assignmentDetailTransform.arrayFrom(result)))
      .catch(error => reject(error));
  });

  getCurrentUserCreatedIncompleteAssignments = (): Promise<Array<AssignmentDetailModel>> => new Promise((resolve, reject) => {
    this._api.assignment.v2.assignedByLoggedInUser.incompleteTopLevel()
      .then(result => resolve(this._assignmentDetailTransform.arrayFrom(result)))
      .catch(error => reject(error));
  });

  createfromTemplate = (id?: number): Promise<AssignmentModel> => new Promise((resolve, reject) => {
    if (id === undefined) {
      return reject('id is undefined');
    }
    var createModel = new AssignmentCreateModel();
    createModel.CompanyFormId = id;
    this._api.assignment.create.fromModel(id)
      .then(result => resolve(this._assignmentTransform.from(result)))
      .catch(error => reject(error));
  });

  createFromForm = (id?: number): Promise<AssignmentModel> => new Promise((resolve, reject) => {
    if (id === undefined) {
      return reject('id is undefined');
    }
    this._api.assignment.create.fromForm(id)
      .then(result => resolve(this._assignmentTransform.from(result)))
      .catch(error => reject(error));
  });

  sendNotification = (id?: number): Promise<void> => new Promise((resolve, reject) => {
    if (id === undefined) {
      return reject('id is undefined');
    }
    this._api.assignment.sendNotification(id)
      .then(() => resolve())
      .catch(error => reject(error));
  });

  forEvent = (event: CalendarEvent): Promise<Array<AssignmentDetailModel>> => new Promise((resolve, reject) => {
    this._api.assignment.forEvent(event.id)
      .then(result => {
        resolve(this._assignmentDetailTransform.arrayFrom(result))
      })
      .catch(error => reject(error));
  });
  createFromModel = async (model: AssignmentCreateModel): Promise<AssignmentDetailModel> => {
    var assignment = await this._api.assignment.create.fromModel(model);
    if (!assignment) {
      throw new Error('Creation failed');
    }
    var createdModel = await this.getDetail(assignment.Id);
    if (!createdModel) {
      throw new Error('Loading model failed');
    }
    return createdModel;
  };
  updateFromModel = (id: number, model: AssignmentUpdateModel): Promise<void> => new Promise((resolve, reject) => {
    this._api.assignment.v2.update(id, model)
      .then(() => resolve())
      .catch(error => reject(error))
  })
}
