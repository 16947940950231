import { WorkawareApi } from 'apis/WorkawareApi'
import { CmmsOverdueAssetDto } from 'data/dtos'
import { CmmsSiteDto } from 'data/dtos'
import { AssetListingDto, AssetDto, AssetPhotoDto, AssetDocumentDto } from 'data/dtos'
import { transform, TransformMethods } from 'data/transform';

export class assetsService {

  private _api: WorkawareApi
  private _assetTransform: TransformMethods<CmmsOverdueAssetDto>;

  constructor(api: WorkawareApi) {
    this._api = api
    this._assetTransform = transform<CmmsOverdueAssetDto>(CmmsOverdueAssetDto)
  }

  getOverdueAssets = () => {
    return new Promise<CmmsOverdueAssetDto[]>((resolve, reject) => {
      this._api.assets.overdue()
        .then(result => resolve(this._assetTransform.arrayFrom(result.value)))
        .catch(error => reject(error))
    });
  }

  getSites = () => {
    return new Promise<CmmsSiteDto[]>((resolve, reject) => {
      this._api.assets.sites.getTree()
        .then(result => resolve(result))
        .catch(error => reject(error))
    });
  }

  getSitesList = () => {
    return new Promise<{name: string, value: string}[]>((resolve, reject) => {
      this._api.assets.sites.getList()
        .then(result => {
          const items = result.value.map(i => {
            return { name: i.Name, value: i.Id }
          })
          resolve(items);
        })
        .catch(error => reject(error))
    });
  }

  getBarcodeTypes = () => {
    return new Promise<{name: string, value: string}[]>((resolve, reject) => {
      this._api.assets.getBarcodeTypes()
        .then(result => resolve(result))
        .catch(error => reject(error))
    });
  }

  getOdometerUnitTypes = () => {
    return new Promise<{name: string, value: string}[]>((resolve, reject) => {
      this._api.assets.getOdometerUnitTypes()
        .then(result => resolve(result))
        .catch(error => reject(error))
    });
  }

  getSiteTypes = () => {
    return new Promise<any>((resolve, reject) => {
      this._api.assets.sites.getTypes()
        .then(result => resolve(result))
        .catch(error => reject(error))
    });
  }

  getSite = (id: number) => {
    return new Promise<CmmsSiteDto>((resolve, reject) => {
      this._api.assets.sites.get(id)
        .then(result => resolve(result))
        .catch(error => reject(error))
    });
  }

  createSite = (site: CmmsSiteDto) => {
    return new Promise<CmmsSiteDto>((resolve, reject) => {
      this._api.assets.sites.post(site)
        .then(result => resolve(result))
        .catch(error => reject(error))
    });
  }

  updateSite = (site: CmmsSiteDto) => {
    return new Promise<CmmsSiteDto>((resolve, reject) => {
      this._api.assets.sites.put(site)
        .then(result => resolve(result))
        .catch(error => reject(error))
    });
  }

  deleteSite = (id: any) => {
    return new Promise<CmmsSiteDto>((resolve, reject) => {
      this._api.assets.sites.delete(id)
        .then(result => resolve(result))
        .catch(error => reject(error))
    });
  }

  getAssetList = () => {
    return new Promise<AssetListingDto[]>((resolve, reject) => {
      this._api.assets.getList()
        .then(result => resolve(result.value))
        .catch(error => reject(error))
    });
  }

  getAsset = (id: number) => {
    return new Promise<AssetDto>((resolve, reject) => {
      this._api.assets.get(id)
        .then(result => resolve(result))
        .catch(error => reject(error))
    });
  }

  postAsset = (asset: AssetDto) => {
    return new Promise<AssetDto>((resolve, reject) => {
      this._api.assets.post(asset)
        .then(result => resolve(result))
        .catch(error => reject(error))
    });
  }

  putAsset = (asset: AssetDto) => {
    return new Promise<AssetDto>((resolve, reject) => {
      this._api.assets.put(asset)
        .then(result => resolve(result))
        .catch(error => reject(error))
    });
  }

  deleteAsset = (id: number) => {
    return new Promise<AssetDto>((resolve, reject) => {
      this._api.assets.delete(id)
        .then(result => resolve(result))
        .catch(error => reject(error))
    });
  }

  uploadPhoto = (id: number, data: FormData) => {
    return new Promise<AssetPhotoDto>((resolve, reject) => {
      this._api.assets.photos.upload(id, data)
        .then(result => resolve(result))
        .catch(error => reject(error))
    });
  }

  downloadPhoto = (id: number) => {
    return new Promise<Blob>((resolve, reject) => {
      this._api.assets.photos.download(id)
        .then(result => resolve(result))
        .catch(error => reject(error))
    });
  }

  uploadDocument = (id: number, data: FormData) => {
    return new Promise<AssetDocumentDto>((resolve, reject) => {
      this._api.assets.documents.upload(id, data)
        .then(result => resolve(result))
        .catch(error => reject(error))
    });
  }

  downloadDocument = (id: number) => {
    return new Promise<Blob>((resolve, reject) => {
      this._api.assets.documents.download(id)
        .then(result => resolve(result))
        .catch(error => reject(error))
    });
  }
}