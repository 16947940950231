import { transform, TransformMethods, Dictionary } from 'data/transform'
import { UserModel } from 'data/models'
import { WorkawareApi } from 'apis/WorkawareApi'
import { PasswordUpdateRequest, PasswordGenerationRequest } from 'data/dtos'
import { PagedResult, PagedArguments } from 'types/apis'

export class userService {
  private _api: WorkawareApi
  private _userTransform: TransformMethods<UserModel>

  constructor(api: WorkawareApi) {
    this._api = api
    this._userTransform = transform<UserModel>(UserModel)
  }

  getUser = (id: number) => {
    return new Promise<UserModel>((resolve, reject) => {
      this._api.user
        .get(id)
        .then((result) => resolve(this._userTransform.from(result)))
        .catch((error) => reject(error))
    })
  }

  getAllUsers = () => {
    return new Promise<Array<UserModel>>((resolve, reject) => {
      this._api.user
        .all()
        .then((result) => resolve(this._userTransform.arrayFrom(result.value)))
        .catch((error) => reject(error))
    })
  }

  getPagedUsers = (args: PagedArguments) => {
    return new Promise<PagedResult<UserModel>>((resolve, reject) => {
      this._api.user
        .paged(args)
        .then((result) => resolve(result))
        .catch((error) => reject(error))
    })
  }

  getActiveUsers = () => {
    return new Promise<Array<UserModel>>((resolve, reject) => {
      this._api.user
        .active()
        .then((result) => resolve(this._userTransform.arrayFrom(result.value)))
        .catch((error) => reject(error))
    })
  }
  getActiveUsersLookup = () => {
    return new Promise<Dictionary<UserModel>>((resolve, reject) => {
      this._api.user
        .active()
        .then((result) => resolve(this._userTransform.mapFrom(result.value, 'Id')))
        .catch((error) => reject(error))
    })
  }

  createUser = (user: UserModel) => {
    return new Promise<UserModel>((resolve, reject) => {
      this._api.user
        .create(user)
        .then((user) => resolve(this._userTransform.from(user)))
        .catch((error) => reject(error))
    })
  }

  updateUser = (id: number, user: Dictionary<any>) => {
    return new Promise((resolve, reject) => {
      this._api.user
        .update(id, user)
        .then((user) => resolve(this._userTransform.from(user)))
        .catch((error) => reject(error))
    })
  }

  updatePassword = (id: number, password: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      const dto = new PasswordUpdateRequest()
      dto.userId = id
      dto.password = password
      this._api.user
        .updatePassword(dto)
        .then(() => resolve())
        .catch((error) => reject(error))
    })
  }

  generatePassword = (id: number): Promise<void> => {
    return new Promise((resolve, reject) => {
      const dto = new PasswordGenerationRequest()
      dto.userId = id
      this._api.user
        .generatePassword(dto)
        .then(() => resolve())
        .catch((error) => reject(error))
    })
  }

  uploadPhoto = async (data: FormData, name: string) => {
    await this._api.user.uploadPhoto(data, name)
  }

  userExists = async (username: string) => {
    return await this._api.user.checkUsername(username)
  }
}
