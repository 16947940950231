import { WorkawareApi } from 'apis/WorkawareApi'
import { ShapeFileModel } from 'data/models/ShapefileModel'
import { transform, TransformMethods } from 'data/transform'

export class shapeFilesService {
  private _api: WorkawareApi
  private _shapeTransform: TransformMethods<ShapeFileModel>

  constructor(api: WorkawareApi) {
    this._api = api
    this._shapeTransform = transform<ShapeFileModel>(ShapeFileModel)
  }

  getShapeFile = async () => {
    const result = await this._api.shapeFiles.all()
    return this._shapeTransform.arrayFrom(result.value)
  }
}
