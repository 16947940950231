import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';
import App from 'containers/app';
import EditPdf from 'containers/editPdf';
import Listener from 'components/Listener';
import { getLocale } from 'etc/i18nConfig';
import { Notifier } from 'factories/notificationFactory';
import { getUrlVars } from 'utils/utilities';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import 'react-notifications-component/dist/theme.css';
import userManager from 'utils/userManager';
import { IRootReduxState } from 'types/reducers';

const RootElement: React.FC = () => {
  const user = useSelector((state: IRootReduxState) => state.oidc.user)
  const theme = useSelector((state: IRootReduxState) => state.app.user.theme)
  const localeCode = useSelector((state: IRootReduxState) => state.app.user.localeCode)

  // if the conditions are right (no user and not a callback uri) redirect to login
  const excludedLocationPaths = ['/', '/callback', '/bye'];
  if (window.location && !excludedLocationPaths.includes(window.location.pathname)) {
    userManager.getUser().then(response => {
      if (!response || response.expired) {
        localStorage.setItem('workaware-requested-pathname', btoa(window.location.pathname + window.location.search));
        userManager.signinRedirect();
      }
    });
  }

  const locale = getLocale(localeCode);

  const appTheme = React.useMemo(() => {
    return createMuiTheme({
      overrides: {
        MuiGrid: {
          root: {
            '& .MuiGrid-item': {
              padding: 0
            },
            '& .MuiInput-underline:before': {
              borderColor: '#ddd'
            }
          }
        }
      },
      spacing: factor => `${0.25 * factor}rem`,
      typography: {
        body1: {
          fontSize: '0.8rem'
        }
      },
      palette: {
        type: theme,
        primary: {
          main: theme === 'dark' ? '#64db00' : '#4c90e3',
        },
        secondary: {
          main: theme === 'dark' ? '#2196f3' : '#64dd17'
        }
      }
    });
  }, [theme]);

  const getAppRootComponent = () => {
    const vars = getUrlVars({ entryPoint: '' });
    switch (vars.entryPoint) {
      case 'pdf':
        return (
          <EditPdf user={user} />
        );
      default:
        return (
          <BrowserRouter>
            <App />
          </BrowserRouter>
        );
    }
  };

  return (
    <IntlProvider key={locale.code} locale={locale.code} messages={locale.messages}>
      <ThemeProvider theme={appTheme}>
        <Notifier />
        <Listener user={user} />
        <Suspense fallback={`loading...`}>
          {getAppRootComponent()}
        </Suspense>
      </ThemeProvider>
    </IntlProvider>
  );
};

export default RootElement;
