import { transform, TransformMethods } from 'data/transform'
import { WorkawareApi } from 'apis/WorkawareApi'
import { SecurityModel } from 'data/models/SecurityModel'

export class securityModuleService {
  private _api: WorkawareApi
  private _securityModuleTransform: TransformMethods<SecurityModel>

  constructor(api: WorkawareApi) {
    this._api = api
    this._securityModuleTransform = transform<SecurityModel>(SecurityModel)
  }
  getAllSecurityModules = () => {
    return new Promise<SecurityModel[]>((resolve, reject) => {
      this._api.companyModuleSecurity
        .all()
        .then((result) => resolve(this._securityModuleTransform.arrayFrom(result.value)))
        .catch((error) => reject(error))
    })
  }
  updateSecurityModule = (id: number, securityModule: SecurityModel) => {
    return new Promise((resolve, reject) => {
      this._api.companyModuleSecurity
        .update(id, securityModule)
        .then((item) => resolve(this._securityModuleTransform.from(item)))
        .catch((error) => reject(error))
    })
  }
}
