export class AssetMaintenanceDto {
    public Id: number
    public DescriptionOfWork: string
    public CMMSMaintenanceScheduleId?: number
    public MaintenanceStatusId?: number
    public TagOutTypeId?: number
    public WorkOrderNumber: string
    public MachineHours?: number
    public Odometer?: number
    public OutageStart?: Date
    public OutageEnd?: Date
    public ActualStart?: Date
    public ActualEnd?: Date
    public TagsHungOn?: Date
    public TagsHungByUserId?: number
    public TagsReleasedOn?: Date
    public TagsReleasedByUserId?: number
    public ApprovedOn?: Date
    public ApprovedByUserId?: number
    public RequestedOn?: Date
    public RequestedByUserId?: number
    public Note: string
    public NotificationUsers: Array<number> = []
    public NotificationTeams: Array<number> = []
    public Hazards: Array<number> = []
    public CreatedBy: string
    public CreatedOn: Date
}