import { IMessage } from "@stomp/stompjs";
import { IMessagingState, IMessagingAction, Message } from 'types/reducers';

export const MESSAGE_RECEIVED = 'workaware/messager/MESSAGE_RECEIVED';
export const CONNECTED = 'workaware/messager/CONNECTED';
export const DISCONNECTED = 'workaware/messager/DISCONNECTED';

export const messageReceived = (message: IMessage) => ({ type: MESSAGE_RECEIVED, message });
export const connected = () => ({ type: CONNECTED });
export const disconnected = () => ({ type: DISCONNECTED });


const initialState: IMessagingState = {
  connected: false,
  collection: []
};

const messageBufferSize = 10

const messagingReducer = (state: IMessagingState = initialState, action: IMessagingAction): IMessagingState => {
  switch (action.type) {
    case DISCONNECTED:
      return Object.assign({}, state, { connected: false });
    case CONNECTED:
      return Object.assign({}, state, { connected: true });
    case MESSAGE_RECEIVED:
      const id = action.message.headers['message-id'];
      const timestamp = action.message.headers['timestamp'];
      console.info(`Broadcast event: ${id} @ ${timestamp}`);
      const message: Message = JSON.parse(action.message.body)
      console.info(message)
      return Object.assign({}, state, {
        collection: [message, ...state.collection].slice(0, messageBufferSize)
      });
    default:
      return state;
  }
}

export default messagingReducer;