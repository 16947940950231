import { WorkawareBaseClass } from './WorkawareBaseClass'

export class JourneyModel extends WorkawareBaseClass {
  _className = 'JourneyModel'
  public IsActive: boolean | undefined = undefined
  public Id: number | undefined = undefined
  public Name: string | undefined = undefined
  public CreatedOn: Date | undefined = new Date()
  public Description: string | undefined = undefined
  public CreatedByUserId: string | undefined = undefined
  public CompanyId: number | undefined = undefined
  public CheckInIntervalMinutes : number | undefined = undefined
  public EscalationMinutes?: number | undefined = undefined
  public NotifySafetyManagers:boolean | undefined = undefined
  public NotificationLevel? : number | undefined = undefined
  public StatusId : number | undefined = 1
  public PriorityId : number | undefined = undefined
}
