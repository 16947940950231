import React from 'react'

interface Props {
  icon: string | JSX.Element
}
const Icon: React.FC<Props> = ({ icon }) => {
  if ('string' === typeof icon) {
    return (
      <span className="fa-stack fa-lg">
        <i className={`fa fa-${icon} fa-stack-1x`}></i>
      </span>
    )
  }

  return icon
}

export default Icon
