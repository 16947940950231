import { TeamMemberModel, FileModel } from ".";
import { WorkawareBaseNodeClass } from './WorkawareBaseNodeClass';

export class TeamModel extends WorkawareBaseNodeClass {
  public Id: number | undefined = undefined;
  public Branch: string | undefined = undefined;
  public Color: string | undefined = undefined;
  public CompanyId: number | undefined = undefined;
  public Name: string | undefined = undefined;
  public ParentOrganizationTeamHierarchyId: number | undefined = undefined;
  public Files: FileModel[] = [];
  public members? : TeamMemberModel[];

  public constructor() {
    super();
    this._stripProperties = ['Files', 'members'];
  }
}