import moment, { Moment } from 'moment'
import React from 'react'
import { Dictionary } from 'types'

export const objectMod = (obj: object, addProperties = {}, removeProperties: string[] | string = [], caseSensitive: boolean = true) => {
  if (typeof removeProperties === 'string') {
    removeProperties = [removeProperties]
  }
  obj = Object.assign(obj, addProperties)
  removeProperties.forEach((prop) => {
    delete obj[prop]
  })
  return obj
}

export const is = {
  _is: function(e, type) {
    return e !== null && e !== undefined && typeof e === type
  },
  String: function(e) {
    return this._is(e, 'string')
  },
  Number: function(e) {
    return this._is(e, 'number')
  },
  Boolean: function(e) {
    return this._is(e, 'boolean')
  },
  Object: function(e) {
    return this._is(e, 'object') && !Array.isArray(e)
  },
  Array: function(e) {
    return Array.isArray(e)
  },
}

export const longDate = (date) => moment(date).format('llll')

export const shortDate = (date) => moment(date).format('llll')

export const humanFileSize = (size: number) => {
  if (!is.Number(size)) {
    size = 0
  }
  const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024))
  return `${(size / 1024 ** i).toFixed(2)} ${['B', 'KB', 'MB', 'GB', 'TB'][i]}`
}

export const trimCharacters = (s, c) => {
  if (c === ']') c = '\\]'
  if (c === '\\') c = '\\\\'
  return s.replace(new RegExp('^[' + c + ']+|[' + c + ']+$', 'g'), '')
}

export const parseColour = (colour?: string, defaultColour: string = 'inherit') => {
  if (!colour) return defaultColour
  if (/#?[0-9A-Fa-f]{6,}/.test(colour)) {
    if (colour.length === 8) return `#${colour.substring(2)}`
    if (colour.length === 7) return `${colour}`
    if (colour.length === 6) return `#${colour}`
  }
  return `#${toColour(colour)}`
}

const toColour = (str: string) => integerToRGB(stringToHash(str))

const stringToHash = (str: string) => {
  var hash = 0
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  return hash
}

const integerToRGB = (i: number) => {
  var c = (i & 0x00ffffff).toString(16).toUpperCase()
  return '00000'.substring(0, 6 - c.length) + c
}

export const blobToBase64 = (blob) =>
  new Promise((resolve, reject) => {
    var reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onerror = (e) => reject(e)
    reader.onloadend = () => resolve((reader.result as string).split(',')[1])
  })

export const base64toBlob = (base64, type = 'application/octet-stream') =>
  new Promise((resolve, reject) => {
    fetch(`data:${type};base64,${base64}`).then((res) => resolve(res.blob()))
  })

export const loadScript = (url: string) =>
  new Promise((resolve, reject) => {
    var script = document.createElement('script') as any
    script.type = 'text/javascript'
    if (script.readyState) {
      script.onreadystatechange = () => {
        if (script.readyState === 'loaded' || script.readyState === 'complete') {
          script.onreadystatechange = null
          resolve()
        }
      }
    } else {
      script.onload = () => resolve()
    }
    script.src = url
    document.getElementsByTagName('body')[0].appendChild(script)
  })

export const buildMap = (entries, getKey) => {
  let idMap = {}
  entries.forEach((entry) => {
    idMap[getKey(entry)] = entry
  })
  return idMap
}

export const getUrlVars = (defaults: Dictionary<string> = {}): Dictionary<string> => {
  var vars: Dictionary<string> = {}
  window.location.href
    .slice(window.location.href.indexOf('?') + 1)
    .split('&')
    .map((h) => {
      const parts = h.split('=')
      vars[parts[0]] = parts[1]
      return h
    })
  return Object.assign({}, defaults, vars)
}

const _easeInOutCubic = (x: number): number => {
  x = Math.abs(x)
  return x < 0.5 ? 4 * x ** 3 : 1 - (-2 * x + 2) ** 3 / 2
}

const _hex = (c) => c.toString(16).padStart(2, '0')

const _ratioFromMidday = (mmt: Moment) => _easeInOutCubic((mmt.diff(mmt.clone().startOf('day'), 'minutes') - 720) / 720)

const _rgb = (hex) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : null
}

export const timeColour = (start, end) => {
  var ratio = _ratioFromMidday(moment())
  var w1 = ratio
  var w2 = 1 - w1
  var rgb1 = _rgb(start)
  var rgb2 = _rgb(end)
  if (rgb1 && rgb2) {
    var r = (rgb1[0] * w1 + rgb2[0] * w2) | 0
    var g = (rgb1[1] * w1 + rgb2[1] * w2) | 0
    var b = (rgb1[2] * w1 + rgb2[2] * w2) | 0
    var h = `#${_hex(r)}${_hex(g)}${_hex(b)}`
    return h
  }
  return '#000000'
}

export const parseUrl = (url: string) => {
  const found = /^(.+):\/\/([^/?#]*)([^?#]*)\??([^#]*)#?(.*)$/.exec(url)
  // could not match against the provided string
  if (found?.length !== 6) return undefined
  const getHostParts = (host: string) => {
    let result: { username?: string; password?: string; hostname: string; port?: string } = { username: undefined, password: undefined, hostname: '', port: undefined }
    const parts = found[2].split('@')
    const hostport = parts.length === 1 ? parts[0].split(':') : parts[1].split(':')
    const userpass = parts.length > 1 ? parts[0].split(':') : []
    result.hostname = hostport[0]
    result.port = hostport[1]
    result.username = userpass[0]
    result.password = userpass[1]
    return result
  }
  let params = {}
  found[4].split('&').forEach((p) => {
    const arg = p.split('=')
    params[arg[0]] = arg[1]
  })
  const hostParts = getHostParts(found[2])
  return {
    href: found[0],
    protocol: found[1],
    username: hostParts.username,
    password: hostParts.password,
    host: hostParts.hostname,
    port: hostParts.port,
    path: found[3],
    parameters: params,
    hash: found[5],
  }
}

export const useIsMounted = () => {
  const isMounted = React.useRef(false)
  React.useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])
  return isMounted
}

export const copyToClipboard = (str: string) => {
  const el = document.createElement('textarea')
  el.value = str
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
}

export const copyProperties = (obj1: object, obj2: object) => {
  for (const prop in obj1) {
    if (obj2.hasOwnProperty(prop)) {
      obj1[prop] = obj2[prop]
    }
  }
  return obj1
}
