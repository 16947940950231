import { WorkawareBaseClass } from './WorkawareBaseClass'

export class ExternalEventModel extends WorkawareBaseClass {
  _className = 'ExternalEventModel'
  public Id: string = ''
  public Summary: string = ''
  public Description: string = ''
  public URL: string | undefined = undefined
  public StartDate: Date | undefined = undefined
  public EndDate: Date | undefined = undefined
  public CalendarId: string | undefined = undefined
}
