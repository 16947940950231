export class CmmsSiteTreeDto {
    public Id: number
    public Name: string
    public ChildCMMSSites?: CmmsSiteTreeDto[]
}

export class CmmsSiteDto extends CmmsSiteTreeDto {
    public IsActive: boolean
    public Description: string
    public Code: string
    public Latitude?: number
    public Longitude?: number
    public Address: string
    public City: string
    public Type: number = 1
    public Province: string
    public PostalCode: string
    public Country: string
    public UseParentAddress: boolean
    public ParentSiteId?: number
}