import { Dictionary } from 'types';

export const SET_CACHE_LIST = 'workaware/cache/SET_CACHE_LIST';
export const REMOVE_CACHE_LIST = 'workaware/cache/REMOVE_CACHE_LIST';

export const setCache = (name: string, list: any): CacheAction => ({ type: SET_CACHE_LIST, payload: { name, list } });
export const removeCache = (name: string): CacheAction => ({ type: REMOVE_CACHE_LIST, payload: { name } });


type CacheAction = {
  type: string,
  payload?: {
    name: string,
    list?: any
  }
}
export type CacheContainer = {
  data: any,
  timestamp: Date
}

const cacheReducer = (state: Dictionary<CacheContainer> = {}, action: CacheAction) => {
  switch (action.type) {
    case REMOVE_CACHE_LIST:
      const { [action.payload?.name as string]: value, ...newState } = state;
      return newState;
    case SET_CACHE_LIST:
      // make sure a name is set
      if (!action.payload?.name) return state;
      return { ...state, 
        [action.payload.name]: {
          data: action.payload.list || [],
          timestamp: new Date()
        }
      };
    default:
      return state;
  }
};

export default cacheReducer;
