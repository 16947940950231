import { WorkawareBaseClass } from './WorkawareBaseClass'

export class CMMSSiteModel extends WorkawareBaseClass {
    _className = 'CMMSSiteModel'
    public Id: number
    public CompanyId: number
    public IsActive: boolean
    public CreatedByUserId: number
    public CreatedOn: Date
    public Name: string
    public Type: number
    public ParentSiteId?: number
    public Description: string
    public Code: string
    public Latitude?: number
    public Longitude?: number
    public Address: string
    public City: string
    public Province: string
    public PostalCode: string
    public Country: string
    public UseParentAddress: boolean
}