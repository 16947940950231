import React from 'react'
import { ModuleNoAccessVisibilityType } from 'enums/ModuleNoAccessVisibilityType'
import { CompanyModuleAccessDto } from 'data/dtos/CompanyModuleAccessDto'

interface Props {
  permissions?: CompanyModuleAccessDto
}

const SecuredModule: React.FC<Props> = ({ permissions, children }) => {
  return (
    <div style={!permissions?.AccessAllowed && permissions?.Visibility === ModuleNoAccessVisibilityType.GreyedOut ? { background: 'grey', pointerEvents: !permissions.AccessAllowed ? 'none' : 'auto' } : {}}>
      {(permissions?.Visibility === ModuleNoAccessVisibilityType.GreyedOut || permissions?.AccessAllowed) && children}
    </div>
  )
}
export default SecuredModule