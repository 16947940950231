import { WorkawareApi } from 'apis/WorkawareApi'
import { JourneyModel } from 'data/models/JourneyModel'
import { transform, TransformMethods } from 'data/transform'

export class journeyService {
  private _api: WorkawareApi
  private _journeyTransform: TransformMethods<JourneyModel>

  constructor(api: WorkawareApi) {
    this._api = api
    this._journeyTransform = transform<JourneyModel>(JourneyModel)
  }
  getJourney = () => {
    return new Promise<JourneyModel[]>((resolve, reject) => {
      this._api.journeyManagement
        .getAllJourneys()
        .then((result) => resolve(this._journeyTransform.arrayFrom(result.value)))
        .catch((error) => reject(error))
    })
  }
  createJourney =(journey:JourneyModel) =>
  {
    return new Promise<JourneyModel>((resolve,reject) =>
    {
      this._api.journeyManagement
          .create(journey)
          .then((journey) =>resolve(this._journeyTransform.from(journey)))
          .catch((error) =>reject(error))
    })
  }
  updateJourney = (id: number, journey: JourneyModel) => {
    return new Promise((resolve, reject) => {
      this._api.journeyManagement
        .update(id, journey)
        .then((journey) => resolve(this._journeyTransform.from(journey)))
        .catch((error) => reject(error))
    })
  }

}
