export class JourneyCheckPointModel {
  _classname = 'journeyCheckPointModel'
  public Id: number | undefined = undefined
  public Name: string | undefined = undefined
  public DepartureLocation: string | undefined = undefined
  public EstimatedDepartureOn: Date | undefined = new Date();
  public ArrivalLocation: string | undefined = undefined
  public CompanyId: number | undefined = undefined
  public JourneyId: number | undefined = undefined
  public EstimatedArrivalOn: Date |  undefined = new Date();
  public TravelMethodId: number | undefined = undefined
  public Description: string | undefined = undefined
  public ArrivalPointLatitude: number | undefined = undefined
  public ArrivalPointLongitude: number | undefined = undefined
  public DeparturePointLatitude: number | undefined = undefined
  public DeparturePointLongitude: number | undefined = undefined
}
