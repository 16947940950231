import React from 'react'
import ListItemBase from 'components/list-items/ListItemBase'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed'
import { SecurityModel } from 'data/models/SecurityModel'
import { SecurityModules } from 'enums/SecurityModules'
import { ListItemAvatar, useTheme } from '@material-ui/core'

interface Props {
  securityModule: SecurityModel
  selected?: boolean
  multiSelect?: boolean
  handleSelect?: (securityModule: SecurityModel) => void
}
const SecurityModuleListItem: React.FC<Props> = ({ securityModule, selected, handleSelect, multiSelect = false }) => {
  const theme = useTheme()
  const keys = React.useMemo(() => {
    return Object.keys(SecurityModules)
      .filter((x) => SecurityModules[x] === securityModule.ModuleId)[0]
      ?.replace(/([A-Z])/g, ' $1')
      .trim()
  }, [securityModule])
  const getTag = (name: string, value: boolean) => {
    const mod = theme.palette.type === 'dark' ? 'is-dark' : 'is-light'
    return (
      <span className={`tag is-lowercase mr-1 ${value ? 'is-success' : 'is-danger'} ${mod}`}>{name}</span>
    )
  }
  const getSecondaryText = () => {
    if (!securityModule) {
      return ''
    }
    return (<>
      {getTag('User', securityModule.CanUserAccess)}
      {getTag('Manager', securityModule.CanManagerAccess)}
      {getTag('Senior Manager', securityModule.CanSeniorManagerAccess)}
      {getTag('External Auditor', securityModule.CanExternalAuditorAccess)}
    </>)
  }
  return (
    <ListItemBase multiSelect={multiSelect} handleClick={() => handleSelect && handleSelect(securityModule)} selected={selected}>
      <ListItem>
        <ListItemAvatar>
          <DynamicFeedIcon color={securityModule.ModuleNoAccessVisibilityType === 2 ? 'secondary' : 'disabled'} />
        </ListItemAvatar>
        <ListItemText primary={keys} secondary={getSecondaryText()} />
      </ListItem>
    </ListItemBase>
  )
}

export default SecurityModuleListItem
