import { WorkawareApi } from 'apis/WorkawareApi';
import { PagedArguments, PagedResult } from 'types/apis';
import { FormCompletionModel } from 'data/models/FormCompletionModel';
import { FormCompletionAggregationDto } from 'data/dtos/FormCompletionAggregationDto';
import { DynamicFormCompletionAttachmentsDto } from 'data/dtos/DynamicFormCompletionAttachmentsDto';
import { ParticipationFilter } from 'components/liveReporting/models/ParticipationFilter';

export class completedFormsService {

  private _api: WorkawareApi;

  constructor(api: WorkawareApi) {
    this._api = api;
  }

  paged = (args: PagedArguments, filter?: string) => new Promise<PagedResult<FormCompletionModel>>((resolve, reject) => {
    this._api.forms.completed.paged(args, filter)
      .then(result => resolve({
        Count: result.Count,
        Values: result.Values
      }))
      .catch(error => reject(error));
  });

  countAll = () => new Promise<number>((resolve, reject) => {
    this._api.forms.completed.total()
      .then(result => resolve(+result))
      .catch(error => reject(error))
  });

  setOwner = (form: FormCompletionModel, newOwnerId: number) => new Promise<void>((resolve, reject) => {
    if (form.Id === undefined) {
      reject('form id is not set');
    } else {
      this._api.forms.completed.changeOwner(form.Id, newOwnerId)
        .then(() => resolve())
        .catch(error => reject(error))
    }
  });

  aggregated = (filter: ParticipationFilter) => new Promise<FormCompletionAggregationDto>((resolve, reject) => {
    this._api.forms.completed.aggregated(filter)
      .then(data => resolve(data as FormCompletionAggregationDto))
      .catch(error => reject(error));
  });

  /**
   * The promise returned from this call will resolve to either a 
   * FormCompletionDto or a DynamicFormCompletionDto based om whether the form
   * is 'dynamic' or not
   * 
   * @param id the form completion id to return
   * @returns FormCompletionDto | DynamicFormCompletionDto
   */
  get = (id: number) => new Promise<any>((resolve, reject) => {
    this._api.forms.completed.get(id)
      .then(result => resolve(result))
      .catch(error => reject(error))
  });

  /**
   * Returns the PDF (details) as a Blob
   * 
   * @param id 
   * @returns A Blop of the PDF data
   */
  details = (id: number) => new Promise<Blob>((resolve, reject) => {
    this._api.forms.detail.get(id)
      .then(result => resolve(result))
      .catch(error => reject(error))
  });

  /**
   * Save a dynamic form
   * 
   * @param data 
   */
  saveDynamicForm = (templateId: number, data: any) => new Promise<any>((resolve, reject) => {
    this._api.forms.dynamic.post({
      CompanyFormId: templateId,
      FormData: JSON.stringify(data)
    })
      .then(result => resolve(result))
      .catch(error => reject(error))
  });

  /**
   * Update a dynamic form
   * 
   * @param data 
   */
  updateDynamicForm = (formCompletionId: number, data: any) => new Promise<any>((resolve, reject) => {
    this._api.forms.dynamic.put({
      FormCompletionId: formCompletionId,
      FormData: JSON.stringify(data)
    })
      .then(result => resolve(result))
      .catch(error => reject(error))
  });

  /**
   * Get a minimal listing of the form attachment data
   * 
   * @param formCompletionId 
   */
  getDynamicFormAttachments = (formCompletionId: number) => new Promise<DynamicFormCompletionAttachmentsDto>((resolve, reject) => {
    this._api.forms.dynamic.attachments.get(formCompletionId)
      .then(result => resolve(result))
      .catch(error => reject(error))
  });

  deleteDynamicFormAttachment = (formCompletionId: number, attachmentId: string) => new Promise<DynamicFormCompletionAttachmentsDto>((resolve, reject) => {
    this._api.forms.dynamic.attachments.delete(formCompletionId, attachmentId)
      .then(result => resolve(result))
      .catch(error => reject(error))
  });

  uploadDynamicFormAttachment = (formCompletionId: number, data: FormData) => new Promise<DynamicFormCompletionAttachmentsDto>((resolve, reject) => {
    this._api.forms.dynamic.attachments.post(formCompletionId, data)
      .then(result => resolve(result))
      .catch(error => reject(error))
  });

  downloadAttachment = (formId: number, attachmentId: string) => new Promise<Blob>((resolve, reject) => {
    this._api.forms.dynamic.attachments.download(formId, attachmentId)
      .then(result => resolve(result))
      .catch(error => reject(error))
  });
}