import React from 'react';
import { loadScript } from 'utils/utilities';
import 'assets/styles/pdftron.scss';
import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

interface Props {
  blob: Blob,
  handleSave: (blob: Blob) => void,
  working: boolean
}
const PdfTron = ({ blob, handleSave, working }: Props) => {
  const classes = useStyles();
  let viewer = React.createRef<any>();

  React.useEffect(() => {
    const ref = viewer.current;
    loadScript('/lib/pdftron/webviewer.min.js').then(() => {
      initialize(ref);
    });
    // eslint-disable-next-line
  }, []);

  const initialize = ref => {
    (window as any)
      .WebViewer({ path: '/lib/pdftron' }, ref)
      .then(instance => {
        instance.setTheme('dark');
        instance.setHeaderItems(header => {
          header.push({
            type: 'actionButton',
            title: 'Save',
            img: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm-5 16c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-10H5V5h10v4z"/></svg>',
            onClick: () => save(instance)
          });
        });
        instance.loadDocument(blob, { filename: 'form.pdf' });
      });
  };

  const save = instance => {
    const { docViewer, annotManager } = instance;
    docViewer.getDocument().getFileData({ xfdfString: annotManager.exportAnnotations() })
      .then(data => {
        const arr = new Uint8Array(data);
        const blob = new Blob([arr], { type: 'application/pdf' });
        handleSave(blob);
      });
  };

  return (
    <>
      <div className="pdftron">
        <div className="header">PDF Editor</div>
        <div className="webviewer" ref={viewer}></div>
      </div>
      <Backdrop className={classes.backdrop} open={working}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default PdfTron;
