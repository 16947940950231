import { WorkawareBaseClass } from './WorkawareBaseClass'

export class AnonymousFormProperties {
  public AnonymousAccessActive: boolean = true
  public AnonymousAccessId: string = ''
}

export class CompanyFormModel extends WorkawareBaseClass {
  _className = 'CompanyFormModel'
  public FormCompletions: any[] = []
  public CompanyFormTemplates: any[] = []
  public BypassEmailQueue: boolean = false
  public Charts: any[] = []
  public CognitiveFieldId?: string = undefined
  public Color?: string = undefined
  public CompanyFormFriendlyNames: string[] = []
  public CompanyId: number = 0
  public CustomEmailSubject?: string = undefined
  public DateUploaded?: Date
  public Description?: string = undefined
  public DisplayOrder?: string = undefined
  public EmailTemplate?: string = undefined
  public Filename?: string = undefined
  public FollowUpCalculation?: string = undefined
  public FollowUpFormId?: string = undefined
  public FollowUpOnFieldId?: string = undefined
  public FollowUpOnFieldValue?: string = undefined
  public FormVersion?: string = undefined
  public GeoFencePicklistTypeId?: string = undefined
  public GeoFenceShapefileFieldId?: string = undefined
  public GeoFenceShapefileId?: string = undefined
  public GroupNamePicklistId?: number = undefined
  public HasCustomEmailTemplate: boolean = false
  public IconURL?: string = undefined
  public IconURLLastUpdatedOn?: string = undefined
  public Id: number = 0
  public IsActive: boolean = false
  public IsArchiveOnly: boolean = false
  public IsAvailableOnDesktop: boolean = false
  public IsAvailableOnMobile: boolean = false
  public LockedToShapefile: boolean = false
  public MaximumZoomVisible?: string = undefined
  public MinimumZoomVisible?: string = undefined
  public MobileFormType: number = 0
  public Name?: string = undefined
  public NotificationExpression?: string = undefined
  public NotificationLevel?: string = undefined
  public NotifySafetyManagers: boolean = false
  public NotifyTeamMembers: boolean = false
  public OnlyAllowSingleForm: boolean = false
  public ProcessAssetSerialNumber: boolean = false
  public ProcessShapefile: boolean = false
  public SecurityItems: any[] = []
  public ShapefileId?: string = undefined
  public ShowInBlankForms: boolean = true
  public ShowInCompletedForms: boolean = true
  public Size: number = 0
  public Type?: string = undefined
  public URL?: string = undefined

  // server added dto properties
  public AnonymousForms: AnonymousFormProperties[] = []
  public GroupName: string = ''
  public Tags: string[] = []
}
