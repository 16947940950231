import { WorkawareApi } from 'apis/WorkawareApi'
import { CompanyModel } from '../models/CompanyModel'

export class companyServices {
  private _api: WorkawareApi

  constructor(api: WorkawareApi) {
    this._api = api
  }

  get = (id: number): Promise<CompanyModel> => {
    return new Promise((resolve, reject) => {
      this._api.company
        .get(id)
        .then((result: CompanyModel) => resolve(result))
        .catch((error: any) => reject(error))
    })
  }

  getLogo = (id: string): Promise<Blob> => {
    return new Promise((resolve, reject) => {
      this._api.company
        .getLogo(id)
        .then((result: Blob) => resolve(result))
        .catch((error: any) => reject(error))
    })
  }

  update = (id: number, modified: object): Promise<void> => {
    return new Promise((resolve, reject) => {
      this._api.company
        .update(id, modified)
        .then(() => resolve())
        .catch((error: any) => reject(error))
    })
  }
}
