import { DocumentsApi } from 'apis/DocumentsApi'
import { WorkawareApi } from 'apis/WorkawareApi'
import { GraphDataRequestDto } from 'components/liveReporting/types'
import { LiveReportComponentDto } from 'data/dtos/LiveReportComponentDto'
import { ValueTranslationRequestDto } from 'data/dtos/ValueTranslationDto'


export class reportingService {
  private _api: DocumentsApi
  private _workawareApi: WorkawareApi

  constructor(api: DocumentsApi, workawareApi: WorkawareApi) {
    this._api = api
    this._workawareApi = workawareApi
  }

  translateIds = async (data: ValueTranslationRequestDto) => {
    return await this._workawareApi.reporting.utilities.translate(data)
  }

  aggregate = async (query: string) => {
    return await this._api.documents.aggregate(query)
  }

  getBarGraphData =  async (dto: GraphDataRequestDto) => {
    return this._api.liveData.getBarGraphData(dto)
  }

  getPieGraphData =  async (dto: GraphDataRequestDto) => {
    return this._api.liveData.getPieGraphData(dto)
  }

  getLiveReports = async () => {
    const result = await this._api.live.list()
    return result.map((r) => ({
      value: r._id,
      name: r.title,
    }))
  }

  getLiveReport = async (id: string) => {
    const result = await this._api.live.report(id)
    return result
  }

  createLiveReport = async (name: string) => {
    const result = await this._api.live.create({ name })
    return {
      value: result._id,
      name: result.title,
    }
  }

  addComponent = async (reportId: string, dto: LiveReportComponentDto) => {
    return await this._api.live.addComponent(reportId, dto)
  }

  removeComponent = async (reportId: string, componentId: string) => {
    return await this._api.live.removeComponent(reportId, componentId)
  }

  saveComponentOptions = async (reportId: string, componentId: string, options: string) => {
    return await this._api.live.saveComponentOptions(reportId, componentId, options)
  }

  saveComponentLabel = async (reportId: string, componentId: string, label: string) => {
    return await this._api.live.saveComponentLabel(reportId, componentId, label)
  }

  saveLayout = async (reportId: string, layout: string) => {
    return await this._api.live.saveLayout(reportId, layout)
  }
}
