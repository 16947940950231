import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Collapse from '@material-ui/core/Collapse'
import Icon from 'components/Icon'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { Tooltip } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { IRootReduxState } from 'types/reducers'

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(1),
  },
  menuListItem: {
    marginTop: '5px',
    marginBottom: '5px',
    paddingTop: '5px',
    paddingBottom: '5px',
  },
}))
interface Props {
  title: string | JSX.Element
  icon: string | JSX.Element
}
const MenuListItemContainer: React.FC<Props> = ({ title, icon, children }) => {
  const classes = useStyles()
  const [expanded, setExpanded] = React.useState(false)
  const sideBarOut = useSelector((state: IRootReduxState) => state.app.state.sideMenuExpanded)
  return (
    <>
      <Tooltip disableFocusListener={sideBarOut} disableHoverListener={sideBarOut} disableTouchListener={sideBarOut} title={title} arrow placement="right">
        <ListItem button onClick={() => setExpanded(!expanded)} className={classes.menuListItem}>
          <ListItemIcon>
            <Icon icon={icon} />
          </ListItemIcon>
          <ListItemText primary={title} />
          {expanded ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      </Tooltip>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <>{children}</>
      </Collapse>
    </>
  )
}

export default MenuListItemContainer
