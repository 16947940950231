import { WorkawareApi } from '../../apis/WorkawareApi'
import { MaintenanceScheduleDto, MaintenanceScheduleListDto } from '../dtos'

export class maintenanceSchedulesService {

  private _api: WorkawareApi;

  constructor(api: WorkawareApi) {
    this._api = api;
  }

  getList = () => {
    return new Promise<MaintenanceScheduleListDto[]>((resolve, reject) => {
      this._api.maintenanceSchedules.getList()
      .then(result => resolve(result))
      .catch(error => reject(error))
    });
  }

  get = (id: number) => {
    return new Promise<MaintenanceScheduleDto>((resolve, reject) => {
      this._api.maintenanceSchedules.get(id)
      .then(result => resolve(result))
      .catch(error => reject(error))
    });
  }

  create = (schedule: MaintenanceScheduleDto) => {
    return new Promise<MaintenanceScheduleDto>((resolve, reject) => {
      this._api.maintenanceSchedules.post(schedule)
      .then(result => resolve(result))
      .catch(error => reject(error))
    });
  }

  update = (schedule: MaintenanceScheduleDto) => {
    return new Promise<MaintenanceScheduleDto>((resolve, reject) => {
      this._api.maintenanceSchedules.put(schedule)
      .then(result => resolve(result))
      .catch(error => reject(error))
    });
  }

  delete = (id: number) => {
    return new Promise<MaintenanceScheduleDto>((resolve, reject) => {
      this._api.maintenanceSchedules.delete(id)
      .then(result => resolve(result))
      .catch(error => reject(error))
    });
  }
}