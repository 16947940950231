import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import lightLogo from 'assets/images/workaware-light.png'
import darkLogo from 'assets/images/workaware-dark.png'

const useStyles = makeStyles((theme) => ({
  logo: (props: any) => ({
    width: props.width ? `${props.width}px` : 'auto',
    height: 'auto',
  }),
}))
interface Props {
  width?: number
}
const Branding: React.FC<Props> = ({ width }) => {
  const theme = useTheme()
  const classes = useStyles({ width })

  return (
    <React.Fragment>
      <img src={theme.palette.type === 'light' ? lightLogo : darkLogo} className={classes.logo} alt="Company Logo" />
    </React.Fragment>
  )
}

export default Branding
