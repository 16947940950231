import uuid from 'uuid/v1'
import { WorkawareBaseClass } from './WorkawareBaseClass'

export class AssignmentModel extends WorkawareBaseClass {
  _className = 'AssignmentModel'
  public Id: number | undefined = undefined
  public UniqueID: string = uuid()
  public ParentAssignmentId: number | undefined = undefined
  public CompanyId: number | undefined = undefined
  public AssignedByUserId: number | undefined = undefined
  public AssignedToTeamId: number | undefined = undefined
  public AssignedToTeamType: string | undefined = undefined
  public AssignedToUserId: number | undefined = undefined
  public CompletedByUserId: number | undefined = undefined
  public Description: string = ''
  public DueDate: Date | undefined = undefined
  public CompletedOn: Date | undefined = undefined
  public AssignedOn: Date | undefined = undefined
  public EmailedOn: Date | undefined = undefined
  public ItemType?: string
}
