import React from 'react'
import { verticalHeader } from '../../components/style/shared'
import Drawer from '@material-ui/core/Drawer'
import { useIntl } from 'react-intl'
import { Dictionary } from 'types'
import SecurityModuleForm from 'containers/forms/SecurityModuleForm'
import { SecurityModel } from 'data/models/SecurityModel'

const containerStyle = {
  maxWidth: 500,
  minWidth: 300,
  margin: 20,
  marginLeft: 40,
}

interface Props {
  selectedModule?: SecurityModel
  setSelectedModule: (user?: SecurityModel) => void
  handleUpdate: (id: number, changes: Dictionary<any>, something?: boolean) => void
}
const SecurityModuleDrawer: React.FC<Props> = ({ selectedModule, setSelectedModule, handleUpdate }) => {
  const intl = useIntl()
  return (
    <Drawer anchor="right" open={selectedModule !== undefined} onClose={() => setSelectedModule(undefined)}>
      <div role="presentation" style={containerStyle}>
        <div style={verticalHeader(300)}>{intl.formatMessage({ id: 'user.details', defaultMessage: 'Security Modules' })}</div>
        {selectedModule && <SecurityModuleForm handleUpdate={handleUpdate} handleCancel={() => setSelectedModule(undefined)} item={selectedModule} />}
      </div>
    </Drawer>
  )
}

export default SecurityModuleDrawer
