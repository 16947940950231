import { FileTree, FileNode } from 'utils/FileTree'
import { CultureGroupModel } from 'data/models'

export const LIBRARY_FOLDERS_SET = 'workaware/library/LIBRARY_FOLDERS_SET'
export const LIBRARY_FOLDER_RENAMED = 'workaware/library/LIBRARY_FOLDER_RENAMED'
export const LIBRARY_FOLDER_CREATED = 'workaware/library/LIBRARY_FOLDER_CREATED'
export const LIBRARY_FOLDER_DELETED = 'workaware/library/LIBRARY_FOLDER_DELETED'
export const LIBRARY_FILE_DELETED = 'workaware/library/LIBRARY_FILE_DELETED'
export const LIBRARY_CHANGE_FOLDER = 'workaware/library/LIBRARY_CHANGE_FOLDER'
export const LIBRARY_FILE_ADDED = 'workaware/library/LIBRARY_FILE_ADDED'
export const LIBRARY_FILE_CULTURE_GROUP_JOINED = 'workaware/library/LIBRARY_FILE_CULTURE_GROUP_JOINED'
export const LIBRARY_FILE_CULTURE_GROUP_LEFT = 'workaware/library/LIBRARY_FILE_CULTURE_GROUP_LEFT'

export const libraryRenameFolder = (oldName, newName) => ({ type: LIBRARY_FOLDER_RENAMED, payload: { oldName, newName } })
export const libraryFileDeleted = (id) => ({ type: LIBRARY_FILE_DELETED, payload: id })
export const libraryChangeFolder = (id, path) => ({ type: LIBRARY_CHANGE_FOLDER, payload: { id, path } })
export const libraryFileAdded = (file: FileNode) => ({ type: LIBRARY_FILE_ADDED, payload: file })
export const libraryFolderCreated = (name: string) => ({ type: LIBRARY_FOLDER_CREATED, payload: name })
export const libraryFolderDeleted = (name: string) => ({ type: LIBRARY_FOLDER_DELETED, payload: name })
export const setLibraryFolders = (tree: FileTree) => ({ type: LIBRARY_FOLDERS_SET, payload: tree })
export const libraryFileCultureGroupJoined = (file: FileNode, group: CultureGroupModel) => ({ type: LIBRARY_FILE_CULTURE_GROUP_JOINED, payload: { file, group } })
export const libraryFileCultureGroupLeft = (file: FileNode) => ({ type: LIBRARY_FILE_CULTURE_GROUP_LEFT, payload: file })

interface State {
  tree?: FileTree
}

interface Action {
  type: string
  payload?: any
}

const initialState: State = {
  tree: undefined,
}

const libraryReducer = (state: State = initialState, action: Action) => {
  switch (action.type) {
    case LIBRARY_FILE_CULTURE_GROUP_JOINED:
      return { ...state, tree: state.tree?.joinFileCultureGroup(action.payload.file as FileNode, action.payload.group as CultureGroupModel) }

    case LIBRARY_FILE_CULTURE_GROUP_LEFT:
      return { ...state, tree: state.tree?.leaveFileCultureGroup(action.payload as FileNode) }

    case LIBRARY_FILE_ADDED:
    case LIBRARY_FOLDER_CREATED:
      return { ...state, tree: state.tree?.addFile(action.payload as FileNode) }

    case LIBRARY_FILE_DELETED:
      return { ...state, tree: state.tree?.removeFileById(action.payload as number) }

    case LIBRARY_CHANGE_FOLDER:
      return { ...state, tree: state.tree?.moveFileById(action.payload.id as number, action.payload.path as string) }

    case LIBRARY_FOLDER_DELETED:
      return { ...state, tree: state.tree?.removeFolder(action.payload as string) }

    case LIBRARY_FOLDERS_SET:
      return { ...state, tree: action.payload as FileTree }

    case LIBRARY_FOLDER_RENAMED:
      const payload: { oldName: string; newName: string } = action.payload
      return { ...state, tree: state.tree?.renameFolder(payload.oldName, payload.newName) }
    default:
      return state
  }
}

export default libraryReducer
