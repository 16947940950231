import { WorkawareApi } from 'apis/WorkawareApi'
import { transform, TransformMethods } from 'data/transform'
import { JourneyCheckPointModel } from '../models/JourneyCheckPointModel'


export class JourneyCheckPointService{
    private _api : WorkawareApi
    private _checkPointTransform :TransformMethods<JourneyCheckPointModel>

    constructor(api: WorkawareApi) {
        this._api = api
        this._checkPointTransform = transform<JourneyCheckPointModel>(JourneyCheckPointModel)
      }
      createJourneyCheckPoint =(journeyCheckpoints:JourneyCheckPointModel) =>
      {
        return new Promise<JourneyCheckPointModel>((resolve,reject) =>
        {
          this._api.journeyCheckPoints
              .post(journeyCheckpoints)
              .then((journeyCheckpoint) =>resolve(this._checkPointTransform.from(journeyCheckpoint)))
              .catch((error) =>reject(error))
        })
      }
    }