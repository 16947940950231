export interface Node {
  Id: number | undefined,
  parent: Node | undefined,
  children: Node[] | undefined,
};

export interface Tree extends Node {
  map: (mapFunction: (node: Node) => any) => void,
  _stopTraversal: boolean,
  _traverse: (traverseFunction: (node: Node) => void, node?: Node) => void,
  findOne: (findFunction: (node: Node) => boolean, foundFunction?: (node: Node) => void) => void,
  findAll: (findFunction: (node: Node) => boolean, foundFunction?: (nodes: Node[]) => void) => void
}

const createTree = (props: Node): Tree => {
  var tree: Tree = {
    ...props,
    map: (mapFunction) => {
      tree._traverse(n => {
        n = mapFunction(n)
      });
    },
    findOne: (findFunction, foundFunction = undefined) => {
      tree._stopTraversal = false;
      let found: Node | undefined = undefined;
      tree._traverse(n => {
        if (findFunction(n)) {
          found = n;
          tree._stopTraversal = true;
          if (foundFunction && found !== undefined) {
            foundFunction(found);
          }
        }
      });
      return found;
    },
    findAll: (findFunction, foundFunction = undefined) => {
      const found: Node[] = [];
      tree._traverse(n => {
        if (findFunction(n)) {
          found.push(n);
        }
      });
      if (found.length > 0 && foundFunction) {
        foundFunction(found);
      }
      return found;
    },
    _stopTraversal: false,
    _traverse: (traverseFunction, node: Node = tree) => {
      if (!tree._stopTraversal && node) {
        traverseFunction(node);
      }
      if (node.children) {
        node.children.forEach(n => {
          tree._traverse(traverseFunction, n);
        });
      }
    }
  };

  return tree;
}

export const listToTree = ([...list]: Node[], idProperty = 'id', parentIdProperty = 'parentId') => {
  var map = {};
  var root:Node = {
    Id: undefined,
    parent: undefined,
    children: []
  };
  for (let i = 0; i < list.length; i += 1) {
    map[list[i][idProperty]] = i;
    list[i].children = [];
    list[i].parent = undefined;
  }
  for (let i = 0; i < list.length; i += 1) {
    let node = list[i];
    var parent = list[map[node[parentIdProperty]]];
    if (node[parentIdProperty] && parent) {
      parent.children?.push(node);
      node.parent = parent;
    } else {
      node.parent = root;
      root.children?.push(node);
    }
  }
  return createTree(root);
};


export default Tree;
