import { Grid } from '@material-ui/core';
import Button from 'components/Buttons';
import { WaoCheckbox, WaoFormContainer, WaoText, WaoIntegerInput } from 'components/Forms';
import { Dictionary } from 'data/transform';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { RasterModel } from 'data/models/RasterModel';


interface Props {
  item: RasterModel,
  handleCreate?: (raster: RasterModel) => void,
  handleUpdate?: (id: number, changes: Dictionary<any>, something?: boolean) => void,
  handleCancel?: () => void
}
const UserEditForm = ({ item, handleCreate, handleUpdate, handleCancel }: Props) => {
  const [raster, setRaster] = React.useState<RasterModel>(Object.assign(new RasterModel(), item));
  const [modified, setModified] = React.useState<Dictionary<any>>({});
  const editing = Boolean(raster.Id);

  const dirty = () => 0 < Object.keys(modified).length;
  const handleChange = (name: string) => (value: any) => mutateState(name, value);
  const mutateState = (name: string, value: any) => {
    setModified({ ...modified, [name]: value });
    setRaster({ ...raster, [name]: value });
  };


  const handleSubmit = event => {
   
    event.preventDefault();
    editing
      ? handleUpdate && raster.Id && handleUpdate(raster.Id, modified)
      : handleCreate && handleCreate(raster);
  };

  return (
    <WaoFormContainer name="user-edit-form" submit={e => handleSubmit(e)}>


      <Grid container>
 
        <Grid item sm={12} md={9}>
          <WaoText name="Name" value={raster.Name} handleChange={handleChange} label={<FormattedMessage id="name" defaultMessage="Name" />} />
          <WaoText name="Description" value={raster.Description} handleChange={handleChange} label={<FormattedMessage id="description" defaultMessage="Description" />} />
          <WaoIntegerInput name="MinimumZoomVisible" value={raster.MinimumZoomVisible?.toString()} handleChange={handleChange} label={<FormattedMessage id="min-zoom" defaultMessage="Min Zoom Level" />} />
          <WaoIntegerInput name="MaximumZoomVisible" value={raster.MaximumZoomVisible?.toString()} handleChange={handleChange} label={<FormattedMessage id="max-zoom" defaultMessage="Max Zoom" />} />
          <WaoIntegerInput name="DisplayOrder" value={raster.DisplayOrder?.toString()} handleChange={handleChange} label={<FormattedMessage id="display_order" defaultMessage="Display Order" />} />
          <WaoText name="WMSServer" value={raster.WMSServer} handleChange={handleChange} label={<FormattedMessage id="wms-server" defaultMessage="WMS Server" />} />
          <WaoText name="MBTilesURL" value={raster.MBTilesURL} handleChange={handleChange} label={<FormattedMessage id="mb_tiles_urlL" defaultMessage="MB Tiles URL" />} />
          <WaoText name="CacheZipURL" value={raster.CacheZipURL} handleChange={handleChange} label={<FormattedMessage id="cache_zip_url" defaultMessage="Cache Zip URL" />} />

        </Grid>
      </Grid>
      <WaoCheckbox name="HasMBTiles" value={raster.HasMBTiles}  handleChange={handleChange} label="Has MB Tiles" />

      <WaoCheckbox name="DefaultVisibleOnMap" value={raster.DefaultVisibleOnMap}  handleChange={handleChange} label="Visible On Map" />

      <WaoCheckbox name="IsActive" value={raster.IsActive} handleChange={handleChange} label="Active" />

      <br /><br />
      <Button.SubmitButton disabled={!dirty()} label={
        editing
          ? <FormattedMessage id="update" defaultMessage="Update" />
          : <FormattedMessage id="create" defaultMessage="Create" />
      } />
    </WaoFormContainer>
  );
};

export default UserEditForm;
