export class TrackPositionDto {
  public UserId: number
  public SourceType: number
  public Latitude: number
  public Longitude: number
  public DateTime: Date
  public BatteryStatus: string
  public LocationAccuracy: number
  public BestName: string
  public PhotoURL: string
  // public  LocationAltitude  :number
  // public  LocationAltitudeAccuracy  :number
  // public  LocationHeading  :number
  // public  LocationSpeed  :number
  // public  PositionSourceId  :number
}
