import { WorkawareBaseClass } from './WorkawareBaseClass'

export class SecurityModel extends WorkawareBaseClass {
  _className = 'SecurityModel'

  public Id: number
  public CompanyId: number | undefined = undefined
  public ModuleNoAccessVisibilityType: number | undefined = undefined
  public ModuleId: number | undefined = undefined
  public CanUserAccess: boolean = false
  public CanManagerAccess: boolean = false
  public CanSeniorManagerAccess: boolean = false
  public CanExternalAuditorAccess: boolean = false
}
