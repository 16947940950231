const host = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`

export const activePdfSettings = {
  protocol: 'https',
  hostname: 'pdf.workaware.com',
  port: 62626,
  language: 'en',
}

export const apiSettings = {
  workaware: {
    base: 'https://api.workaware.com',
      //: 'http://localhost:5044',
      //: 'https://dev.workaware.com/server-1.4',
    cullIdsInPostRequests: true,
  },
  documents: {
    base: process.env.NODE_ENV === 'production'
      ? 'https://documents.workaware.com/api/v1'
      : 'https://documents.workaware.com/api/v1'
      //: 'http://localhost:4000/api/v1'
  },
  forms: {
    base: 'https://forms.workaware.com',
  },
}

export const oidcSettings = {
  client_id: '19E1882C8CDEA709C898149AEB62802EF48AF014',
  client_secret: 'not_a_secret',
  redirect_uri: `${host}/callback`,
  silent_redirect_uri: `${host}/entryPoint=silentRenew`,
  post_logout_redirect_uri: `${host}/bye`,
  response_type: 'code',
  scope: process.env.NODE_ENV === 'production'
    ? 'openid profile offline_access documents_api reporting_query'
    : 'openid profile offline_access documents_api reporting_query',
    // : 'openid profile offline_access',
  authority: process.env.NODE_ENV === 'production'
    ? 'https://id.workaware.com'  
    : 'https://id.workaware.com',
    // : 'https://localhost:5001',
    // : 'https://dev.workaware.com/identity-server',
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
  revokeAccessTokenOnSignout: true,
}

export const debugSettings = {
  showListenerMessages: false,
}

export const uiSettings = {
  pagination: {
    rowsOptions: [10, 20, 50, 100],
    defaultRows: 20,
  },
  drawerWidth: 240,
  metaDrawerWidth: 300,
  drawerWidthClosed: 55,
  background: 'white',
  header: {
    appBarHeight: 64,
    appBarHeightMobile: 55,
    foreground: 'white',
    background: 'white'
  }
};

export const apiKeys = {
  bing: 'EOEajKQAAekD7yBX6eSo~t1ug4VCikjD48MyBDZWm5Q~AoTxGHltjSwTAmYFYljhqg8VOsXBVeD_PLQX8bPAX_Oj3vxas0h4ZdR2YpgzbOg0'
}