import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { OidcProvider } from 'redux-oidc';
import { processSilentRenew } from 'redux-oidc';
import * as serviceWorker from './serviceWorker';
import RootElement from 'containers/root';
import store from 'utils/configureStore';
import userManager from 'utils/userManager';
import { getUrlVars } from 'utils/utilities';

// register the protocol handler
if (window.location.host === 'app.workaware.com' && navigator.registerProtocolHandler) {
  navigator.registerProtocolHandler("web+workaware",
    `https://app.workaware.com/external?link=%s`,
    "Workaware Link Handler");
}

const vars = getUrlVars({ 'entryPoint': 'default' });

userManager.startSilentRenew();

// process a ticket renewal
if (vars.entryPoint === 'silentRenew') {
  processSilentRenew();

  // main application entry point
} else {
  ReactDOM.render(
    <Provider store={store}>
      <OidcProvider store={store} userManager={userManager}>
        <RootElement />
      </OidcProvider>
    </Provider>,
    document.getElementById('root')
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
}