import React from 'react'
import clsx from 'clsx'
import { makeStyles, darken } from '@material-ui/core/styles'
import Fade from '@material-ui/core/Fade'
import { Box, Grid, IconButton } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'

const useStyles = makeStyles((theme) => ({
  element: {
    width: '100%',
    cursor: 'pointer',
    borderRadius: 5,
    backgroundColor: theme.palette.background.paper,
    margin: '0px 0px 10px 0px',
    color: theme.palette.text.primary,
    '&:hover': {
      boxShadow: theme.palette.type === 'light' 
        ? '1px 1px 0px #e4e4e4' 
        : '1px 1px 0px #383838',
      backgroundColor: theme.palette.action.hover,
      color: theme.palette.text.primary,
      '& > div > div > li > button': {
        color: theme.palette.type === 'light' 
          ? theme.palette.primary.contrastText 
          : theme.palette.text.primary,
      },
    },
  },
  elementSelected: {
    width: '100%',
    cursor: 'pointer',
    borderRadius: 5,
    borderStyle: 'dotted',
    borderWidth: 1,
    borderColor: darken(theme.palette.background.paper, 0.15),
    backgroundColor: darken(theme.palette.background.paper, 0.05),
    '&:hover': {
      backgroundColor: darken(theme.palette.background.paper, 0.08),
    },
  },
  emphasize: {
    borderRadius: 5,
    borderStyle: 'solid',
    borderColor: 'silver',
    borderWidth: 1,
  }
}))

interface Props {
  children?: Array<JSX.Element> | JSX.Element
  fadeOutTrigger?: boolean
  selected?: boolean
  handleClick?: () => void
  multiSelect?: boolean
  fadeTimeout?: { appear?: number; enter?: number; exit?: number }
  emphasize?: boolean
}

const ListItemBase: React.FC<Props> = (props) => {
  const {
    fadeOutTrigger,
    children,
    selected,
    handleClick,
    multiSelect = false,
    fadeTimeout = {
      enter: 100,
      exit: 500,
    },
  } = props
  const classes = useStyles()
  return (
    <Fade in={!fadeOutTrigger} timeout={fadeTimeout} unmountOnExit={true}>
      <Box boxShadow={props.emphasize ? 2 : 0} 
        className={
          clsx(
            selected ? classes.elementSelected : classes.element,
            props.emphasize && classes.emphasize) 
        }>
        <Grid container>
          {multiSelect && (
            <Grid item>
              <Grid container spacing={0} justify="center" direction="column" style={{ height: '100% ' }}>
                <Grid item>
                  <IconButton component="span" onClick={() => handleClick && handleClick()}>
                    {selected && <CheckIcon />}
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item xs onClick={() => handleClick && handleClick()}>
            {children}
          </Grid>
        </Grid>
      </Box>
    </Fade>
  )
}

export default ListItemBase
