import { ModuleNoAccessVisibilityType } from 'enums/ModuleNoAccessVisibilityType'
import { CompanyModuleAccessDto } from '../data/dtos/CompanyModuleAccessDto'
import { Module } from '../enums/Module'

export interface IModuleAccessProvider {
  getAccess: (forModule: Module) => CompanyModuleAccessDto
}
export class ModuleAccessProvider implements IModuleAccessProvider {
  private _companyModuleAccess: CompanyModuleAccessDto[]
  constructor(companyModuleAccess: CompanyModuleAccessDto[] = []) {
    this._companyModuleAccess = companyModuleAccess
  }
  getAccess(moduleType: Module): CompanyModuleAccessDto {
    const moduleItem = this._companyModuleAccess.find((i) => i.Module === moduleType)
    if (moduleItem) {
      return moduleItem
    }
    return {
      Module: moduleType,
      AccessAllowed: false,
      Visibility: ModuleNoAccessVisibilityType.GreyedOut,
    }
  }
}
