import { UserModel, FileModel } from '.'
import { WorkawareBaseClass } from './WorkawareBaseClass'

export class TeamMemberModel extends WorkawareBaseClass {
  _className = 'TeamMemberModel'

  public Id: number | undefined = undefined
  public OrganizationTeamHierarchyId: number | undefined = undefined
  public ParentOrganizationTeamMemberHierarchyId: number | undefined = undefined
  public CompanyId: number | undefined = undefined
  public UserId: number | undefined = undefined
  public Title: string | undefined = undefined
  public Comments: string | undefined = undefined
  public Color: string | undefined = undefined
  public Files: FileModel[] | undefined = undefined
  public user?: UserModel
}
