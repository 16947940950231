import { transform, TransformMethods } from 'data/transform'
import { WorkawareApi } from 'apis/WorkawareApi'
import { RasterModel } from 'data/models/RasterModel'

export class rasterService {
  private _api: WorkawareApi
  private _rasterTransform: TransformMethods<RasterModel>

  constructor(api: WorkawareApi) {
    this._api = api
    this._rasterTransform = transform<RasterModel>(RasterModel)
  }

  getRaster = (id: number) => {
    return new Promise<RasterModel[]>((resolve, reject) => {
      this._api.raster
        .get(id)
        .then((result) => resolve(this._rasterTransform.arrayFrom(result.value)))
        .catch((error) => reject(error))
    })
  }

  getAllRaster = () => {
    return new Promise<RasterModel[]>((resolve, reject) => {
      this._api.raster
        .all()
        .then((result) => resolve(this._rasterTransform.arrayFrom(result.value)))
        .catch((error) => reject(error))
    })
  }

  createRaster = (raster: RasterModel) => {
    return new Promise<RasterModel>((resolve, reject) => {
      this._api.raster
        .create(raster)
        .then((raster) => resolve(this._rasterTransform.from(raster)))
        .catch((error) => reject(error))
    })
  }

  updateRaster = (id: number, raster: RasterModel) => {
    return new Promise((resolve, reject) => {
      this._api.raster
        .update(id, raster)
        .then((raster) => resolve(this._rasterTransform.from(raster)))
        .catch((error) => reject(error))
    })
  }
}
