import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { reducer as oidcReducer, loadUser } from 'redux-oidc';
import messagingReducer from 'reducers/messagingReducer';
import cacheReducer, { CacheContainer } from 'reducers/cacheReducer';
import applicationReducers from 'reducers';
import userManager from 'utils/userManager';
import { Dictionary } from 'types';
import { CachedListContainer } from 'data/cache';

export const rootReducer = combineReducers({
  messaging: messagingReducer,
  oidc: oidcReducer,
  app: applicationReducers,
  cache: cacheReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

// const logger = store => next => action => {
//   console.log('initial state', store.getState());
//   console.log('dispatching', action);
//   let result = next(action);
//   console.log('next state', store.getState());
//   return result
// }

const configureTheStore = () => {
  const store = createStore(
    rootReducer,
    compose(applyMiddleware(...[
      // middleware
      //logger
    ]), ...[
      // enhancers
    ])
  );

  loadUser(store, userManager)
  return store;
};

const store = configureTheStore();

/** @deprecated Use redux hooks instead */
export const getCachedList = (name:string): CachedListContainer | undefined => store.getState().app.cachedLists[name];

/** @deprecated Use redux hooks instead */
export const getCachedLists = (): Dictionary<CachedListContainer> => store.getState().app.cachedLists;

/** @deprecated Use redux hooks instead */
export const getAccessToken = (): string => store.getState().oidc.user?.access_token || '';

/** @deprecated Use redux hooks instead */
export const getUser = () => store.getState().oidc.user;

/** @deprecated Use redux hooks instead */
export const getUserInformation = () => store.getState().app.user;

/** @deprecated Use redux hooks instead */
export const getTeamFilter = (): string => store.getState().app.user.teamFilter;

/** @deprecated Use redux hooks instead */
export const getLocale = (): string => store.getState().app.user.localeCode;

/** @deprecated Use redux hooks instead */
export const getCache = (): Dictionary<CacheContainer> => store.getState().cache;

export default store;
