import { WorkawareApi } from 'apis/WorkawareApi'
import { transform, TransformMethods } from 'data/transform'
import { FileModel, TeamModel, CultureGroupModel } from 'data/models'
import { FileNode } from 'utils/FileTree'

export class filesService {
  private _api: WorkawareApi
  private _fileTransform: TransformMethods<FileModel>

  constructor(api: WorkawareApi) {
    this._api = api
    this._fileTransform = transform<FileModel>(FileModel)
  }

  linkCultureGroup = (file: FileNode, group: CultureGroupModel) => {
    return new Promise<CultureGroupModel>((resolve, reject) => {
      if (!file?.id || !group?.Id) {
        return reject()
      }
      this._api.files.cultureGroups
        .link(file.id, group.Id)
        .then((result) => (result ? resolve(result) : reject()))
        .catch((error) => reject(error))
    })
  }

  unlinkCultureGroup = (file: FileNode) => {
    return new Promise<CultureGroupModel>((resolve, reject) => {
      if (!file?.id) {
        return reject()
      }
      this._api.files.cultureGroups
        .unlink(file.id)
        .then(() => resolve())
        .catch((error) => reject(error))
    })
  }

  getAllCultureGroups = () => {
    return new Promise<CultureGroupModel[]>((resolve, reject) => {
      this._api.files.cultureGroups
        .getAll()
        .then((result) => (result ? resolve(result.map((r) => ({ ...r, Name: r.Name || '', Id: r.Id || '' }))) : reject()))
        .catch((error) => reject(error))
    })
  }

  getCultureGroup = (id: string) => {
    return new Promise<CultureGroupModel>((resolve, reject) => {
      this._api.files.cultureGroups
        .get(id)
        .then((result) => (result ? resolve(result) : reject()))
        .catch((error) => reject(error))
    })
  }

  updateCultureGroup = (group: CultureGroupModel) => {
    return new Promise<CultureGroupModel>((resolve, reject) => {
      this._api.files.cultureGroups
        .update(group)
        .then((result) => (result ? resolve(result) : reject()))
        .catch((error) => reject(error))
    })
  }

  createCultureGroup = (name: string) => {
    const group = new CultureGroupModel()
    group.Name = name
    return new Promise<CultureGroupModel>((resolve, reject) => {
      this._api.files.cultureGroups
        .create(group)
        .then((result) => (result ? resolve(result) : reject()))
        .catch((error) => reject(error))
    })
  }

  deleteCultureGroup = (id: string) => {
    return new Promise((resolve, reject) => {
      this._api.files.cultureGroups
        .delete(id)
        .then(() => resolve())
        .catch((error) => reject(error))
    })
  }

  createFolder = (path: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      this._api.folders
        .create(path)
        .then((result: string) => resolve(result))
        .catch((error: any) => reject(error))
    })
  }

  deleteFolder = (path: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      this._api.folders
        .delete(path)
        .then(() => resolve())
        .catch((error: any) => reject(error))
    })
  }

  getAllFiles = (): Promise<Array<FileModel>> => {
    return new Promise((resolve, reject) => {
      this._api.files
        .getAll()
        .then((result) => {
          let files = this._fileTransform.arrayFrom(result)
          files.map((f) => f.Teams.map((t) => t as TeamModel))
          resolve(files)
        })
        .catch((error: any) => reject(error))
    })
  }

  renameFile = (oldName: string, newName: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      this._api.folders
        .rename(this._knockOffDocRoot(oldName), this._knockOffDocRoot(newName))
        .then(() => resolve())
        .catch((error: any) => reject(error))
    })
  }

  changeFileFolder = (id: number, newName: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      this._api.folders
        .changeFolder(id, newName)
        .then(() => resolve())
        .catch((error: any) => reject(error))
    })
  }

  pascalCase = (obj) => {
    var result = {}
    for (var name in obj) {
      result[name.charAt(0).toUpperCase() + name.slice(1)] = obj[name]
    }
    return result
  }

  uploadFile = (data: FormData, path: string): Promise<FileNode> => {
    return new Promise((resolve, reject) => {
      this._api.files
        .upload(data, path)
        .then((result: any) => {
          resolve(new FileNode(this.pascalCase(result) as FileModel))
        })
        .catch((error: any) => reject(error))
    })
  }

  deleteFile = (id: number): Promise<void> => {
    return new Promise((resolve, reject) => {
      this._api.files
        .delete(id)
        .then(() => resolve())
        .catch((error: any) => reject(error))
    })
  }

  downloadFileFromId = (id: number): Promise<Blob> => {
    return new Promise((resolve, reject) => {
      this._api.download
        .file(id)
        .then((result: Blob) => resolve(result))
        .catch((error: any) => reject(error))
    })
  }

  downloadFileFromObject = (file: FileNode): Promise<Blob> => {
    return new Promise((resolve, reject) => {
      if (file.cultureGroup) {
        this._api.download
          .fileInCultureGroup(file.cultureGroup)
          .then((result: Blob) => resolve(result))
          .catch((error: any) => reject(error))
      } else {
        this._api.download
          .file(file.id)
          .then((result: Blob) => resolve(result))
          .catch((error: any) => reject(error))
      }
    })
  }

  private _knockOffDocRoot = (path: string, separator: string = '/'): string => {
    path = path.trim()
    if (path[0] === separator) path = path.substring(1)
    const parts = path.split(separator)
    parts.shift()
    return separator + parts.join(separator)
  }
}
