import ContentWrapper from 'components/ContentWrapper'
import SecurityModuleList from 'components/lists/SecurityModulesList'
import TopNavigation from 'components/navigation/TopNavigation'
import SecurityModuleDrawer from 'containers/drawers/SecurityModuleDrawer'
import { SecurityModel } from 'data/models/SecurityModel'
import { useSecurityModuleService } from 'data/services'
import { createNotification } from 'factories/notificationFactory'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import { Dictionary } from 'types'


const SecurityPage: React.FC = () => {
  const intl = useIntl()
  const [modules, setModules] = React.useState<SecurityModel[]>([])
  const [selectedModules, setSelectedModules] = React.useState<SecurityModel | undefined>(undefined)
  const securityModuleServices = useSecurityModuleService()

  const refresh = (closeDrawer = true) => {
    securityModuleServices.getAllSecurityModules().then((result) => {
      closeDrawer && setSelectedModules(undefined)
      setModules(result)
    })
  }

  const handleUpdate = (id: number, data: Dictionary<any>) => {
    securityModuleServices.updateSecurityModule(id, data as SecurityModel).then(() => {
      createNotification.success(
        intl.formatMessage({
          id: 'update-success-user',
          defaultMessage: 'User Updated Successfully',
        })
      )
      refresh()
    })
  }

  React.useEffect(() => {
    refresh()
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Helmet>
        <title>Settings / Users</title>
        <meta name="description" content="company security settings" />
      </Helmet>

      <TopNavigation />

      <ContentWrapper>
        <SecurityModuleList securityModules={modules} handleSelect={(itm) => setSelectedModules(itm)} />
      </ContentWrapper>

      {<SecurityModuleDrawer setSelectedModule={setSelectedModules} selectedModule={selectedModules} handleUpdate={handleUpdate} />}
    </>
  )
}

export default SecurityPage
