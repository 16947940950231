export enum elementType {
  infoButton = 0,
  infoCard = 1,
  infoMessage = 2,
  infoButtonSimple = 3,
  pieGraph = 100,
  lineGraph = 101,
  barGraph = 102,
  participation = 103,
  outstandingAssignmentsList = 104,
  outstandingAssignmentsCount = 105,
  cmmsAssetOverdue = 110
}
export enum queryType {
  countMatchingTypeGroupedByField = 0,
  countAllGroupedByType = 1,
  totalDocumentsPerDayMatchedByTypes = 2,
  totalDocumentsMatchedByType = 3,
}
export enum parserType {
  scalar = 0,
  list = 1,
  pie = 2,
  perMonth = 3,
  flattenCountResultToObject = 100,
}

export enum ParticipationGroupCategory {
  UploadedBy = 0,
  Client = 1,
  JobClassification = 2,
  Department = 3,
  Location = 4,
  Division = 5,
  Priority = 6,
}

export enum ParticipationSortType {
  total = 0,
  totalReverse = 1,
  name = 2,
  nameReverse = 3
}
