export class CmmsOverdueAssetDto {
  public AbsoluteDatetime?: Date
  public AbsoluteHours?: number
  public AbsoluteOdometer?: number
  public AssetId: number
  public AssetMaintenanceScheduleId: number
  public AssetName: string
  public AssetSerialNumber?: string
  public CurrentAssetHoursReading?: number
  public CurrentAssetOdometerReading?: number
  public IsAbsoluteDatetime: boolean
  public IsAbsoluteHours: boolean
  public IsAbsoluteOdometer: boolean
  public IsOverdue: boolean
  public IsPeriodicDatetime: boolean
  public IsPeriodicHours: boolean
  public IsPeriodicOdometer: boolean
  public LastHours?: number
  public LastOdometer?: number
  public LastPeriodDatetime?: Date
  public MaintenanceName: string
  public NextHours?: number
  public NextOdometer?: number
  public NextPeriodDatetime?: Date
  public PeriodOdometer?: number
  public PeriodDatetime?: Date
  public PeriodHours?: number
}