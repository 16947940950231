import { WorkawareBaseClass } from './WorkawareBaseClass'
import { IListableItem } from 'containers/dialogs/SelectIListableItemDialog'
import { FileModel } from '.'

export class CultureGroupModel extends WorkawareBaseClass implements IListableItem {
  _className = 'CultureGroupModel'
  public Id: string = ''
  public Name: string = ''
  // optional properties used on the DTOs
  public FileCount?: number = 0
  public Files?: FileModel[] = []
}
