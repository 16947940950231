import { Client } from '@stomp/stompjs'
import { useMessagingServices } from 'data/services'
import { debugSettings } from 'etc/settings'
import React from 'react'
import { useDispatch } from 'react-redux'
import { messageReceived } from 'reducers/messagingReducer'
import { User } from 'oidc-client'
import { parseUrl } from 'utils/utilities'

interface Props {
  user?: User
}
const Listener = ({ user }: Props) => {
  const messagingServices = useMessagingServices()
  const dispatch = useDispatch()

  const showMessages = React.useMemo(() => {
    return debugSettings.showListenerMessages
  }, [])

  React.useEffect(() => {
    if (!user) return

    const topic = `/topic/company_${user.profile.company_id}`

    messagingServices.getConnectionString().then((parameters) => {
      const url = parseUrl(parameters.url)

      const client = new Client({
        brokerURL: `wss://${url?.host}:61619`,
        connectHeaders: {
          login: parameters.user,
          passcode: parameters.password,
        },
        debug: function(str) {
          //console.log(str);
        },
        onWebSocketError: (e) => console.error(e),
        reconnectDelay: 5000,
        heartbeatIncoming: 4000,
        heartbeatOutgoing: 4000,
      })

      client.onConnect = function(frame) {
        client.subscribe(topic, (message) => {
          dispatch(messageReceived(message))
        })
      }

      client.onStompError = function(frame) {
        console.error('Broker reported error: ' + frame.headers['message'])
        console.error('Additional details: ' + frame.body)
      }

      client.activate()
    })
    return () => {}
    // eslint-disable-next-line
  }, [user, showMessages, dispatch])

  return null
}

export default Listener
