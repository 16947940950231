import { WorkawareBaseClass } from './WorkawareBaseClass'
import { CompanyFormModel } from '.'
import { IListableItem } from 'containers/dialogs/SelectIListableItemDialog'

export class FormTemplateModel extends WorkawareBaseClass implements IListableItem {
  _className = 'FormTemplateModel'
  
  public Id: number = 0
  public Name: string = ''

  toCompanyForm(): CompanyFormModel {
    const form = new CompanyFormModel()
    form.Id = this.Id || -1
    form.Name = this.Name
    return form
  }
}