import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'

const useStyles = makeStyles((theme) => ({
  list: {
    height: 'calc(100% - 200)',
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: '0px 0px',
  },
}))

interface Props {
  ariaLabel?: string
  children?: Array<JSX.Element> | JSX.Element
}
const CustomList: React.FC<Props> = (props) => {
  const { ariaLabel, children } = props
  const classes = useStyles()

  return (
    <List aria-label={ariaLabel} className={classes.list}>
      {children}
    </List>
  )
}

export default CustomList
