import { WorkawareApi } from '../../apis/WorkawareApi'

export class avatarService {
  private _api: WorkawareApi

  constructor(api: WorkawareApi) {
      this._api = api
  }

  get = (relativeUrl: string) => {
    return new Promise<Blob>((reslove, reject) => {
      this._api.avatar.get(relativeUrl)
        .then(result => reslove(result))
        .catch(error => reject(error));
    });
  }
}