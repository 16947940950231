import React from 'react';
import { FormattedMessage } from 'react-intl';
import LinearProgress from '@material-ui/core/LinearProgress';

const LoadingIndicator = (props) => {
  return (
    <div {...props} style={{ width:140 }}>
      <FormattedMessage id="loading" defaultMessage="Loading..." />
      <LinearProgress />
    </div>
  );
};

export default LoadingIndicator;
