export class MaintenanceScheduleListDto {
    public Id: number
    public Name: string
    public Description: string
}

export class MaintenanceScheduleDto extends MaintenanceScheduleListDto {
    public IsActive: boolean
    public DuePeriodDatetime: boolean
    public PeriodDatetime?: number
    public DuePeriodHours: boolean
    public PeriodHours?: number
    public DuePeriodOdometer: boolean
    public PeriodOdometer?: number
    public DueAbsoluteDatetime: boolean
    public AbsoluteDatetime: Date
    public DueAbsoluteOdometer: boolean
    public AbsoluteOdometer?: number
    public DueAbsoluteHours: boolean
    public AbsoluteHours?: number
    public NotifySafetyManagers: boolean
    public NotificationDays: number
    public CreatedOn: Date
    public CreatedBy: string
}