import uuid from 'uuid/v1'
import { FormCompletionModel, FormTemplateModel } from '.'
import { WorkawareBaseClass } from './WorkawareBaseClass'

export class AssignmentItemModel extends WorkawareBaseClass {
  _className = 'AssignmentItemModel'
  public Id: number | undefined = undefined
  public UniqueID: string = uuid()
  public Template: FormTemplateModel | undefined = undefined
  public FormCompletion: FormCompletionModel | undefined = undefined
}
