import React from 'react'
import uuid from 'uuid/v1'
import { useDropzone } from 'react-dropzone'
import { FormattedMessage } from 'react-intl'
import { createNotification } from 'factories/notificationFactory'
import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import Tooltip from '@material-ui/core/Tooltip'
import { UserModel } from 'data/models'
import { useDownloadServices, useUserServices } from 'data/services'
import { useIsMounted } from 'utils/utilities'

const useStyles = makeStyles((theme) => ({
  avatar: (props: { size: number; swapColours: boolean }) => ({
    width: props.size,
    height: props.size,
    margin: 0,
    textTransform: 'uppercase',
    color: props.swapColours ? theme.palette.primary.main : theme.palette.primary.contrastText,
    background: props.swapColours ? theme.palette.primary.contrastText : theme.palette.primary.main,
    '&:hover': {
      background: theme.palette.primary.light,
    },
  }),
}))

interface Props {
  username?: string
  photoUrl?: string
  editing?: boolean
  photoUploaded?: (name: string) => void
  size?: number
  swapColours?: boolean
}
const UserAvatar: React.FC<Props> = ({ photoUrl, username, editing = false, photoUploaded = undefined, size = 40, swapColours = false }) => {
  const classes = useStyles({ size, swapColours })
  const [imageUrl, setImageUrl] = React.useState<string | undefined>(undefined)
  const downloadServices = useDownloadServices()
  const userServices = useUserServices()
  const isMounted = useIsMounted()

  React.useEffect(() => {
    if (isMounted.current) {
      ;(async () => {
        if (photoUrl && imageUrl === undefined) {
          const blob = await downloadServices.getUserFile(photoUrl)
          const url = (window.URL || window.webkitURL).createObjectURL(blob)
          setImageUrl(url)
        }
      })()
    }
    // eslint-disable-next-line
  }, [photoUrl, imageUrl])

  const { acceptedFiles, getRootProps, open, getInputProps } = useDropzone({
    multiple: false,
    noClick: true,
    noKeyboard: true,
    onDropAccepted: (files, e) => handleUpload(files[0]),
  })

  const getAvatarLetter = (user?: UserModel) => (username || '?')[0]

  const handleUpload = async (file) => {
    const data = new FormData()
    const name = `${uuid()}.png`
    data.append('file', file, name)
    await userServices.uploadPhoto(data, name)
    createNotification.success('File(s) uploaded successfully')
    acceptedFiles.length = 0
    acceptedFiles.splice(0, acceptedFiles.length)
    photoUploaded && photoUploaded(name)
  }

  if (editing)
    return (
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <Tooltip title={<FormattedMessage id="drop-user-avatar-image-here" defaultMessage="Drop User Avatar Image Here" />}>
          <Avatar variant="circle" onClick={() => open()} className={classes.avatar} style={{ cursor: 'pointer' }} src={imageUrl}>
            {getAvatarLetter()}
          </Avatar>
        </Tooltip>
      </div>
    )

  return (
    <Avatar variant="circle" className={classes.avatar} src={imageUrl}>
      {getAvatarLetter()}
    </Avatar>
  )
}

export default UserAvatar
