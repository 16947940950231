import { WorkawareApi } from 'apis/WorkawareApi'
import { TrackPositionDto } from 'data/dtos/TrackPositionDto'
import { transform, TransformMethods } from 'data/transform'

export class trackingCurrentPosition {
  private _api: WorkawareApi
  private _trackingCurrentPosition: TransformMethods<TrackPositionDto>

  constructor(api: WorkawareApi) {
    this._api = api
    this._trackingCurrentPosition = transform<TrackPositionDto>(TrackPositionDto)
  }

  getUserTrackingCurrentPositions = async () => {
    const result = await this._api.trackingCurrentPosition.getTrackingUser()
    return this._trackingCurrentPosition.arrayFrom(result)
  }

  getUserHistoricPositions = async (data) => {
    const result = await this._api.trackingCurrentPosition.getHistoricPositions(data)
    return this._trackingCurrentPosition.arrayFrom(result)
  }
   
}
