export class WorkawareBaseClass {
  protected _className: string = ''
  protected _stripProperties?: string[] = []

  public getInternalApplicationLink = () => {
    return WorkawareBaseClass.getInternalApplicationLink(this)
  }

  /**
   * Gets the URL to be used for internal linking
   *
   * @param opt either an instance of an object extending WorkawareBaseClass or the 'id' of a model
   * @param name if an id was given in 'opt' this is the type name of the model
   */
  public static getInternalApplicationLink = (opt: WorkawareBaseClass | number, modelName?: string) => {
    const id = (typeof opt === 'object') 
      ? (opt as any).Id
      : opt as number 
    const name = (typeof opt === 'object') 
      ? opt._className
      : modelName || ''
    switch (name) {
      case 'CompanyFormModel':
        return `/form/templates/${id}`
      case 'AnonymousFormModel':
        return `/form/templates/anonymous/${id}`
      case 'UserModel':
        return `/settings/user/${id}`
      default:
        return `/unknown/?error=${JSON.stringify({
          type: 'unknown routing object',
          parameters: { opt },
          stack: new Error().stack,
        })}`
    }
  }
}
