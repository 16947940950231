export const SETTINGS_RECEIVED = 'workaware/company/SETTINGS_RECEIVED';

export const settingsReceived = (settings) => ({ type: SETTINGS_RECEIVED, settings });

const initialState = {
  settings: null
};

const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case SETTINGS_RECEIVED:
      return Object.assign({}, state, { settings: action.settings });
    default:
      return state;
  }
};

export default companyReducer;
