import uuid from 'uuid/v1'
import { WorkawareBaseClass } from './WorkawareBaseClass'

export class FormCompletionModel extends WorkawareBaseClass {
  _className = 'FormCompletionModel'
  public Id: number | undefined = undefined
  public UniqueID: string | undefined = uuid()
  public AssociatedAssetSerial: string | undefined = undefined
  public CompanyFormId: number | undefined = undefined
  public CompanyId: number | undefined = undefined
  public DepartmentId: number | undefined = undefined
  public DivisionId: number | undefined = undefined
  public SubContractorId: number | undefined = undefined
  public ClientId: number | undefined = undefined
  public JobClassificationId: number | undefined = undefined
  public Date: Date | undefined = undefined
  public LastModifiedDate: Date | undefined = undefined
  public CreatedOnDate: Date | undefined = undefined
  public UploadedOn: Date | undefined = undefined
  public CompletedByUserId: number | undefined = undefined
  public UploadedByUserId: number | undefined = undefined
  public OwnedByUserId: number | undefined = undefined
  public LocationId: number | undefined = undefined
  public Latitude: number | undefined = undefined
  public Longitude: number | undefined = undefined
  public VerticalAccuracy: number | undefined = undefined
  public HorizontalAccuracy: number | undefined = undefined
  public VisibleInGeospatial: boolean = true
  public PriorityId: number | undefined = undefined
  public Subject: string | undefined = undefined
  public TagIdentifier: string | undefined = undefined
  public URL: string | undefined = undefined
  public FormRequestId: number | undefined = undefined
  public FormSubmissionDeviceVersion: string | undefined = undefined
  public FormSubmissionSource: number | undefined = undefined
  public CognitiveKeyPhrases: string | undefined = undefined
  public CognitiveSentimentScore: string | undefined = undefined
  public Filesize: number | undefined = undefined
  public FormVersion: number | undefined = undefined
  public GroupingIdentifier: number | undefined = undefined
}
