import React from 'react';
import ReactNotification, { store } from 'react-notifications-component';

const standardOptions = {
  title: 'Info',
  message: '',
  type: 'info',
  insert: 'top',
  container: 'bottom-left',
  animationIn: ['animated', 'fadeIn'],
  animationOut: ['animated', 'fadeOut'],
  dismiss: { duration: 3000 },
  dismissable: { click: true }
};

const _notify = (params, options={}) => {
  store.addNotification(Object.assign({}, standardOptions, options, params))
};

export const createNotification = {
  error:   (message, options={}) => _notify({ title: 'Error',   type: 'danger',  message: '' + message }, options),
  info:    (message, options={}) => _notify({ title: 'Info',    type: 'info',    message: '' + message }, options),
  warning: (message, options={}) => _notify({ title: 'Warning', type: 'warning', message: '' + message }, options),
  success: (message, options={}) => _notify({ title: 'Success', type: 'success', message: '' + message }, options),
};

export const Notifier = () => React.createElement(ReactNotification);
