export class RasterModel {
    _className = 'RasterModel'
    public  Id :number| undefined=undefined
    public  CompanyId :number| undefined=undefined
    public  Name :string| undefined=undefined
    public  Description :string| undefined=undefined
    public  DateTimeOffset:Date| undefined=undefined
    public  DateUploaded :Date| undefined=undefined
    public  MinimumZoomVisible :number| undefined=undefined
    public  MaximumZoomVisible :number| undefined=undefined
    public  IsActive :boolean| undefined=false
    public  HasMBTiles :boolean| undefined=false
    public  DisplayOrder :number| undefined=undefined
    public  WMSServer :string| undefined=undefined
    public  MBTilesURL :string| undefined=undefined
    public  CacheZipURL :string| undefined=undefined
    public  DefaultVisibleOnMap :boolean| undefined=false
    public  RowVersion :[]| undefined=undefined
}