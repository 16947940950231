import uuid from 'uuid/v1'
import { WorkawareBaseClass } from './WorkawareBaseClass'

export class TeamAttributeModel extends WorkawareBaseClass {
  _className = 'TeamAttributeModel'

  public Id: number | undefined = undefined
  public PicklistItemId: number | undefined = undefined
  public CompanyId: number | undefined = undefined
  public UniqueID: string | undefined = uuid()
  public OrganizationTeamHierarchyId: number | undefined = undefined
  public Overrides: boolean = false
  public IsInherited: boolean = false
}
