import { WorkawareApi } from 'apis/WorkawareApi';
import LoadingIndicator from 'components/LoadingIndicator';
import PdfTron from 'components/pdf/PdfTron';
import { createNotification } from 'factories/notificationFactory';
import { User } from 'oidc-client';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { getUrlVars } from 'utils/utilities';


interface Props {
  user?: User
}
const EditPdf = ({ user }: Props) => {
  const vars = getUrlVars({ tid: '', id: '' });
  const api = new WorkawareApi();
  const intl = useIntl();
  const [blob, setBlob] = React.useState<Blob | undefined>(undefined);
  const [working, setWorking] = React.useState(false);
  const [formId, setFormId] = React.useState<number | undefined>(undefined);
  const [templateId, setTemplateId] = React.useState<number | undefined>(undefined);

  React.useEffect(() => {
    if (user) {
      if (vars.id !== '' && formId === undefined) {
        setFormId(parseInt(vars.id));
      }
      if (vars.tid !== '' && templateId === undefined) {
        setTemplateId(parseInt(vars.tid));
      }
      if (blob === undefined) {
        if (formId !== undefined) {
          api.forms.detail.get(formId).then(blob => setBlob(blob));
        }
        else if (templateId !== undefined) {
          api.forms.templates.getPopulated(templateId).then(blob => setBlob(blob));
        }
      }
    }
    // eslint-disable-next-line
  }, [user, formId, templateId]);

  const handleSave = async (blob) => {
    setWorking(true);
    // are we creating a mew form completion
    if (formId === undefined && templateId !== undefined) {
      var completion = await api.forms.templates.createFormCompletionRecord(templateId);
      setFormId(completion.Id);
    }
    // make sure we have a usable form id
    if (completion.Id === undefined) {
      setWorking(false);
      createNotification.error(intl.formatMessage({
        id: 'document-saved-error',
        defaultMessage: 'Could not find the form ID'
      }));
      return;
    }
    // upload the document
    var bytes = await blob.arrayBuffer();
    api.forms.detail.post(completion.Id, bytes).then(() => {
      setWorking(false);
      createNotification.success(intl.formatMessage({
        id: 'document-saved-success',
        defaultMessage: 'Document Saved Successfully'
      }));
    });
  };

  return (
    <>
      <Helmet>
        <title>Workaware: PDF</title>
      </Helmet>
      {!blob && <LoadingIndicator />}
      {blob && <PdfTron
        handleSave={blob => handleSave(blob)}
        blob={blob}
        working={working}
        {...vars}
      />}
    </>
  );
};

export default EditPdf;
