export class UserGeneratedShapeModel {
  _className = 'UserGeneratedShapeModel'
  // mostly used for internal checks but very important to persist the values
  public _id: string
  public __COMPANYID__: number

  public id: string
  public Name: string
  public Type: number
  public Color: string
  public Comments: string
  public Points: {
    id: string
    Comments: string
    Latitude: number
    Longitude: number
    RelatedFormId?: number
  }[]
  public CreatedByUserId?: number
  public LengthMeters?: number
  public AreaSquareMeters?: number
  public RelatedFormId?: number
  public IsDraft: boolean = false
}
