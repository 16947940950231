import { WorkawareApi } from 'apis/WorkawareApi';
import { MessagingConnectionParameters } from 'data/dtos/MessagingConnectionParameters';

export class messagingService {

  private _api: WorkawareApi;

  constructor(api: WorkawareApi) {
    this._api = api;
  }

  getConnectionString = () => {
    return new Promise<MessagingConnectionParameters>((resolve, reject) => {
      this._api.messaging.serviceConnectionString()
        .then(result => resolve(result))
        .catch(error => reject(error));
    });
  }
}