import React from 'react'
import { WaoEmptyListPlaceholder } from 'components/Forms'
import CustomList from 'components/lists/CustomList'
import UserGeneratedListitem from 'components/list-items/UserGeneratedListitem'
import { UserGeneratedShapeModel } from 'data/models/UserGeneratedShapeModel'

interface Props {
  shapes: UserGeneratedShapeModel[]
  filterText?: string
  handleSelect?: (securityModule: UserGeneratedShapeModel) => void
  multiSelect?: boolean
  selectedShapes?: UserGeneratedShapeModel[]
}
const UserGeneratedShapesList: React.FC<Props> = ({ shapes, selectedShapes = [], handleSelect, multiSelect = false }) => {
  return (
    <>
      {shapes.length > 0 && (
        <CustomList ariaLabel="shape items">
          {shapes.map((item) => (
            <UserGeneratedListitem multiSelect={multiSelect} selected={selectedShapes.includes(item)} key={item.id} handleSelect={handleSelect} shape={item} />
          ))}
        </CustomList>
      )}

      {(!shapes || shapes.length === 0) && <WaoEmptyListPlaceholder />}
    </>
  )
}

export default UserGeneratedShapesList