import { WorkawareApi } from 'apis/WorkawareApi';
import { transform, TransformMethods } from 'data/transform';
import ICAL from 'ical.js';

export enum calendarFeedType {
  default = 1,
  assignments = 2,
  external = 100,
}

export class ExternalCalendar {
  public Id: string | undefined = undefined;
  public Name: string | undefined = undefined;
};

export interface CalendarEvent {
  title: string,
  start: Date,
  end: Date,
  allDay?: boolean
  resource?: any,
  type: number,
  description: string,
  attachments: Array<string>,
  id: string,
  assignmentId : number | undefined,
  calenderId : string | undefined
};

interface CustomProperties {
  assignmentId : number | undefined
};

/**
 * The ICAL Library is a bit messy so we'll keep it contained in this wrapper class
 */
export class CalendarContainer {

  private parsedData: any;
  private type: number = calendarFeedType.default;
  private calendar? : ExternalCalendar
  constructor(data: string, type: number = calendarFeedType.default, forCalendar : ExternalCalendar | undefined = undefined) {
    this.type = type;
    if (data) {
      this.parsedData = ICAL.parse(data);
    }    
    this.calendar = forCalendar
  }

  getRootComponent = () => {
    return new ICAL.Component(this.parsedData || '');
  }

  getCustomProperties = (event: any) => {
    //event is an unprocessed vevent's jCal property
    //which looks something like ['vevent', [(property name, property type, property value)], ...]
    //we want to find any of our custom properties in there
    if(event === undefined) {
      return undefined;
    }
    if(!Array.isArray(event[1])) {
      return undefined;
    }
    let result : CustomProperties = {
      assignmentId : undefined
    };
    event[1].forEach((e : Array<any>) => {
      if(typeof e[0] === 'string' && "x-workaware-assignment".localeCompare(e[0], undefined, { sensitivity: 'base'}) === 0) {
        result.assignmentId = Number(e[3]);
        if(result.assignmentId === Number.NaN) {
          result.assignmentId = undefined;
        }
      }
    })
    return result;
  }
  getUsableEvents = () => {
    const calendarEvents: CalendarEvent[] = [];
    const events = this.getRootComponent().getAllSubcomponents('vevent');
    events.forEach((e: any) => {
      var event = new ICAL.Event(e);
      var customProperties = this.getCustomProperties(e.jCal);
      calendarEvents.push({
        title: event.summary,
        start: event.startDate.toJSDate(),
        end: event.endDate.toJSDate(),
        type: this.type,
        id: event.uid,
        description: event.description,
        attachments: [],
        assignmentId: customProperties?.assignmentId,
        calenderId: this.calendar?.Id
      })
    });
    return calendarEvents;
  };
}

export class calendarService {

  private _api: WorkawareApi;
  private _transformCalendar: TransformMethods<ExternalCalendar>;
  constructor(api: WorkawareApi) {
    this._api = api;
    this._transformCalendar = transform<ExternalCalendar>(ExternalCalendar);
  }

  externalFeeds = () => {
    return new Promise<Array<ExternalCalendar>>((resolve, reject) => {
      this._api.calendar.feed.list()
        .then(result => resolve(this._transformCalendar.arrayFrom(result)))
        .catch(error => reject(error));
    });
  }
  
  external = (from: ExternalCalendar) => {
    return new Promise<CalendarContainer>((resolve, reject) => {
      let id = from.Id
      if(undefined === id) {
        reject(new Error("Invalid external calendar"));
      }
      this._api.calendar.feed.external(id ?? "")
        .then(result => resolve(new CalendarContainer(result, calendarFeedType.external, from)))
        .catch(error => reject(error));
    });
  }

  allAssignments = () => {
    return new Promise<CalendarContainer>((resolve, reject) => {
      this._api.calendar.feed.allAssignments()
        .then(result => resolve(new CalendarContainer(result, calendarFeedType.assignments)))
        .catch(error => reject(error));
    });
  }

  alIncompleteAssignments = () => {
    return new Promise<CalendarContainer>((resolve, reject) => {
      this._api.calendar.feed.allIncompleteAssignments()
        .then(result => resolve(new CalendarContainer(result, calendarFeedType.assignments)))
        .catch(error => reject(error));
    });
  }

  myAssignments = () => {
    return new Promise<CalendarContainer>((resolve, reject) => {
      this._api.calendar.feed.myAssignments()
        .then(result => resolve(new CalendarContainer(result, calendarFeedType.assignments)))
        .catch(error => reject(error));
    });
  }

  myIncompleteAssignments = () => {
    return new Promise<CalendarContainer>((resolve, reject) => {
      this._api.calendar.feed.myIncompleteAssignments()
        .then(result => resolve(new CalendarContainer(result, calendarFeedType.assignments)))
        .catch(error => reject(error));
    });
  }
}