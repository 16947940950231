import React from 'react'
import { WaoEmptyListPlaceholder } from 'components/Forms'
import CustomList from 'components/lists/CustomList'
import SecurityModuleListItem from 'components/list-items/SecurityModuleListItem'
import { SecurityModel } from 'data/models/SecurityModel'

interface Props {
  securityModules: SecurityModel[]
  filterText?: string
  selectedUsers?: SecurityModel[]
  handleSelect?: (securityModule: SecurityModel) => void
  multiSelect?: boolean
  selectedSecurityModules?: SecurityModel[]
}
const SecurityModuleList: React.FC<Props> = ({ securityModules, selectedSecurityModules = [], handleSelect, multiSelect = false }) => {
  return (
    <>
      {securityModules.length > 0 && (
        <CustomList ariaLabel="module items">
          {securityModules.map((item) => (
            <SecurityModuleListItem multiSelect={multiSelect} selected={selectedSecurityModules.includes(item)} key={item.Id} handleSelect={handleSelect} securityModule={item} />
          ))}
        </CustomList>
      )}

      {(!securityModules || securityModules.length === 0) && <WaoEmptyListPlaceholder />}
    </>
  )
}

export default SecurityModuleList
