import React from 'react';

class ErrorBoundary extends React.Component {
  state = {
    error: null
  };
 
  static getDerivedStateFromError(error) {
    return {
      error: error.message
    };
  }
 
  componentDidCatch(error, errorInfo) {
    console.error('Something bad happened!', error, errorInfo);
  }
 
  render() {
    if (this.state.error) {
      return (
        <div>
          <h1>Something went wrong :(</h1>
          <p>
            {
              this.state.error
            }
          </p>
        </div>
      )
    }
    return this.props.children;
  }
}

export default ErrorBoundary;