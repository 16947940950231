import { WorkawareApi } from '../../apis/WorkawareApi';
import { KeyValueDto } from '../dtos/KeyValueDto';

export class formStateTemplatesService {
    private _api: WorkawareApi;

    constructor(api: WorkawareApi) {
        this._api = api;
    }

    getAll = () => new Promise<KeyValueDto[]>((resolve, reject) => {
        this._api.forms.stateFlowTemplates.getAll()
            .then(result => {
                const tempformStateTemplates: KeyValueDto[] = [];

                if (result) {
                    Object.keys(result).forEach(i => {
                        const tempformStateTemplate: KeyValueDto = {
                            Key: i,
                            Value: result[i]
                        }

                        tempformStateTemplates.push(tempformStateTemplate);
                    });
                }

                resolve(tempformStateTemplates);
            })
            .catch(error => reject(error));
    });
}