import { UserModel } from 'data/models'
import { IUserState } from 'types/reducers'

export const USER_LOADED = 'workaware/user/USER_LOADED'
export const USER_UNLOADED = 'workaware/user/USER_UNLOADED'
export const SET_TEAM_FILTER = 'workaware/user/SET_TEAM_FILTER'
export const SET_LOCALE = 'workaware/user/SET_LOCALE'
export const SET_PDF_VIEWER = 'workaware/user/SET_PDF_VIEWER'
export const TOGGLE_THEME = 'workaware/user/TOGGLE_THEME'
export const TOGGLE_USE_CACHE = 'workaware/user/TOGGLE_USE_CACHE'
export const USER_MODULE_ACCESS_LOADED = 'workaware/user/USER_MODULES_LOADED'
export const USER_MODULE_ACCESS_CLEARED = 'workaware/user/USER_MODULES_CLEARED'

export const userLoaded = (user) => ({ type: USER_LOADED, payload: user })
export const userUnloaded = () => ({ type: USER_UNLOADED })
export const setTeamFilter = (teamId) => ({ type: SET_TEAM_FILTER, payload: teamId })
export const setLocale = (localeCode) => ({ type: SET_LOCALE, payload: localeCode })
export const toggleTheme = () => ({ type: TOGGLE_THEME })
export const toggleUseCache = () => ({ type: TOGGLE_USE_CACHE })
export const userModuleAccessLoaded = (access) => ({ type: USER_MODULE_ACCESS_LOADED, payload: access })
export const userModuleAccessCleared = () => ({ type: USER_MODULE_ACCESS_CLEARED })

const key = 'workaware-user-settings'

const getInitialState = (): IUserState => {
  return Object.assign(
    {},
    {
      current: null,
      theme: 'light',
      useCache: false,
      localeCode: 'en',
      teamFilter: '-1', // no filter
      access: [],
    },
    JSON.parse(localStorage.getItem(key) ?? '{}')
  )
}

const setAndSave = (state) => (data) => {
  const obj = Object.assign({}, state, data)
  const { current, ...settings } = obj
  localStorage.setItem(key, JSON.stringify(settings))
  return obj
}

const userReducer = (state: IUserState = getInitialState(), action): IUserState => {
  const set = setAndSave(state)
  switch (action.type) {
    case SET_PDF_VIEWER:
      return set({ pdfViewer: action.payload })
    case SET_TEAM_FILTER:
      return set({ teamFilter: action.payload.toString() || '' })
    case SET_LOCALE:
      return set({ localeCode: action.payload || '' })
    case USER_LOADED:
      const user: UserModel = Object.assign(new UserModel(), action.payload)
      return set({ current: user })
    case USER_UNLOADED:
      return set({ current: null })
    case TOGGLE_THEME:
      return set({ theme: state.theme === 'light' ? 'dark' : 'light' })
    case TOGGLE_USE_CACHE:
      return set({ useCache: !state.useCache })
    case USER_MODULE_ACCESS_LOADED:
      return set({ access: [...action.payload] })
    case USER_MODULE_ACCESS_CLEARED:
      return set({ access: [] })
    default:
      return state
  }
}

export default userReducer
