import { requestBuilder } from 'utils/request'
import { apiSettings } from 'etc/settings'
import { GraphDataRequestDto, GraphDataResponseDto, LiveReport } from 'components/liveReporting/types'
import { LiveReportComponentDto } from 'data/dtos/LiveReportComponentDto'

const request = requestBuilder(apiSettings.documents)

export class DocumentsApi {
  public live = {
    report: (id: string): Promise<LiveReport> => request(`/report/${id}`),
    list: (): Promise<LiveReport[]> => request(`/reports`),
    create: (data: any): Promise<LiveReport> => request('/report', { method: 'post', data }),
    update: (data: any): Promise<LiveReport> => request('/report', { method: 'put', data }),
    delete: (id: string): Promise<void> => request(`/report/${id}`, { method: 'delete' }),
    addComponent: (reportId: string, dto: LiveReportComponentDto): Promise<void> => request(`/report/${reportId}/component`, { method: 'post', data: dto }),
    removeComponent: (reportId: string, id: string): Promise<void> => request(`/report/${reportId}/component/${id}`, { method: 'delete' }),
    saveComponentOptions: (reportId: string, id: string, optionsJson: string): Promise<void> => request(`/report/${reportId}/component/${id}/options`, { method: 'put', data: { options: optionsJson } }),
    saveComponentLabel: (reportId: string, id: string, label: string): Promise<void> => request(`/report/${reportId}/component/${id}/label`, { method: 'put', data: { label } }),
    saveLayout: (reportId: string, layoutJson: string): Promise<void> => request(`/report/${reportId}/layout`, { method: 'put', data: { layout: layoutJson } }),
  }

  public liveData = {
    getBarGraphData: (dto: GraphDataRequestDto): Promise<GraphDataResponseDto> => request(`/reporting/data/bargraph`, { method: 'post', data: dto }),
    getPieGraphData: (dto: GraphDataRequestDto): Promise<GraphDataResponseDto> => request(`/reporting/data/piegraph`, { method: 'post', data: dto }),
  }

  public documents = {
    aggregate: (json: string): Promise<any> => request('/documents/aggregate', { method: 'POST', data: json.replace(/\s+/g, '') }, { 'Content-Type': 'text/plain' }),
  }
}
