import { WorkawareApi } from 'apis/WorkawareApi'
import { UserShapeModel } from 'data/models/UserShapeModel'

export class userShapesService {
  private _api: WorkawareApi

  constructor(api: WorkawareApi) {
    this._api = api
  }

  getUserShapes = async () => {
    const result = await this._api.userShapes.getUserShapes()
    return result.results as UserShapeModel[]
  }
}
