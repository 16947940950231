const UploadTypes = {
  'CompanyFiles': {
    value: 1,
    name: 'companyfiles'
  },
  'UserFiles': {
    value: 2,
    name: 'userfiles'
  },
  'Forms': {
    value: 3,
    name: 'forms/templates'
  },
  'CompletedPDFForm': {
    value: 4,
    name: 'forms/completedforms'
  },
  'CompanyInternalFile': {
    value: 5,
    name: 'internalfiles'
  },
  'ReportTemplate': {
    value: 6,
    name: 'reports'
  },
  'ArchivedForm': {
    value: 7,
    name: 'forms/archivedforms'
  },
  'SupportLogfiles': {
    value: 8,
    name: 'supportfiles'
  },
  'Invoices': {
    value: 9,
    name: 'invoices'
  },
  'UserFilePhotos': {
    value: 10,
    name: 'userfiles/photos'
  },
  'UserFileCertificates': {
    value: 11,
    name: 'userfiles/certificate'
  },
};

export default UploadTypes;

/*

        [EnumMember(Value = "userfiles/driverlicenses")] //
        UserFileDriverLicenses = 12,

        [EnumMember(Value = "userfiles/licenses")] //
        UserFileLicenses = 13,

        [EnumMember(Value = "userfiles/signatures")] //
        UserFileSignatures = 14,

        [EnumMember(Value = "logos")]
        CompanyFileLogos = 15,

        [EnumMember(Value = "cameraevents")]
        CameraEvents = 16,

        [EnumMember(Value = "cmms/photos")]
        CMMSPhotos = 17,

        [EnumMember(Value = "cmms/documents")]
        CMMSDocuments = 18,

        [EnumMember(Value = "imports")]
        ImportAssets = 19,

        [EnumMember(Value = "imports")]
        ImportPeople = 20,

        [EnumMember(Value = "imports")]
        ImportUpdatedAssets = 21,

        [EnumMember(Value = "shapefiles")]
        Shapefiles = 22,

        [EnumMember(Value = "companyfiles")] //documents go into companyfiles, but the record needs to be manually saved
        Documents = 23,

        [EnumMember(Value = "geospatialmarkers")]
        GeospatialMarkers = 24,

        [EnumMember(Value = "picklist")]
        Picklist = 25,

        [EnumMember(Value = "rasters")]
        Rasters = 26,

        [EnumMember(Value = "forms/templates")]
        FormIcon = 27,

        [EnumMember(Value = "imports")]
        ImportUpdatePeople = 28,

        [EnumMember(Value = "imports")]
        ImportPeopleCertificates =29,

        [EnumMember(Value = "imports")]
        ImportUpdatePeopleCertificates = 30,

        [EnumMember(Value = "partnerlogos")]
        CompanyFilePartnerLogos = 31,
*/