import { AssetMaintenanceDto, AssetDocumentDto, AssetCheckinDto, AssetPhotoDto } from '../../data/dtos';

export class AssetListingDto {
    public Id: number
    public Name: string
    public Description: string
    public IsActive: boolean
}

export class AssetDto extends AssetListingDto {
    public ParentAssetId?: number
    public CreatedBy: string
    public CreatedOn?: Date
    public AssetCategoryId?: number
    public Code: string
    public Make: string
    public Model: string
    public SerialNumber: string
    public Barcode: string
    public Odometer?: number
    public OdometerUnitId?: number
    public Price?: number
    public Location: string
    public BarcodeTypeId?: number
    public MachineHours?: number
    public AssignedOperator: string
    public PrimaryPhotoId?: number
    public Photos: Array<AssetPhotoDto> = []
    public Maintenances: Array<AssetMaintenanceDto> = []
    public Documents: Array<AssetDocumentDto> = []
    public Checkins: Array<AssetCheckinDto> = []
}