export enum SecurityModules {
  ParticipationDashboard = 1,
  Dashboard = 2,
  Calendar = 3,
  ScheduledItems = 4,
  IncidentInvestigation = 5,
  DocumentLibrary = 6,
  CameraEvents = 7,
  ManualEntries = 8,
  BlankForms = 9,
  CompletedForms = 10,
  FormsPendingUpload = 11,
  FormsSearch = 12,
  People = 13,
  OrganizationChart = 14,
  LocationTracking = 15,
  JourneyManagement = 16,
  TrainingMatrix = 17,
  OrientationAndCourses = 18,
  CMMSAssets = 19,
  CMMSMaintenanceDashboard = 20,
  InteractiveCharts = 21,
  ComputerSettings = 22,
  CompanySettings = 23,
  Logout = 24,
  Exit = 25,
  Geospatial = 26,
  Reports = 27,
  DirectMessages = 28,
  Assignments = 29,
  LMSCourses = 30,
}
