import { combineReducers } from 'redux';
import companyReducer from './companyReducer';
import libraryReducer from './libraryReducer';
import userReducer from './userReducer';
import teamsReducer from './teamsReducer';
import cachedListReducer from './cachedListReducer';
import stateReducer from './stateReducer';
import reportingReducer from './reportingReducer';

const appReducer = combineReducers({
  library: libraryReducer,
  company: companyReducer,
  user: userReducer,
  teams: teamsReducer,
  cachedLists: cachedListReducer,
  state: stateReducer,
  reporting: reportingReducer,
});

export type AppState = ReturnType<typeof appReducer>;

export default appReducer;