import store from 'utils/configureStore';
import messages_en from "translations/en.json";
import messages_de from "translations/de.json";
import messages_fr from "translations/fr.json";
import { createIntl, createIntlCache } from 'react-intl';

export const getLocale = localeCode => {
  let locale = locales.find(l => l.code === localeCode);
  if (!locale) {
    locale = locales[0]; // default to CA
  }
  return locale;
};

export const locales = [{
  code: 'en',
  flagCode: 'CA',
  name: "English",
  messages: messages_en,
},{
  code: 'de',
  flagCode: 'DE',
  name: "Deutsche",
  messages: messages_de,
},{
  code: 'fr',
  flagCode: 'FR',
  name: "Français",
  messages: messages_fr,
}];

export const getIntlObject = () => {
  const state = store.getState();
  const locale = getLocale(state.app.user.localeCode);
  return createIntl({ locale: locale.code, messages: locale.messages }, createIntlCache());
};