import React, { FunctionComponent } from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formIcon: {
    width: '40px',
    height: '55px'
  }
})
);

const IconBase: FunctionComponent<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} style={{ width: '20px', height: '20px' }}>
      {props.children}
    </SvgIcon>
  );
}

export function HomeIcon(props: SvgIconProps) {
  return (
    <IconBase {...props}>
      <path fillRule="evenodd" d="M503.8 142.408h4.746v-8.425h5.894v8.425h4.76a.761.761 0 0 0 .758-.758v-9.382-.092h1.8a1 1 0 0 0 .93-1.369 2.718 2.718 0 0 0-.683-1.007l-9.374-8.433a1.566 1.566 0 0 0-2.237 0l-9.384 8.433a2.727 2.727 0 0 0-.688 1.007 1 1 0 0 0 .932 1.369h1.793v9.474a.763.763 0 0 0 .753.758z" transform="translate(-500.249 -120.894)" />
    </IconBase>
  );
}

export function ReportsIcon(props: SvgIconProps) {
  return (
    <IconBase {...props}>
      <path fillRule="evenodd" d="M67.119 486.442v-1.7h1.939v-17.866h5.013v17.866h1.86V477h5.013v7.741h1.941v-12.87h5.025v12.87h1.939v1.7zm21.389-23.4a16.771 16.771 0 0 0-.769 3.9 1.15 1.15 0 0 1-.2.374.5.5 0 0 1-.3.156.143.143 0 0 1-.1-.033l-1.072-1.07-6.15 7.049a.71.71 0 0 1-.873.018l-5.9-10.015-4.365 3.135a.707.707 0 0 1-.964.24.7.7 0 0 1-.244-.958l4.769-3.8a.692.692 0 0 1 .469-.325.715.715 0 0 1 .558.128l6.085 10.154 5.626-6.623-.676-.674a.636.636 0 0 1-.089-.1c-.055-.106.041-.276.14-.374a.634.634 0 0 1 .246-.148l3.734-1.322h.03c.16-.043-.016 0 .02.038a.3.3 0 0 1 .037.262z" transform="translate(-67.119 -461.693)" />
    </IconBase>
  );
}

export function OrganizationIcon(props: SvgIconProps) {
  return (
    <IconBase {...props}>
      <path d="M27.128 20.095h-2.009v-4.019a1 1 0 0 0-1-1h-9.048V8.038h5.024a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1H8.038a1 1 0 0 0-1 1v6.033a1 1 0 0 0 1 1h5.024v7.033H4.019a1 1 0 0 0-1 1V20.1H1a1 1 0 0 0-1 1v6.029a1 1 0 0 0 1 1h6.033a1 1 0 0 0 1-1V21.1a1 1 0 0 0-1-1H5.024v-3.019h8.038v3.014h-2.01a1 1 0 0 0-1 1v6.029a1 1 0 0 0 1 1h6.029a1 1 0 0 0 1-1V21.1a1 1 0 0 0-1-1h-2.01v-3.019h8.038v3.014H21.1a1 1 0 0 0-1 1v6.029a1 1 0 0 0 1 1h6.029a1 1 0 0 0 1-1V21.1a1 1 0 0 0-1.001-1.005z" transform="scale(0.90)" />
    </IconBase>
  );
}

export function LibraryIcon(props: SvgIconProps) {
  return (
    <IconBase {...props}>
      <path d="M367.1 182.758a2.063 2.063 0 0 1 .261 1.877l-4.007 13.2a3.025 3.025 0 0 1-1.116 1.566 2.9 2.9 0 0 1-1.783.633H347a3.581 3.581 0 0 1-2.165-.777 4.1 4.1 0 0 1-1.45-1.918 2.637 2.637 0 0 1-.03-1.849c0-.041.015-.169.043-.395s.049-.4.059-.541a.829.829 0 0 0-.043-.312.739.739 0 0 1-.044-.283.925.925 0 0 1 .117-.306 3.1 3.1 0 0 1 .242-.344 3.6 3.6 0 0 0 .24-.342 10.1 10.1 0 0 0 .656-1.333 8.162 8.162 0 0 0 .437-1.335 1.19 1.19 0 0 0 .007-.437 1.252 1.252 0 0 1-.007-.408 1.225 1.225 0 0 1 .247-.409 2.338 2.338 0 0 0 .248-.334 9.175 9.175 0 0 0 .613-1.341 5.968 5.968 0 0 0 .363-1.312 2.053 2.053 0 0 0-.036-.465c-.035-.224-.031-.36.007-.408a1.19 1.19 0 0 1 .321-.445q.264-.254.321-.325a6.137 6.137 0 0 0 .62-1.234 5.577 5.577 0 0 0 .4-1.407 1.225 1.225 0 0 0-.043-.37.97.97 0 0 1-.031-.386.9.9 0 0 1 .133-.265c.067-.1.155-.207.261-.332s.189-.229.248-.307a3.5 3.5 0 0 0 .24-.442c.082-.182.154-.352.219-.511s.14-.335.233-.526a2.376 2.376 0 0 1 .284-.465 1.74 1.74 0 0 1 .386-.342.955.955 0 0 1 .524-.168 3.057 3.057 0 0 1 .692.079l-.015.044a3.956 3.956 0 0 1 .743-.132h11.089a1.92 1.92 0 0 1 1.663.817 2.084 2.084 0 0 1 .261 1.894l-3.993 13.2a5.68 5.68 0 0 1-1.042 2.237 2.678 2.678 0 0 1-1.872.5h-12.658a.641.641 0 0 0-.554.22.664.664 0 0 0-.015.626q.35 1.023 2.1 1.021h13.451a1.615 1.615 0 0 0 .817-.227 1.036 1.036 0 0 0 .51-.6l4.372-14.383a2.4 2.4 0 0 0 .074-.832 1.944 1.944 0 0 1 .857.634zm-16.717 3.758a.388.388 0 0 0 .03.329.32.32 0 0 0 .291.138h8.862a.614.614 0 0 0 .372-.138.652.652 0 0 0 .24-.329l.306-.932a.378.378 0 0 0-.028-.327.322.322 0 0 0-.293-.14H351.3a.616.616 0 0 0-.372.14.645.645 0 0 0-.242.327zm1.21-3.73a.378.378 0 0 0 .03.327.314.314 0 0 0 .291.138h8.862a.6.6 0 0 0 .37-.138.642.642 0 0 0 .242-.327l.306-.934a.378.378 0 0 0-.03-.327.317.317 0 0 0-.291-.14h-8.86a.612.612 0 0 0-.372.14.652.652 0 0 0-.242.327z" transform="translate(-343.206 -177.656)" />
    </IconBase>
  );
}
export function JourneyIcons(props:SvgIconProps)
{
  return(
    <IconBase {...props}>
      <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9z"/><circle cx="12" cy="9" r="2.5"/>
    </IconBase>
  )
}

export function LocationTrackingIcon (props:SvgIconProps)
{
  return(
    <IconBase {...props}>
    <path d="M12 2c2.131 0 4 1.73 4 3.702 0 2.05-1.714 4.941-4 8.561-2.286-3.62-4-6.511-4-8.561 0-1.972 1.869-3.702 4-3.702zm0-2c-3.148 0-6 2.553-6 5.702 0 3.148 2.602 6.907 6 12.298 3.398-5.391 6-9.15 6-12.298 0-3.149-2.851-5.702-6-5.702zm0 8c-1.105 0-2-.895-2-2s.895-2 2-2 2 .895 2 2-.895 2-2 2zm10.881-2.501c0-1.492-.739-2.83-1.902-3.748l.741-.752c1.395 1.101 2.28 2.706 2.28 4.5s-.885 3.4-2.28 4.501l-.741-.753c1.163-.917 1.902-2.256 1.902-3.748zm-3.381 2.249l.74.751c.931-.733 1.521-1.804 1.521-3 0-1.195-.59-2.267-1.521-3l-.74.751c.697.551 1.141 1.354 1.141 2.249s-.444 1.699-1.141 2.249zm-16.479 1.499l-.741.753c-1.395-1.101-2.28-2.707-2.28-4.501s.885-3.399 2.28-4.5l.741.752c-1.163.918-1.902 2.256-1.902 3.748s.739 2.831 1.902 3.748zm.338-3.748c0-.896.443-1.698 1.141-2.249l-.74-.751c-.931.733-1.521 1.805-1.521 3 0 1.196.59 2.267 1.521 3l.74-.751c-.697-.55-1.141-1.353-1.141-2.249zm16.641 14.501c0 2.209-3.581 4-8 4s-8-1.791-8-4c0-1.602 1.888-2.98 4.608-3.619l1.154 1.824c-.401.068-.806.135-1.178.242-3.312.949-3.453 2.109-.021 3.102 2.088.603 4.777.605 6.874-.001 3.619-1.047 3.164-2.275-.268-3.167-.296-.077-.621-.118-.936-.171l1.156-1.828c2.723.638 4.611 2.016 4.611 3.618z"/>    </IconBase>
  )
}

export function FormsIcon(props: SvgIconProps) {
  return (
    <IconBase {...props}>
      <path fillRule="evenodd" d="M289.054 458.977l.017-26.009h11.035l.074 9.317 10.13.037v16.655zm2.706-1.952h13.92v-1.766h-13.92v1.766zm0-2.722h9.493v-1.762h-9.493v1.759zm0-2.778h12.1v-1.88h-12.1v1.88zm0-2.774h9.493v-1.884h-9.493v1.884zm6.54-7.384h-6.54v1.767h6.54v-1.767zm7.439 2.781H291.76v1.767h13.98v-1.767zm-4.588-11.109l9.085 8.315h-9.162l.076-8.315z" transform="scale(0.9 1) translate(-287.054 -434.968)" />
    </IconBase>
  );
}

export function AssignmentsIcon(props: SvgIconProps) {
  return (
    <IconBase {...props}>
      <path fillRule="evenodd" d="M178.437 99.281l-8.1 6.593v-3.991a13.177 13.177 0 0 0-5.076.486c-6.03 1.667-8.114 3.513-8.114 3.513s2.744-6.531 8.218-8.065c4.7-1.315 4.972-1.034 4.972-1.034v-4.131l8.1 6.629zm-13.194 11.582a13.115 13.115 0 0 0 5.074-.486c6.03-1.665 8.114-3.511 8.114-3.511s-2.744 6.529-8.22 8.065c-4.692 1.315-4.968 1.032-4.968 1.032v4.133l-8.1-6.633 8.1-6.591v3.991z" transform="translate(-156.144 -92.652)" />
    </IconBase>
  );
}

export function RasterImageIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
<path d="M0 0h24v24H0z" fill="none"/><path d="M22 16V4c0-1.1-.9-2-2-2H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2zm-11-4l2.03 2.71L16 11l4 5H8l3-4zM2 6v14c0 1.1.9 2 2 2h14v-2H4V6H2z"/>  </SvgIcon>
  );
}

export function PdfFormIcon(props: SvgIconProps) {
  const classes = useStyles();

  return (
    <SvgIcon viewBox="0 0 40 55" classes={{ root: classes.formIcon }} {...props}>
      <g transform="translate(-380 -144.5)">
        <g>
          <path d="M-408.75-.677l10.9 10.9h-7.587a3.313 3.313 0 0 1-3.313-3.313z" transform="translate(817.742 145.649)" />
          <path d="M-614.424 48.14a2.518 2.518 0 0 1-2.515 2.515h-35.211a2.518 2.518 0 0 1-2.515-2.515v-50.3a2.518 2.518 0 0 1 2.515-2.515H-627v7.543a5.035 5.035 0 0 0 5.03 5.03h7.545z" transform="translate(1034.666 149.177)" />
        </g>
        <path fill="#fff" d="M-590.5 161.451a36.435 36.435 0 0 1-3.008-2.595c-.958-.958-1.811-1.886-2.553-2.771 1.157-3.575 1.664-5.418 1.664-6.4 0-4.174-1.508-5.03-3.773-5.03-1.721 0-3.773.894-3.773 5.151 0 1.877 1.028 4.155 3.065 6.8-.5 1.522-1.084 3.277-1.743 5.255-.317.949-.661 1.829-1.024 2.642-.3.131-.583.265-.861.4-1 .5-1.949.949-2.83 1.367-4.016 1.9-6.668 3.159-6.668 5.642 0 1.8 1.959 2.919 3.773 2.919 2.338 0 5.869-3.123 8.448-8.384a50.034 50.034 0 0 1 8.632-2.328c2.1 1.619 4.43 3.167 5.556 3.167 3.118 0 3.773-1.8 3.773-3.315 0-2.973-3.4-2.973-5.03-2.973a29.787 29.787 0 0 0-3.648.453zm-17.73 10.87a1.987 1.987 0 0 1-1.258-.4c0-.892 2.659-2.152 5.23-3.37l.5-.235c-1.888 2.735-3.756 4.005-4.469 4.005zm8.8-22.516c0-2.635.818-2.635 1.258-2.635.889 0 1.258 0 1.258 2.515a20.163 20.163 0 0 1-1 3.927 7.728 7.728 0 0 1-1.51-3.806zm.964 13.516q.118-.328.228-.663c.467-1.4.887-2.658 1.261-3.79q.783.862 1.687 1.766c.236.236.82.766 1.6 1.431-1.544.335-3.194.757-4.77 1.257zm14.126.655c0 .565 0 .8-1.167.807a9.267 9.267 0 0 1-2.112-1.206c.355-.039.616-.059.764-.059 1.864 0 2.391.182 2.521.458z" transform="translate(997.03 17.451)" />
      </g>
    </SvgIcon>
  );
}

export function DynamicFormIcon(props: SvgIconProps) {
  const classes = useStyles();

  return (
    <SvgIcon viewBox="0 0 40 55" classes={{ root: classes.formIcon }} {...props}>
      <g transform="translate(-380 -264.5)">
        <g transform="translate(380 264.5)">
          <path d="M-371.535-631.072l10.9 10.9h-7.587a3.313 3.313 0 0 1-3.309-3.309z" transform="translate(400.527 631.543)" />
          <path d="M-577.21-582.254a2.518 2.518 0 0 1-2.515 2.515h-35.211a2.518 2.518 0 0 1-2.515-2.515v-50.3a2.518 2.518 0 0 1 2.515-2.515h25.151v7.545a5.035 5.035 0 0 0 5.03 5.03h7.545z" transform="translate(617.451 635.071)" />
        </g>
        <g transform="translate(384.284 284.665)" fill="#fff">
          <g transform="translate(6.335 3.62)">
            <g>
              <path d="M-520.142-433.327a7.24 7.24 0 0 0-7.24 7.24 7.24 7.24 0 0 0 7.24 7.24 7.248 7.248 0 0 0 7.24-7.24 7.24 7.24 0 0 0-7.24-7.24zm0 12.669a5.43 5.43 0 0 1-5.43-5.43 5.43 5.43 0 0 1 5.43-5.43 5.435 5.435 0 0 1 5.43 5.43 5.43 5.43 0 0 1-5.43 5.43z" transform="translate(527.382 433.327)" />
            </g>
          </g>
          <g transform="translate(24.434)">
            <g>
              <path d="M-371.147-464.031a2.715 2.715 0 0 0-2.715 2.715 2.715 2.715 0 0 0 2.715 2.715 2.715 2.715 0 0 0 2.715-2.715 2.715 2.715 0 0 0-2.715-2.715zm0 3.62a.9.9 0 0 1-.9-.9.905.905 0 0 1 .9-.905.905.905 0 0 1 .9.905.9.9 0 0 1-.9.9z" transform="translate(373.862 464.031)" />
            </g>
          </g>
          <g transform="translate(21.719 19.004)">
            <g>
              <path d="M-394.175-302.835a2.715 2.715 0 0 0-2.715 2.715 2.715 2.715 0 0 0 2.715 2.715 2.715 2.715 0 0 0 2.715-2.715 2.715 2.715 0 0 0-2.715-2.715zm0 3.62a.9.9 0 0 1-.9-.9.905.905 0 0 1 .9-.9.905.905 0 0 1 .9.9.905.905 0 0 1-.9.9z" transform="translate(396.89 302.835)" />
            </g>
          </g>
          <g transform="translate(0 18.099)">
            <g>
              <path d="M-578.4-310.511a2.715 2.715 0 0 0-2.715 2.715 2.715 2.715 0 0 0 2.715 2.715 2.715 2.715 0 0 0 2.715-2.715 2.715 2.715 0 0 0-2.715-2.715zm0 3.62a.905.905 0 0 1-.9-.9.905.905 0 0 1 .9-.9.9.9 0 0 1 .9.9.9.9 0 0 1-.9.9z" transform="translate(581.115 310.511)" />
            </g>
          </g>
          <g>
            <g>
              <path d="M-578.4-464.031a2.715 2.715 0 0 0-2.715 2.715 2.715 2.715 0 0 0 2.715 2.716 2.715 2.715 0 0 0 2.715-2.715 2.715 2.715 0 0 0-2.715-2.716zm0 3.62a.9.9 0 0 1-.9-.9.905.905 0 0 1 .9-.905.9.9 0 0 1 .9.905.9.9 0 0 1-.9.9z" transform="translate(581.115 464.031)" />
            </g>
          </g>
          <g transform="translate(26.244 9.955)">
            <path d="M-355.8-379.595a2.715 2.715 0 0 0-2.715 2.715 2.715 2.715 0 0 0 2.715 2.715 2.715 2.715 0 0 0 2.715-2.715 2.715 2.715 0 0 0-2.715-2.715zm0 3.62a.905.905 0 0 1-.905-.9.905.905 0 0 1 .905-.905.905.905 0 0 1 .9.905.905.905 0 0 1-.9.9z" transform="translate(358.51 379.595)" />
          </g>
          <g transform="translate(16.664 15.558)">
            <g>
              <path d="M0 0H1.809V7.783H0z" transform="rotate(-54.48 1.43 .736)" />
            </g>
          </g>
          <g transform="translate(19.909 11.765)">
            <g>
              <path d="M0 0H7.239V1.81H0z" />
            </g>
          </g>
          <g transform="translate(18.552 2.835)">
            <g>
              <path d="M0 0H7.295V1.81H0z" transform="rotate(-29.744 6.814 1.81)" />
            </g>
          </g>
          <g transform="translate(3.056 2.912)">
            <g>
              <path d="M0 0H1.811V5.795H0z" transform="rotate(-51.341 1.471 .707)" />
            </g>
          </g>
          <g transform="translate(2.923 13.902)">
            <g>
              <path d="M0 0H7.068V1.811H0z" transform="matrix(.64 -.768 .768 .64 0 5.429)" />
            </g>
          </g>
          <g transform="translate(13.575 6.335)">
            <g>
              <path d="M-465.974-410.3v1.81a2.715 2.715 0 0 1 2.715 2.715h1.81a4.53 4.53 0 0 0-4.525-4.525z" transform="translate(465.974 410.299)" />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}