import React from 'react'
import moment from 'moment'
import ListItemBase from 'components/list-items/ListItemBase'
import UserAvatar from 'components/UserAvatar'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { UserModel } from 'data/models'
import { ListItemAvatar } from '@material-ui/core'

interface Props {
  user: UserModel
  selected?: boolean
  multiSelect?: boolean
  handleSelect?: (user: UserModel) => void
  primaryText?: (user: UserModel) => string
  secondaryText?: (user: UserModel) => string
}
const UserListItem: React.FC<Props> = ({ user, selected, handleSelect, primaryText, secondaryText, multiSelect = false }) => {
  const getSecondaryText = (user: UserModel) => {
    if (user.Lastname && user.Firstname) {
      return `${user.Lastname}, ${user.Firstname}`
    }
    if (user.Firstname) {
      return user.Firstname
    }
    return `created ${moment(user.CreateDate).fromNow()}`
  }

  return (
    <ListItemBase multiSelect={multiSelect} handleClick={() => handleSelect && handleSelect(user)} selected={selected}>
      <ListItem>
        <ListItemAvatar>
          <UserAvatar username={user.Username} photoUrl={user.PhotoURL} />
        </ListItemAvatar>
        <ListItemText primary={primaryText?.(user) || user.Username} secondary={secondaryText?.(user) || getSecondaryText(user)} />
      </ListItem>
    </ListItemBase>
  )
}

export default UserListItem
