import React from 'react'
import { WorkawareApi } from 'apis/WorkawareApi'
import { assignmentsService } from './assignmentsService'
import { completedFormsService } from './completedFormsService'
import { formTemplatesService } from './formTemplatesService'
import { formStateTemplatesService } from './formStateTemplatesService'
import { userService } from './userService'
import { teamsService } from './teamsService'
import { picklistsService } from './picklistsService'
import { filesService } from './filesService'
import { teamFileLinkService } from './teamFileLinkService'
import { companyServices } from './companyServices'
import { reportingService } from './reportingService'
import { messagingService } from './messagingService'
import { DocumentsApi } from 'apis/DocumentsApi'
import { calendarService } from './calendarService'
import { assetsService } from './assetsService'
import { downloadServices } from './downloadServices'
import { emailTemplatesService } from './emailTemplatesService'
import { securityModuleService } from './securityModuleService'
import { journeyService } from './journeyService'
import { rasterService } from './rasterService'
import {shapeFilesService} from './shapeFilesService'
import {trackingCurrentPosition} from './trackingCurrentPosition'
import {userShapesService} from './userShapesService'
import {JourneyCheckPointService} from './journeyCheckPointService'
import { maintenanceSchedulesService } from './maintenanceSchedulesService'
import { userGeneratedShapesService } from './userGeneratedShapesService'
import { moduleAccessService } from './moduleAccessService'
import { avatarService } from './avatarService'

/**
 * Use service hooks instead
 *
 * @deprecated
 */
export enum WorkawareService {
  assignmentsService,
  companyServices,
  completedFormsService,
  filesService,
  formTemplatesService,
  formStateTemplatesService,
  messagingService,
  picklistsService,
  reportingService,
  calendarService,
  teamsService,
  teamFileLinkService,
  userService,
  assetsService,
  downloadServices,
  journeyService,
  rasterService,
  userShapesService
}

/**
 * Convert an enum to an instance of the requests API
 *
 * @param serviceName Which service is being requested
 * @param workawareApi an instance of the API
 * @param documentsApi an instance of the API
 * @deprecated
 */
const serviceFromEnum = (serviceName: WorkawareService, workawareApi: WorkawareApi, documentsApi: DocumentsApi) => {
  switch (serviceName) {
    case WorkawareService.calendarService:
      return new calendarService(workawareApi)
    case WorkawareService.messagingService:
      return new messagingService(workawareApi)
    case WorkawareService.reportingService:
      return new reportingService(documentsApi, workawareApi)
    case WorkawareService.companyServices:
      return new companyServices(workawareApi)
    case WorkawareService.teamFileLinkService:
      return new teamFileLinkService(workawareApi)
    case WorkawareService.filesService:
      return new filesService(workawareApi)
    case WorkawareService.assignmentsService:
      return new assignmentsService(workawareApi)
    case WorkawareService.completedFormsService:
      return new completedFormsService(workawareApi)
    case WorkawareService.formTemplatesService:
      return new formTemplatesService(workawareApi)
    case WorkawareService.formStateTemplatesService:
      return new formStateTemplatesService(workawareApi)
    case WorkawareService.userService:
      return new userService(workawareApi)
    case WorkawareService.teamsService:
      return new teamsService(workawareApi)
    case WorkawareService.picklistsService:
      return new picklistsService(workawareApi)
    case WorkawareService.journeyService:
      return new journeyService(workawareApi)
    case WorkawareService.rasterService:
      return new rasterService(workawareApi)
    default:
      return undefined
  }
}

/**
 * This exists to accommodate requests for services as an array of strings
 *
 * @param names a list of service names as an array of strings
 * @deprecated
 */
export interface IServices {
  assignments?: assignmentsService
  company?: companyServices
  completedForms?: completedFormsService
  files?: filesService
  formTemplates?: formTemplatesService
  formStateTemplates?: formStateTemplatesService
  messaging?: messagingService
  picklists?: picklistsService
  reporting?: reportingService
  calendar?: calendarService
  teams?: teamsService
  teamFileLink?: teamFileLinkService
  user?: userService
  journeyManagement?: journeyService
  _workawareApi: WorkawareApi
  _documentsApi: DocumentsApi
}

/**
 * This exists to accommodate requests for services as an array of strings
 *
 * @param names a list of service names as an array of strings
 * @deprecated
 */
const stringToEnum = (name: string) => {
  switch (name) {
    case 'calendar':
      return WorkawareService.calendarService
    case 'messaging':
      return WorkawareService.messagingService
    case 'reporting':
      return WorkawareService.reportingService
    case 'company':
      return WorkawareService.companyServices
    case 'teamFileLink':
      return WorkawareService.teamFileLinkService
    case 'files':
      return WorkawareService.filesService
    case 'assignments':
      return WorkawareService.assignmentsService
    case 'completedForms':
      return WorkawareService.completedFormsService
    case 'formTemplates':
      return WorkawareService.formTemplatesService
    case 'formStateTemplates':
      return WorkawareService.formStateTemplatesService
    case 'user':
      return WorkawareService.userService
    case 'teams':
      return WorkawareService.teamsService
    case 'picklists':
      return WorkawareService.picklistsService
    case 'journeyManagements':
      return WorkawareService.journeyService
    case 'rasters':
      return WorkawareService.rasterService
    default:
      return undefined
  }
}

/**
 * This exists to accommodate requests for services from the HOS implementation
 *
 * @param names a list of service names as an array of strings
 * @deprecated
 */
const attachService = (services: IServices, serviceName: WorkawareService, service: any) => {
  switch (serviceName) {
    case WorkawareService.calendarService:
      services.calendar = service
      break
    case WorkawareService.messagingService:
      services.messaging = service
      break
    case WorkawareService.reportingService:
      services.reporting = service
      break
    case WorkawareService.companyServices:
      services.company = service
      break
    case WorkawareService.teamFileLinkService:
      services.teamFileLink = service
      break
    case WorkawareService.filesService:
      services.files = service
      break
    case WorkawareService.assignmentsService:
      services.assignments = service
      break
    case WorkawareService.completedFormsService:
      services.completedForms = service
      break
    case WorkawareService.formTemplatesService:
      services.formTemplates = service
      break
    case WorkawareService.formStateTemplatesService:
      services.formStateTemplates = service
      break
    case WorkawareService.userService:
      services.user = service
      break
    case WorkawareService.teamsService:
      services.teams = service
      break
    case WorkawareService.picklistsService:
      services.picklists = service
      break
    case WorkawareService.journeyService:
      services.picklists = service
      break
  }
}

/**
 * a HOC implementation of the service request hook. The requested services are provided as an
 * array of strings. error prone. use the hook instead.
 *
 * This will inject a 'services' property of type 'IServices'
 *
 * @param requiredServices An array of requested services
 * @deprecated
 */
export const withApiServices = (requiredServices: string[] | WorkawareService[]) => <P extends object>(Component: React.ComponentType<P>) => {
  let services: IServices = {
    _workawareApi: new WorkawareApi(),
    _documentsApi: new DocumentsApi(),
  }
  requiredServices.forEach((serviceName: string | WorkawareService) => {
    const serviceEnum = typeof serviceName === 'string' ? stringToEnum(serviceName) : serviceName
    if (serviceEnum !== undefined) {
      const service = serviceFromEnum(serviceEnum, services._workawareApi, services._documentsApi)
      attachService(services, serviceEnum, service)
    }
  })
  return (props) => {
    return React.createElement(Component, { ...props, services })
  }
}

export const useApiServices = () => {
  return React.useMemo(() => {
    return new WorkawareApi()
  }, [])
}

export const useFilesService = () => {
  return React.useMemo(() => {
    return new filesService(new WorkawareApi())
  }, [])
}

export const useUserShapeService = ()=>{
  return React.useMemo(() => {
  return new userShapesService(new WorkawareApi())
  },[])
}

export const useShapeFilesService = ()=>{
  return React.useMemo(() => {
  return new shapeFilesService(new WorkawareApi())
  },[])
}

export const useTrackingCurrentPositions = ()=>{
  return React.useMemo(() => {
  return new trackingCurrentPosition(new WorkawareApi())
  },[])
}


export const useAssetsService = () => {
  return React.useMemo(() => {
    return new assetsService(new WorkawareApi())
  }, [])
}

export const useCalendarServices = () => {
  return React.useMemo(() => {
    return new calendarService(new WorkawareApi())
  }, [])
}

export const useAssignmentServices = () => {
  return React.useMemo(() => {
    return new assignmentsService(new WorkawareApi())
  }, [])
}

export const useReportingServices = () => {
  return React.useMemo(() => {
    return new reportingService(new DocumentsApi(), new WorkawareApi())
  }, [])
}

export const useCompletedFormsServices = () => {
  return React.useMemo(() => {
    return new completedFormsService(new WorkawareApi())
  }, [])
}

export const useFormTemplatesServices = () => {
  return React.useMemo(() => {
    return new formTemplatesService(new WorkawareApi())
  }, [])
}

export const useTeamsServices = () => {
  return React.useMemo(() => {
    return new teamsService(new WorkawareApi())
  }, [])
}

export const useUserServices = () => {
  return React.useMemo(() => {
    return new userService(new WorkawareApi())
  }, [])
}

export const useMessagingServices = () => {
  return React.useMemo(() => {
    return new messagingService(new WorkawareApi())
  }, [])
}

export const useCompanyServices = () => {
  return React.useMemo(() => {
    return new companyServices(new WorkawareApi())
  }, [])
}

export const usePicklistServices = () => {
  return React.useMemo(() => {
    return new picklistsService(new WorkawareApi())
  }, [])
}

export const useEmailTemplatesServices = () => {
  return React.useMemo(() => {
    return new emailTemplatesService(new WorkawareApi())
  }, [])
}

export const useRasterServices = () => {
  return React.useMemo(() => {
    return new rasterService(new WorkawareApi())
  }, [])
}

export const useDownloadServices = () => {
  return React.useMemo(() => {
    return new downloadServices(new WorkawareApi())
  }, [])
}

export const useFormStateTemplatesService = () => {
  return React.useMemo(() => {
    return new formStateTemplatesService(new WorkawareApi())
  }, [])
}

export const useSecurityModuleService = () => {
  return React.useMemo(() => {
    return new securityModuleService(new WorkawareApi())
  }, [])
}

export const useJourneyServices = () => {
  return React.useMemo(() => {
    return new journeyService(new WorkawareApi())
  }, [])
}
export const useJourneyCheckPointServices = () => {
  return React.useMemo(() => {
    return new JourneyCheckPointService(new WorkawareApi())
  }, [])
}
export const useMaintenanceSchedulesService = () => {
  return React.useMemo(() => {
    return new maintenanceSchedulesService(new WorkawareApi())
  }, [])
}

export const useUserGeneratedShapesService = () => {
  return React.useMemo(() => {
    return new userGeneratedShapesService(new WorkawareApi())
  }, [])
}

export const useModuleAccessService = () =>{
  return React.useMemo(() =>{
    return new moduleAccessService(new WorkawareApi())
  }, [])
}

export const useAvatarService = () => {
  return React.useMemo(() => {
    return new avatarService(new WorkawareApi())
  }, [])
}