import { WorkawareApi } from 'apis/WorkawareApi'

export class downloadServices {
  private _api: WorkawareApi

  constructor(api: WorkawareApi) {
    this._api = api
  }

  getUserFile = async (url: string) => {
    return await this._api.download.userFile(url)
  }
}