import React from 'react'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { NavLink } from 'react-router-dom'
import { makeStyles, Tooltip } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { IRootReduxState } from 'types/reducers'
import { toggleMobileMenu } from 'reducers/stateReducer'

const useStyles = makeStyles((theme) => ({
  menuIcon: {
    '& svg': {
      fill: 'currentColor',
      width: '1em',
      height: '1em',
      display: 'inline-block',
      fontSize: '1.25rem',
      transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      flexShrink: '0',
      userSelect: 'none',
    },
  },
  activeItem: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      background: theme.palette.primary.main,
      color: `${theme.palette.primary.contrastText} !important`,
    },
    '& > .MuiListItemIcon-root': {
      color: theme.palette.primary.contrastText,
    },
  },
  menuListItem: {
    marginTop: '5px',
    marginBottom: '5px',
    paddingTop: '5px',
    paddingBottom: '5px',
    '&:hover': {
      color: theme.palette.secondary.contrastText,
    },
  },
}))

interface Props {
  title: string | JSX.Element
  route: string
  icon?: JSX.Element
}
const MenuListItem: React.FC<Props> = ({ title, icon, route }) => {
  const classes = useStyles()
  const sideBarOut = useSelector((state: IRootReduxState) => state.app.state.sideMenuExpanded)
  const mobileMenuOpen = useSelector((state: IRootReduxState) => state.app.state.mobileMenuOpen)
  const dispatch = useDispatch()
  const handleLinkClick = (e:React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (mobileMenuOpen) {
      dispatch(toggleMobileMenu())
    }
  }
  return (
    <Tooltip disableFocusListener={sideBarOut} disableHoverListener={sideBarOut} disableTouchListener={sideBarOut} title={title} arrow placement="right">
      <ListItem button onClick={(e) => handleLinkClick(e)} component={NavLink} to={route} exact className={classes.menuListItem} activeClassName={classes.activeItem}>
        {icon && <ListItemIcon className={classes.menuIcon}>{icon}</ListItemIcon>}
        <ListItemText primary={title} />
      </ListItem>
    </Tooltip>
  )
}

export default MenuListItem
