import uuid from 'uuid/v1'
import { WorkawareBaseClass } from './WorkawareBaseClass'

export class PicklistTypeModel extends WorkawareBaseClass {
  _className = 'PicklistTypeModel'

  public Id: number | undefined = undefined
  public CompanyId: number | undefined = undefined
  public GUID: string | undefined = uuid()
  public Name: string | undefined = ''
}
