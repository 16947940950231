import Forbidden from 'containers/Forbidden'
import { CompanyModuleAccessDto } from 'data/dtos/CompanyModuleAccessDto'
import React from 'react'
import { Route, RouteProps } from 'react-router-dom'

interface IProps {
  access: CompanyModuleAccessDto
}
export const ProtectedRoute: React.FC<IProps & RouteProps> = ({ access, render, ...rest }) => {
  const checkAuth = (location: any) => {
    if (!access.AccessAllowed) {
      return <Forbidden />
    }
    return render()
  }
  return <Route render={checkAuth} {...rest} />
}
export default ProtectedRoute